import { useEffect, useState } from "react";
import CustomTable from "../../../compounds/table";
import AppLayout from "../../../layout";
import primaryColor from "../../../theme/colors";
import "../style.css";
import { useNavigate, useParams } from "react-router-dom";
import postRequest from "../../../function/axios";
import CustomDialog from "../../../compounds/dialog";
import TestCaseTable from "../../assessment/compounds/testcase";

import CryptoJS from "crypto-js";

function AssesmentQuestion() {
  return <AppLayout rId={5} body={<Body />} />;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight:"70%",
  borderRadius: 2,
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  overflow:"auto",
  p: 2,
};

function Body() {
  const navigate = useNavigate();
  const [questionData, setquestionData] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(0);  
  const tableHeader = ["Type", "Title", "Score"];
  const tableFields = ["type", "title", "score"];
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  var { slot } = useParams();
  slot = CryptoJS.AES.decrypt(atob(slot), "!@#$%^&*()").toString(
    CryptoJS.enc.Utf8
  );

  const fetchQuestion = async () => {
    const res = await postRequest("/assessment/question", { id: parseInt(slot) }, navigate);
    if (res.success && res.data.data !== null && res.data.data !== undefined) {
      setquestionData(res.data.data);
    }
  };

  useEffect(() => {
    fetchQuestion();
  }, []);
  return (
    <div
      style={{
        padding: 20,
      }}
    >
      <CustomTable
        button={() => {
          navigate("/slot/assessment/Question/add/"+ btoa(
            CryptoJS.AES.encrypt(slot.toString(), "!@#$%^&*()").toString()
          ));
        }}
        buttonLabel="Add Question"
        title={"Assesment Question"}
        description={
          <>
            Here you can find the Assesment Question of{" "}
            <span style={{ color: primaryColor, fontWeight: 600 }}>
              UI UX Level 1
            </span>
            .
          </>
        }
        data={questionData}
        header={tableHeader}
        field={tableFields}
        buttonAction={(item) => {
          setSelectedQuestion(item);
          setOpen(true);
        }}
      />

      {open ? (
        <CustomDialog
          open={open}
          handleClose={handleClose}
          title={selectedQuestion.type + " Question"}
          style={style}
          body={
            <div
              style={{
                marginTop: 20,
              }}
            >
              <h4
                style={{
                  color: "grey",
                  fontSize: 13,
                  fontWeight: 500,
                }}
              >
                Question Title
              </h4>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 5,
                  gap: 10,
                }}
              >
                <h4
                  style={{
                    fontSize: 18,
                    fontWeight: 500,
                  }}
                >
                  {selectedQuestion.title}
                </h4>
                <h4
                  style={{
                    color: "red",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  {selectedQuestion.score + " Marks"}
                </h4>
              </div>
              <h4
                style={{
                  color: "grey",
                  fontSize: 13,
                  fontWeight: 500,
                  marginTop: 20,
                }}
              >
                Question Description
              </h4>
              <div style={{marginTop:5}}
                dangerouslySetInnerHTML={{
                  __html: selectedQuestion.description,
                }}
              ></div>

              {selectedQuestion.type === "compiler" ? (
                selectedQuestion.options.map((testCase,i)=>(
                  <TestCaseTable index={i} input={testCase.input} output={testCase.output} score={testCase.score} />
                  // console.log(testCase)
                ))
              ) : null}
            </div>
          }
        />
      ) : null}
    </div>
  );
}

export default AssesmentQuestion;
