import { useEffect, useState } from "react";
import CustomTable from "../../compounds/table";
import AppLayout from "../../layout";
import postRequest from "../../function/axios";
import { useNavigate, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";

function CourseQuestionBank() {
  return <AppLayout rId={6} body={<Body />} />;
}

function Body() {
  const navigate = useNavigate();
  const [courseData, setCourseData] = useState([]);
  const tableHeader = ["Name", "Count"];
  const tableFields = ["name", "count"];
  var { courseId } = useParams();
  courseId = CryptoJS.AES.decrypt(atob(courseId), "!@#$%^&*()").toString(
    CryptoJS.enc.Utf8
  );

  const fetchCourse = async () => {
    var res = await postRequest("/questionBank/getCourse", { id: parseInt(courseId) }, navigate);
    if (res.success && res.data.success === true) {
      setCourseData(res.data.data);
    }
  };

  useEffect(() => {
    fetchCourse();
  }, []);
  return (
    <div style={{ padding: 20 }}>
      <CustomTable
        title={"Course Question Bank"}
        description={"Here you can find the Courses and Levels."}
        data={courseData}
        header={tableHeader}
        field={tableFields}
        buttonAction={(item) => {
          navigate(
            "/reports/questionBank/course/question/" +
              btoa(
                CryptoJS.AES.encrypt(
                  item.skill_id.toString(),
                  "!@#$%^&*()"
                ).toString()
              )
          );
        }}
      />
    </div>
  );
}

export default CourseQuestionBank;
