import { useEffect, useState } from "react";
import CustomTable from "../../compounds/table";
import AppLayout from "../../layout";
import { Backdrop, Fade, Modal, Typography } from "@mui/material";
import CustomSelect from "../../compounds/select";
import CustomButton from "../../compounds/button";
import ErrorText from "../../compounds/error";
import postRequest from "../../function/axios";

function CodeReview() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [courseData, setCourseData] = useState([]);
    const [avaCourse, setAvaCourse] = useState([]);
    const [selectedRegisterId, setSelectedRegisterId] = useState(0)
    const tableHeader = ["Course", "Slot"];
    const tableFields = ["course", "slot"];

    const fetchAva = async () => {
        const res = await postRequest(
            "/codereview/getEli",
            {},
            navigator
        );
        setAvaCourse([])
        if (res.data.data !== null && res.data.data !== undefined) {
            setAvaCourse(res.data.data);
        }
    };
    const fetchRequest = async () => {
        const res = await postRequest(
            "/codereview/get",
            {},
            navigator
        );
        setCourseData([])
        if (res.data.data !== null && res.data.data !== undefined) {
            setCourseData(res.data.data);
        }
    };

    const handleRequest = async () => {
        const res = await postRequest(
            "/codereview/request",
            { "id": selectedRegisterId },
            navigator
        );
        handleClose()
        fetchRequest()
        fetchAva()
    }

    useEffect(() => {
        fetchRequest()
        fetchAva()
    }, [])      


    return <AppLayout
        rId={19}
        body={
            <div style={{ padding: 20 }}>
                <CustomTable
                    title={"Code Review"}
                    button={handleOpen}
                    buttonLabel={"New Request"}
                    data={courseData}
                    header={tableHeader}
                    field={tableFields}
                />

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={open}>
                        <div className="slot-booking">
                            <Typography
                                id="transition-modal-title"
                                variant="h6"
                                component="h2"
                            >
                                Request Code Review
                            </Typography>
                            <CustomSelect
                                onChange={setSelectedRegisterId}
                                label="Select any Course"
                                options={avaCourse.map((item) => ({
                                    label: item.course,
                                    value: item.register_id,
                                }))}
                                placeholder="Select any Course"
                            />
                            <CustomButton
                                margin={20}
                                label={"Request Now"}
                                func={handleRequest}
                            />
                        </div>
                    </Fade>
                </Modal>
            </div>
        }

    />
}

export default CodeReview