function MCQOptions(props) {
    return (
      <table className="testcase-table" style={{ width: "100%", marginTop: 20 }}>
        <tr style={{ backgroundColor: "rgb(238, 242, 246)" }}>
          <th
            style={{ padding: 7, textAlign: "start" }}
            colSpan={2}
          >
            Option #{props.index + 1}
          </th>
        </tr>
        <tr>
          <td style={{ padding: 5 }}>Label</td>
          <td>Score</td>
        </tr>
        <tr>
          <td style={{ padding: 5 }}>{props.label}</td>
          <td>{props.score}</td>
        </tr>
      </table>
    );
  }
  
  export default MCQOptions;
  