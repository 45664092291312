import Select from "react-select";
import "./style.css";

function CustomSelect2(props) {
  return (
    <div
      style={{
        marginTop: props.margin ? props.margin : 20,
        flex: !props.widthFull ? 3 : null,
      }}
    >
      {props.label ? (
        <label
          htmlFor={props.name}
          style={{
            color: "grey",
            fontSize: 13,
          }}
        >
          {props.label}
        </label>
      ) : null}
      <div style={{ marginTop: 5 }}>
        <Select
          isLoading={props.isLoading}
          isClearable={true}
          onChange={props.onChange}
          options={props.options}
          isSearchable={true}
          placeholder={props.placeholder}
          value={props.value}
        />
      </div>
    </div>
  );
}

export default CustomSelect2;
