import CustomButton from "../../../compounds/button";

function LinkMaterial(props) {
  return (
    <center style={{ margin: "80px 0" }}>
      <h3>{props.material.title}</h3>
      <br />
      <div style={{ width: 150 }}>
        <CustomButton
          label={"Go To Link"}
          func={() => {
            window.open(props.material.content, "_blank");
          }}
        />
      </div>
    </center>
  );
}

export default LinkMaterial;
