import { useEffect, useState } from "react";
import CustomTable from "../../compounds/table";
import AppLayout from "../../layout";
import postRequest from "../../function/axios";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";

function ProjectApproval() {
  return <AppLayout rId={12} body={<Body />} />;
}

function Body() {
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState([]);
  const tableHeader = ["Project Id", "Title", "Type", "Domain"];
  const tableFields = ["project_id", "title", "type", "domain"];

  const fetchProjects = async () => {
    var res = await postRequest("/projects/pending", {}, navigate);
    if (res.success && res.data.success === true) {
      setProjectData(res.data.data);
    }
  };

  useEffect(()=>{
    fetchProjects()
  },[])

  return (
    <div style={{ padding: 20 }}>
      <CustomTable
        title={"Project Approval"}
        description={"The List of Projects need to be Approved."}
        data={projectData}
        header={tableHeader}
        field={tableFields}
        buttonAction={(item) => {
          navigate(
            "/project/details/" +
              btoa(
                CryptoJS.AES.encrypt(
                  item.id.toString(),
                  "!@#$%^&*()"
                ).toString()
              )
          );
        }}
      />
    </div>
  );
}

export default ProjectApproval;
