import { useEffect, useState } from "react";
import CustomTable from "../../compounds/table"
import AppLayout from "../../layout"
import { useNavigate } from "react-router-dom";
import postRequest from "../../function/axios";
import CryptoJS from "crypto-js";

function EvaluationUsers() {
    const navigate = useNavigate()
    const [courseData, setCourseData] = useState([]);
    const tableHeader = ["Course Name", "Question Type"];
    const tableFields = ["course_name", "type"];

    const handleOpenFilePage = (id) => {
        navigate("/evaluation/details/" + btoa(
            CryptoJS.AES.encrypt(id.toString(), "!@#$%^&*()").toString()
        ))
    }

    const fetchResponse = async () => {
        var res = await postRequest("/evaluation/getPending", {}, navigate)
        if (res.success === true && res.data.success === true && res.data.data !== null) {
            setCourseData(res.data.data)
        }
    }

    useEffect(() => {
        fetchResponse()
    }, [])

    return <AppLayout rId={16}
        body={
            <div style={{ padding: 20 }}>
                <CustomTable
                    title={"Pending Evaluation"}
                    data={courseData}
                    header={tableHeader}
                    field={tableFields}
                    buttonAction={(item) => { handleOpenFilePage(item.id) }}
                />
            </div>
        }
    />
}

export default EvaluationUsers