import { useEffect, useState } from "react";
import CustomTable from "../../compounds/table"
import AppLayout from "../../layout"
import postRequest from "../../function/axios";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

function QuestionBank() {
    return <AppLayout rId={6} body={<Body />} />
}

function Body() {
    const navigate = useNavigate()
    const [courseData, setCourseData] = useState([]);
    const tableHeader = ["Name"];
    const tableFields = ["name"];

    const fetchCourse = async () => {
        var res = await postRequest("/course/allVertical", {}, navigate)
        if (res.success && res.data.success === true) {
            setCourseData(res.data.data)
        }
    }

    useEffect(() => {
        fetchCourse()
    }, [])
    return <div
        style={{ padding: 20 }}>
        <CustomTable
            title={"Course Question Bank"}
            description={
                "Here you can find the Courses and Levels."
            }
            data={courseData}
            header={tableHeader}
            field={tableFields}
            buttonAction={(item) => {
                navigate("course/" + btoa(
                    CryptoJS.AES.encrypt(
                        item.id.toString(),
                        "!@#$%^&*()"
                    ).toString()
                ))
            }} />
    </div>
}

export default QuestionBank