function CustomTextArea(props) {
  return (
    <div style={{ width: "100%" }}>
      <h4
        style={{
          color: "grey",
          fontSize: 13,
          fontWeight: 500,
          marginTop: 20,
        }}
      >
        {props.label}
      </h4>
      <textarea
        value={props.value}
        disabled={props.disabled}
        onChange={(e) => {
          props.onChange(e);
        }}
        style={{
          width: "100%",
          minWidth: "100%",
          maxWidth: "100%",
          padding: 10,
          paddingLeft: 5,
          fontSize: 15,
          border: "0.5px solid grey",
          borderRadius: 5,
          marginTop: 5,
          resize: false,
          minHeight: 100,
          maxHeight: 100,
          boxSizing: "border-box",
          outline: "none",
        }}
      />
    </div>
  );
}

export default CustomTextArea;
