import "./style.css";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

function CourseCard(props) {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate(
          props.path +
            btoa(
              CryptoJS.AES.encrypt(props.id.toString(), "!@#$%^&*()").toString()
            )
            
        );
      }}
      className="course-card"
    >
      <img
        alt="course-img"
        width={"100%"}
        src={"https://ps.bitsathy.ac.in/images/course/" + props.img}
      />
      <div className="course-card-content">
        <h2>{props.title}</h2>
        {props.value !== null ? (
          <BorderLinearProgress variant="determinate" value={props.value} />
        ) : null}
      </div>
    </div>
  );
}

export default CourseCard;
