import { useEffect, useState } from "react";
import BasicCard from "../../compounds/cards";
import AppLayout from "../../layout";
import SqlTables from "./compounds/sql_table";
import "./style.css";
import { postComplierRequest } from "../../function/axios";
import { useNavigate } from "react-router-dom";
import { Editor } from "@monaco-editor/react";
import CustomButton from "../../compounds/button";
import axios from "axios";
import { complierApi } from "../../utils/api";

function SQLPlayGround() {
  const navigate = useNavigate();

  const [availabelTables, setAvailabelTabel] = useState([]);
  const [tableDesc, setTableDesc] = useState([]);
  const [sqlQuery, setSqlQuery] = useState("");
  const [queryOutput,setQueryOutput] = useState("")

  const fetchTables = async () => {
    var res = await postComplierRequest(
      "/playground/sql/getTables",
      {},
      navigate
    );
    console.log(res);
    if (res.success && res.data.success === true) {
      setAvailabelTabel(res.data.data.table);
      setTableDesc(res.data.data.table_desc);
    }
  };

  const handleRunQuery = async () => {
    setQueryOutput("")
    try {
      const response = await axios.post(
        complierApi + "/playground/sql/run",
        { query: sqlQuery },
      );
      console.log(response.data)
        setQueryOutput(response.data)
    } catch (error) {
        setQueryOutput(error.response.data)
    }

    fetchTables()
  };

  useEffect(() => {
    fetchTables();
  }, []);

  return (
    <AppLayout
      rId={18}
      body={
        <div style={{ padding: 20, display: "flex", gap: 10 }}>
          <BasicCard
            padding={20}
            color="white"
            body={
              <>
                <h3>Available Tables</h3>

                <div className="sql-table-row">
                  <SqlTables decode={false} data={availabelTables} />
                  {tableDesc.map((item) => (
                    <SqlTables title={item.name} data={item.table_desc} />
                  ))}
                  {/* <SqlTables data={tableDesc} /> */}
                </div>
              </>
            }
          />
          <BasicCard
            body={
              <>
                <div
                  style={{
                    backgroundColor: "#1e1e1e",
                    padding: "20px 0",
                    borderRadius: 10,
                  }}
                >
                  <Editor
                    options={{
                      fontSize: 17,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      tabSize: 8,
                      letterSpacing: 1.3,
                    }}
                    height={300}
                    width={"100%"}
                    theme={"vs-dark"}
                    language={"sql"}
                    onChange={(value) => {
                      setSqlQuery(value);
                    }}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <div style={{ width: 150 }}>
                    <CustomButton
                      func={handleRunQuery}
                      margin={10}
                      label="Run Query"
                    />
                  </div>
                </div>

                <h3 style={{marginTop:20,fontWeight:400}}>{queryOutput}</h3>
              </>
            }
          />
        </div>
      }
    />
  );
}

export default SQLPlayGround;
