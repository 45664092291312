import { useState } from "react";
import CustomTable from "../../compounds/table";
import AppLayout from "../../layout";
import CustomDialog from "../../compounds/dialog";
import CustomSelect from "../../compounds/select";
import CustomButton from "../../compounds/button";
import postRequest from "../../function/axios";

function MyTrainee() {
  return <AppLayout rId={9} body={<Body />} />;
}

function Body() {
  const [openNewRequest, setOpenNewRequest] = useState(false);
  const tableHeader = [
    "Course Name",
    "Domain",
    "Year",
    "Max Students",
    "Status",
  ];
  const tableFields = ["course", "domain", "year", "max_users", "status"];
  const [course, setCourse] = useState([]);

  const fetchCourse = async () => {
    const res = await postRequest("/myTrainee/availableCourse", {}, navigator);
    if (res.success && res.data.success) {
      setCourse(res.data.data);
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <CustomTable
        button={() => {
          fetchCourse();
          setOpenNewRequest(true);
        }}
        buttonAction={() => {}}
        buttonLabel="Request New Course"
        title={"My Trainees"}
        description={
          "Here you can find the Courses, where I have requested as a Trainer."
        }
        data={[]}
        header={tableHeader}
        field={tableFields}
      />

      <CustomDialog
        open={openNewRequest}
        handleClose={() => {
          setOpenNewRequest(false);
        }}
        title={"Trainer Request"}
        body={
          <>
            <CustomSelect
              // onChange={setSelectedCourse}
              options={course.map((item) => ({
                label: item.course,
                value: item.request_id,
              }))}
              label={"Course Name"}
              placeholder={"Select a Course"}
            />

            <h4 style={{ fontWeight: 400, marginTop: 15, fontSize: 17 }}>
              Trainee Year: II
            </h4>
            <h4 style={{ fontWeight: 400, marginTop: 10, fontSize: 17 }}>
              Max. Trainee: 50
            </h4>
            <CustomButton margin={20} label={"Request"} />
          </>
        }
      />
    </div>
  );
}

export default MyTrainee;
