import Select from "react-select";
import "./style.css";

function CustomSelect(props) {
  return (
    <div
      style={{
        marginTop: props.margin ? props.margin : 20,
        flex: !props.widthFull ? 3 : null,
        width:props.width
      }}
    >
      {props.label ? (
        <label
          htmlFor={props.name}
          style={{
            color: "grey",
            fontSize: 13,
          }}
        >
          {props.label}
        </label>
      ) : null}
      <div style={{ marginTop: 5 }}>
        <Select
          // value={props.value !== undefined ? props.value : null}
          onChange={(e) => {
            props.onChange(e.value);
          }}
          options={props.options}
          isSearchable={true}
          placeholder={props.placeholder}
        />
      </div>
    </div>
  );
}

export default CustomSelect;
