import CryptoJS from "crypto-js";
import AppLayout from "../../layout";
import BasicCard from "../../compounds/cards";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import postRequest, { postComplierRequest } from "../../function/axios";
import InputBox from "../../compounds/input";
import ToastMsg from "../../compounds/toast";
import CustomButton from "../../compounds/button";
import CustomDialog from "../../compounds/dialog";
import MCQTable from "../slot/assessment/compounds/mcq_table";
import CustomSelect from "../../compounds/select";
import JoditEditor from "jodit-react";
import TestCaseTable from "../slot/assessment/compounds/testcase_table";
import { Editor } from "@monaco-editor/react";
import CustomTextArea from "../../compounds/textarea";
import Select from "react-select";

function AddQuestionBank() {
  return (
    <AppLayout
      rId={6}
      body={
        <div style={{ margin: 20 }}>
          <BasicCard
            overflow="none"
            padding={20}
            color="white"
            body={<Body />}
          />
        </div>
      }
    />
  );
}

function Body() {
  const navigator = useNavigate();
  const [selectType, setSelectedType] = useState("");
  const [selectDifficulity, setDifficulity] = useState("");
  const [selectMethod, setMethod] = useState("");
  const [open, setOpen] = useState(false);
  const [optionLabel, setOptionLabel] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [score, setScore] = useState(0);
  const [testCaseInput, setTestCaseInput] = useState("");
  const [testCaseOutput, setTestCaseOutput] = useState("");
  const [optionScore, setOptionScore] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [language, setLanguage] = useState("c");
  const [openUpdate, setOpenUpdate] = useState(false);
  const [selectedOption, setSelectedOption] = useState(-1);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);
  const [code, setCode] = useState("");
  var { courseId } = useParams();
  courseId = CryptoJS.AES.decrypt(atob(courseId), "!@#$%^&*()").toString(
    CryptoJS.enc.Utf8
  );
  // const buttons = [
  //   "undo",
  //   "redo",
  //   "|",
  //   "bold",
  //   "strikethrough",
  //   "underline",
  //   "italic",
  //   "|",
  //   "superscript",
  //   "subscript",
  //   "|",
  //   "align",
  //   "|",
  //   "ul",
  //   "ol",
  //   "outdent",
  //   "indent",
  //   "|",
  //   "font",
  //   "fontsize",
  //   "brush",
  //   "paragraph",
  //   "|",
  //   "image",
  //   "link",
  //   "table",
  //   "|",
  //   "hr",
  //   "eraser",
  //   "copyformat",
  //   "|",
  //   "fullsize",
  //   "selectall",
  //   "print",
  //   "|",
  //   "symbols",
  // ];

  const [mcqData, setMcqData] = useState([]);
  const closeToast = () => {
    setToastShow(false);
  };
  // const editorConfig = {
    // readonly: false,
    // toolbar: true,
    // maxHeight: 400,
    // spellcheck: true,
    // language: "en",
    // toolbarButtonSize: "medium",
    // toolbarAdaptive: false,
    // showCharsCounter: true,
    // showWordsCounter: true,
    // showXPathInStatusbar: false,
    // askBeforePasteHTML: true,
    // askBeforePasteFromWord: true,
    // placeholder: "Enter Description...",
    // //defaultActionOnPaste: "insert_clear_html",
    // buttons: buttons,
    // uploader: {
    //   // url: '/manage/upload',  //your upload api url
    //   //               insertImageAsBase64URI: false,
    //   //               imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
    //   //headers: {"token":`${db.token}`},
    // },
  // };

  const [testCase, setTestCase] = useState([]);
  const openToast = (message, type) => {
    setToastMessage(message);
    setToastType(type);
    setToastShow(true);
    setTimeout(() => {
      closeToast();
    }, 5000);
  };
  const addQuestion = async () => {
    const data = {
      skill_id: parseInt(courseId),
      title: title,
      description: description,
      score: parseInt(score),
      type: selectType,
      difficulty_level: selectDifficulity,
      options:
        selectType === "mcq"
          ? mcqData
          : selectType === "compiler"
          ? testCase
          : null,
    };
    var res = await postRequest("/questionBank/addQuestion", data, navigator);
    res = res.data;
    if (res.success && res.data !== null && res.data !== undefined) {
      navigator(
        "/reports/questionBank/course/question/" +
          btoa(
            CryptoJS.AES.encrypt(courseId.toString(), "!@#$%^&*()").toString()
          )
      );
    } else {
      openToast(res.error, "error");
    }
  };

  const handleRunCompiler = async () => {
    const res = await postComplierRequest(
      "/compiler/compile",
      {
        compiler_on: "practice",
        input: testCaseInput,
        language: language,
        code: code,
        user: localStorage.getItem("id"),
      },
      navigator
    );

    if (res.success === true) {
      if (res.data.success === true) {
        setTestCaseOutput(res.data.data.error + res.data.data.output);
      } else {
        setTestCaseOutput(res.data.error);
      }
    } else {
      setTestCaseOutput(res.error);
    }
  };

  return (
    <div>
      <h2 style={{ fontSize: 19, fontWeight: 500 }}>Add Question</h2>
      <br />
      <InputBox
        label="Question Title"
        placeholder="Enter Question Title"
        onChange={(e) => {
          setTitle(e.target.value);
        }}
      />

      <div style={{ marginTop: 20 }}>
        <label
          style={{
            color: "grey",
            fontSize: 13,
          }}
        >
          Question Description
        </label>
      </div>
      <div style={{ marginTop: 8 }}>
        <JoditEditor
          // ref={editor}
          // value={description}
          // ref={editor}
          // config={editorConfig}
          onChange={(value) => {
            setDescription(value);
          }}
        />
      </div>
      <div style={{ display: "flex", gap: 20, alignItems: "end" }}>
        <CustomSelect
          label="Task Type"
          placeholder="Select a Type"
          onChange={setSelectedType}
          options={[
            { label: "Compiler", value: "compiler" },
            { label: "File", value: "file" },
            { label: "MCQ", value: "mcq" },
            { label: "Manual Grading", value: "manual-grading" },
          ]}
        />
        {selectType === "compiler" ? (
          <CustomSelect
            label="Add Test Case Method"
            placeholder="Select a Method"
            onChange={setMethod}
            options={[
              { label: "Manual", value: "manual" },
              { label: "Auto", value: "auto" },
            ]}
          />
        ) : null}
        <CustomSelect
          label="Task Difficulity"
          placeholder="Select a Difficulity"
          onChange={setDifficulity}
          options={[
            { label: "Easy", value: "easy" },
            { label: "Medium", value: "medium" },
            { label: "Hard", value: "hard" },
          ]}
        />
        <div style={{ flex: 3 }}>
          <label
            htmlFor={"score"}
            style={{
              color: "grey",
              fontSize: 13,
            }}
          >
            Task Score
          </label>
          <br />
          <input
            placeholder="Task Score"
            onChange={(e) => {
              setScore(e.target.value);
            }}
            type="number"
            style={{
              width: "92%",
              padding: "12px 15px",
              paddingLeft: 5,
              fontSize: 15,
              border: "0.5px solid grey",
              borderRadius: 5,
              marginTop: 5,
            }}
          />
        </div>
      </div>

      {selectType === "compiler" && selectMethod === "auto" ? (
        <>
          <div
            style={{
              backgroundColor: "#1e1e1e",
              borderRadius: 15,
              padding: 10,
              marginTop: 20,
            }}
          >
            <div style={{ width: 200 }}>
              <Select
                styles={{
                  input: (styles) => ({ ...styles, color: "white" }),
                  singleValue: (styles) => ({ ...styles, color: "white" }),
                  control: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: "#1e1e1e",
                    border: "1px solid grey",
                    outline: "none",
                  }),
                }}
                placeholder="Selct a Language"
                options={[
                  { label: "C", value: "c" },
                  { label: "C++", value: "cpp" },
                  { label: "Python", value: "py" },
                  { label: "Java", value: "java" },
                  { label: "SQL", value: "sql" },
                ]}
                onChange={(e) => {
                  setLanguage(e.value);
                }}
              />
            </div>
            <br />
            <Editor
              options={{
                fontSize: 17,
                fontFamily: "Roboto",
                fontWeight: 500,
                tabSize: 8,
                letterSpacing: 1.3,
                // lineNumbers: false,
              }}
              height={250}
              width={"100%"}
              theme={"vs-dark"}
              language={language === "py" ? "python" : language}
              value={code}
              onChange={(value) => {
                setCode(value);
              }}
            />
          </div>

          <div style={{ display: "flex", gap: 10, alignItems: "end" }}>
            <CustomTextArea
              disabled={false}
              label="Input"
              // value={testCaseInput}
              onChange={(e) => {
                setTestCaseInput(e.target.value);
              }}
            />

            <CustomTextArea
              label="Output"
              value={testCaseOutput}
              disabled={true}
              onChange={(e) => {
                setTestCaseOutput(e.target.value.replace(/\n/g, "<br/>"));
              }}
            />

            <div style={{ minWidth: 350 }}>
              <InputBox
                label="Score"
                onChange={(e) => {
                  setOptionScore(e.target.value);
                }}
              />
              <div style={{ display: "flex", gap: 10, marginTop: 10 }}>
                <CustomButton
                  label="Run"
                  func={() => {
                    setTestCaseOutput("Compiling...");
                    handleRunCompiler();
                  }}
                />
                <CustomButton
                  label="Add"
                  func={() => {
                    if (
                      testCaseOutput !== "" &&
                      testCaseOutput !== "Compiling..."
                    ) {
                      setTestCase([
                        ...testCase,
                        {
                          input: testCaseInput.replace(/\n/g, "<br/>"),
                          output: testCaseOutput.replace(/\n/g, "<br/>"),
                          score: parseInt(optionScore),
                        },
                      ]);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ) : null}

      {selectType === "mcq" ? (
        <MCQTable data={mcqData} handleOpen={handleOpen} />
      ) : selectType === "compiler" ? (
        <TestCaseTable
          showAdd={selectMethod === "manual"}
          data={testCase}
          handleOpen={handleOpen}
          buttonAction={(index) => {
            setSelectedOption(index);
            setTestCaseInput(testCase[index].input);
            setTestCaseOutput(testCase[index].output);
            setOptionScore(testCase[index].score);
            handleOpenUpdate();
          }}
        />
      ) : null}
      <CustomButton label="Add Question" margin={30} func={addQuestion} />

      <CustomDialog
        open={selectType === "mcq" && open}
        handleClose={handleClose}
        title={"Add Options"}
        body={
          <>
            <div style={{ width: "95%" }}>
              <InputBox
                label="Option Label"
                margin={20}
                placeholder="Label"
                onChange={(e) => {
                  setOptionLabel(e.target.value);
                }}
              />
              <InputBox
                label="Option Score"
                type="number"
                margin={20}
                placeholder="Score"
                onChange={(e) => {
                  setOptionScore(e.target.value);
                }}
              />
            </div>
            <CustomButton
              margin={20}
              label="Add Option"
              func={() => {
                setMcqData([
                  ...mcqData,
                  { label: optionLabel, score: parseInt(optionScore) },
                ]);
                handleClose();
              }}
            />
          </>
        }
      />

      <CustomDialog
        open={selectType === "compiler" && open}
        handleClose={handleClose}
        title={"Add Test Case"}
        body={
          <>
            <div style={{ width: "95%" }}>
              <h4
                style={{
                  color: "grey",
                  fontSize: 13,
                  fontWeight: 500,
                  marginTop: 20,
                }}
              >
                Input
              </h4>
              <textarea
                onChange={(e) => {
                  setTestCaseInput(e.target.value.replace(/\n/g, "<br/>"));
                }}
                style={{
                  width: "100%",
                  minWidth: "100%",
                  maxWidth: "100%",
                  padding: 12,
                  paddingLeft: 5,
                  fontSize: 15,
                  border: "0.5px solid grey",
                  borderRadius: 5,
                  marginTop: 5,
                  minHeight: 60,
                  maxHeight: 100,
                }}
              />

              <h4
                style={{
                  color: "grey",
                  fontSize: 13,
                  fontWeight: 500,
                  marginTop: 20,
                }}
              >
                Output
              </h4>
              <textarea
                onChange={(e) => {
                  setTestCaseOutput(e.target.value.replace(/\n/g, "<br/>"));
                }}
                style={{
                  width: "100%",
                  minWidth: "100%",
                  maxWidth: "100%",
                  padding: 12,
                  paddingLeft: 5,
                  fontSize: 15,
                  border: "0.5px solid grey",
                  borderRadius: 5,
                  marginTop: 5,
                  minHeight: 60,
                  maxHeight: 100,
                }}
              />

              <InputBox
                label="Test Case Score"
                type="number"
                margin={20}
                placeholder="Score"
                onChange={(e) => {
                  setOptionScore(e.target.value);
                }}
              />
            </div>
            <CustomButton
              margin={20}
              label="Add Test Case"
              func={() => {
                setTestCase([
                  ...testCase,
                  {
                    input: testCaseInput,
                    output: testCaseOutput,
                    score: parseInt(optionScore),
                  },
                ]);
                handleClose();
              }}
            />
          </>
        }
      />

      <CustomDialog
        open={selectType === "compiler" && openUpdate}
        handleClose={handleCloseUpdate}
        title={"Update Test Case"}
        body={
          <>
            <div style={{ width: "95%" }}>
              <h4
                style={{
                  color: "grey",
                  fontSize: 13,
                  fontWeight: 500,
                  marginTop: 20,
                }}
              >
                Input
              </h4>
              <textarea
                defaultValue={
                  selectedOption !== -1
                    ? testCase[selectedOption].input.replace(/<br\/>/g, "\n")
                    : null
                }
                onChange={(e) => {
                  setTestCaseInput(e.target.value.replace(/\n/g, "<br/>"));
                }}
                style={{
                  width: "100%",
                  minWidth: "100%",
                  maxWidth: "100%",
                  padding: 12,
                  paddingLeft: 5,
                  fontSize: 15,
                  border: "0.5px solid grey",
                  borderRadius: 5,
                  marginTop: 5,
                  minHeight: 60,
                  maxHeight: 100,
                }}
              />

              <h4
                style={{
                  color: "grey",
                  fontSize: 13,
                  fontWeight: 500,
                  marginTop: 20,
                }}
              >
                Output
              </h4>
              <textarea
                defaultValue={
                  selectedOption !== -1
                    ? testCase[selectedOption].output.replace(/<br\/>/g, "\n")
                    : null
                }
                onChange={(e) => {
                  setTestCaseOutput(e.target.value.replace(/\n/g, "<br/>"));
                }}
                style={{
                  width: "100%",
                  minWidth: "100%",
                  maxWidth: "100%",
                  padding: 12,
                  paddingLeft: 5,
                  fontSize: 15,
                  border: "0.5px solid grey",
                  borderRadius: 5,
                  marginTop: 5,
                  minHeight: 60,
                  maxHeight: 100,
                }}
              />

              <InputBox
                defaultValue={
                  selectedOption !== -1 ? testCase[selectedOption].score : null
                }
                label="Test Case Score"
                type="number"
                margin={20}
                placeholder="Score"
                onChange={(e) => {
                  setOptionScore(e.target.value);
                }}
              />
            </div>
            <CustomButton
              margin={20}
              label="Update Test Case"
              func={() => {
                const updatedTestCase = [...testCase];
                updatedTestCase[selectedOption] = {
                  id: testCase[selectedOption].id,
                  input: testCaseInput,
                  output: testCaseOutput,
                  score: parseInt(optionScore),
                };
                setTestCase(updatedTestCase);
                handleCloseUpdate();
              }}
            />
          </>
        }
      />

      {toastShow ? (
        <ToastMsg message={toastMessage} type={toastType} close={closeToast} />
      ) : null}
    </div>
  );
}

export default AddQuestionBank;
