import InputBox from "../../../compounds/input";
import AppLayout from "../../../layout";
import JoditEditor from "jodit-react";
import BasicCard from "../../../compounds/cards";
import { useRef, useState } from "react";
import CustomSelect from "../../../compounds/select";
import CustomButton from "../../../compounds/button";
import MCQTable from "./compounds/mcq_table";
import CustomDialog from "../../../compounds/dialog";
import TestCaseTable from "./compounds/testcase_table";
import postRequest from "../../../function/axios";
import { useNavigate, useParams } from "react-router-dom";
import ToastMsg from "../../../compounds/toast";

import CryptoJS from "crypto-js";
function AddAssessmentQuestion() {
  return (
    <AppLayout
      rId={5}
      body={
        <div style={{ margin: 20 }}>
          <BasicCard
            overflow="auto"
            padding={20}
            color="white"
            body={<Body />}
          />
        </div>
      }
    />
  );
}

function Body() {
  const navigator = useNavigate();
  const editor = useRef(null);
  const [selectType, setSelectedType] = useState("");
  const [selectDifficulity, setDifficulity] = useState("");
  const [open, setOpen] = useState(false);
  const [optionLabel, setOptionLabel] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [score, setScore] = useState(0);
  const [testCaseInput, setTestCaseInput] = useState("");
  const [testCaseOutput, setTestCaseOutput] = useState("");
  const [optionScore, setOptionScore] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  var { slot } = useParams();
  slot = CryptoJS.AES.decrypt(atob(slot), "!@#$%^&*()").toString(
    CryptoJS.enc.Utf8
  );
  const buttons = [
    "undo",
    "redo",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "|",
    "superscript",
    "subscript",
    "|",
    "align",
    "|",
    "ul",
    "ol",
    "outdent",
    "indent",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "link",
    "table",
    "|",
    "hr",
    "eraser",
    "copyformat",
    "|",
    "fullsize",
    "selectall",
    "print",
    "|",
    "symbols",
  ];

  const [mcqData, setMcqData] = useState([]);
  const closeToast = () => {
    setToastShow(false);
  };
  const editorConfig = {
    // readonly: false,
    // toolbar: true,
    // maxHeight: 400,
    // spellcheck: true,
    // language: "en",
    // toolbarButtonSize: "medium",
    // toolbarAdaptive: false,
    // showCharsCounter: true,
    // showWordsCounter: true,
    // showXPathInStatusbar: false,
    // askBeforePasteHTML: true,
    // askBeforePasteFromWord: true,
    // placeholder: "Enter Description...",
    // //defaultActionOnPaste: "insert_clear_html",
    // buttons: buttons,
    // uploader: {
    //   // url: '/manage/upload',  //your upload api url
    //   //               insertImageAsBase64URI: false,
    //   //               imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
    //   //headers: {"token":`${db.token}`},
    // },
  };

  const [testCase, setTestCase] = useState([]);
  const openToast = (message, type) => {
    setToastMessage(message);
    setToastType(type);
    setToastShow(true);
    const timer = setTimeout(() => {
      closeToast();
    }, 5000);
  };
  const addQuestion = async () => {
    const data = {
      slot_id: parseInt(slot),
      title: title,
      description: description,
      score: parseInt(score),
      type: selectType,
      difficulty_level: selectDifficulity,
      options:
        selectType === "mcq"
          ? mcqData
          : selectType === "compiler"
          ? testCase
          : null,
    };
    var res = await postRequest("/assessment/question/add", data, navigator);
    res = res.data;
    if (res.success && res.data !== null && res.data !== undefined) {
      navigator(
        "/slot/assessment/Question/" +
          btoa(
            CryptoJS.AES.encrypt(slot.toString(), "!@#$%^&*()").toString()
          )
      );
    } else {
      openToast(res.error, "error");
    }
  };

  return (
    <div>
      <h2 style={{ fontSize: 19, fontWeight: 500 }}>Add Question</h2>

      <div style={{ marginTop: 20, width: "98%" }}>
        <InputBox
          label="Question Title"
          placeholder="Enter Question Title"
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </div>

      <div style={{ marginTop: 20 }}>
        <label
          style={{
            color: "grey",
            fontSize: 13,
          }}
        >
          Question Description
        </label>
      </div>
      <div style={{ marginTop: 8 }}>
        <JoditEditor
          // ref={editor}
          // value={description}
          // ref={editor}
          // config={editorConfig}
          onChange={(value) => {
            setDescription(value);
          }}
        />
      </div>
      <div style={{ display: "flex", gap: 20, alignItems: "end" }}>
        <CustomSelect
          label="Task Type"
          placeholder="Select a Type"
          onChange={setSelectedType}
          options={[
            { label: "Compiler", value: "compiler" },
            { label: "File", value: "file" },
            { label: "MCQ", value: "mcq" },
          ]}
        />
        <CustomSelect
          label="Task Difficulity"
          placeholder="Select a Difficulity"
          onChange={setDifficulity}
          options={[
            { label: "Easy", value: "easy" },
            { label: "Medium", value: "medium" },
            { label: "Hard", value: "hard" },
          ]}
        />
        <div style={{ flex: 3 }}>
          <label
            htmlFor={"score"}
            style={{
              color: "grey",
              fontSize: 13,
            }}
          >
            Task Score
          </label>
          <br />
          <input
            placeholder="Task Score"
            onChange={(e) => {
              setScore(e.target.value);
            }}
            type="number"
            style={{
              width: "92%",
              padding: "12px 15px",
              paddingLeft: 5,
              fontSize: 15,
              border: "0.5px solid grey",
              borderRadius: 5,
              marginTop: 5,
            }}
          />
        </div>
      </div>

      {selectType === "mcq" ? (
        <MCQTable data={mcqData} handleOpen={handleOpen} />
      ) : selectType === "compiler" ? (
        <TestCaseTable data={testCase} handleOpen={handleOpen} />
      ) : null}
      <CustomButton label="Add Question" margin={30} func={addQuestion} />

      <CustomDialog
        open={selectType === "mcq" && open}
        handleClose={handleClose}
        title={"Add Options"}
        body={
          <>
            <div style={{ width: "95%" }}>
              <InputBox
                label="Option Label"
                margin={20}
                placeholder="Label"
                onChange={(e) => {
                  setOptionLabel(e.target.value);
                }}
              />
              <InputBox
                label="Option Score"
                type="number"
                margin={20}
                placeholder="Score"
                onChange={(e) => {
                  setOptionScore(e.target.value);
                }}
              />
            </div>
            <CustomButton
              margin={20}
              label="Add Option"
              func={() => {
                setMcqData([
                  ...mcqData,
                  { label: optionLabel, score: parseInt(optionScore) },
                ]);
                handleClose();
              }}
            />
          </>
        }
      />

      <CustomDialog
        open={selectType === "compiler" && open}
        handleClose={handleClose}
        title={"Add Test Case"}
        body={
          <>
            <div style={{ width: "95%" }}>
              <h4
                style={{
                  color: "grey",
                  fontSize: 13,
                  fontWeight: 500,
                  marginTop: 20,
                }}
              >
                Input
              </h4>
              <textarea
                onChange={(e) => {
                  setTestCaseInput(e.target.value.replace(/\n/g, "<br/>"));
                }}
                style={{
                  width: "100%",
                  minWidth: "100%",
                  maxWidth: "100%",
                  padding: 12,
                  paddingLeft: 5,
                  fontSize: 15,
                  border: "0.5px solid grey",
                  borderRadius: 5,
                  marginTop: 5,
                  minHeight: 60,
                  maxHeight: 100,
                }}
              />

              <h4
                style={{
                  color: "grey",
                  fontSize: 13,
                  fontWeight: 500,
                  marginTop: 20,
                }}
              >
                Output
              </h4>
              <textarea
                onChange={(e) => {
                  setTestCaseOutput(e.target.value.replace(/\n/g, "<br/>"));
                }}
                style={{
                  width: "100%",
                  minWidth: "100%",
                  maxWidth: "100%",
                  padding: 12,
                  paddingLeft: 5,
                  fontSize: 15,
                  border: "0.5px solid grey",
                  borderRadius: 5,
                  marginTop: 5,
                  minHeight: 60,
                  maxHeight: 100,
                }}
              />

              <InputBox
                label="Test Case Score"
                type="number"
                margin={20}
                placeholder="Score"
                onChange={(e) => {
                  setOptionScore(e.target.value);
                }}
              />
            </div>
            <CustomButton
              margin={20}
              label="Add Test Case"
              func={() => {
                setTestCase([
                  ...testCase,
                  {
                    input: testCaseInput,
                    output: testCaseOutput,
                    score: parseInt(optionScore),
                  },
                ]);
                handleClose();
              }}
            />
          </>
        }
      />

      {toastShow ? (
        <ToastMsg message={toastMessage} type={toastType} close={closeToast} />
      ) : null}
    </div>
  );
}

export default AddAssessmentQuestion;
