import { useEffect, useState } from "react";
import CustomSelect from "../../compounds/select"
import postRequest from "../../function/axios";
import AppLayout from "../../layout"
import { useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import BasicCard from "../../compounds/cards";
import CustomTable from "../../compounds/table";

function AnalysisDashboard() {
    return <AppLayout rId={14} body={<Body />} />
}

function Body() {

    const navigator = useNavigate();
    const [verticals, setVerticals] = useState(null);
    const [selectedYear, setSelectedYear] = useState(0)
    const [selectedSkill, setSelectedSkill] = useState(0)
    const [courseData, setCourseData] = useState([
        {
            name: "Programming Level -1",
            registered: 342,
            booked: 212,
            attended: 211,
            absent: 1,
            cleared: 32,
            percentage: 21
        }
    ]);
    const tableHeader = ["Course Level", "Registered", "Booked Slot", "Attended", "Absent", "Cleared", "Percentage"];
    const tableFields = ["course_name", "registered", "booked", "attended", "absent", "cleared", "percentage"];

    const [series, setSeries] = useState([])
    const [options, setOptions] = useState({
        chart: {
            type: 'donut',
            width: 40,
            height: 40,
            sparkline: {
                enabled: true
            }
        },
        labels: [],

        stroke: {
            width: 1
        },
        tooltip: {
            fixed: {
                enabled: true,
            },
        }
    });

    const handleSkillChange = (id) => {
        setSelectedSkill(id)
        fetchSkillData(id, selectedYear);
    };

    const handleYearChange = (year) => {
        setSelectedYear(year)
        fetchSkillData(selectedSkill, year)
    }

    const fetchVertical = async () => {
        const res = await postRequest("/course/allVertical", {}, navigator);
        setVerticals(res.data.data);
        fetchSkillData(res.data.data[0].id, 0);
    };

    useEffect(() => {
        fetchVertical()
    }, [])

    const fetchSkillData = async (Skill, year) => {
        var res = await postRequest("/analysis/getSkillData", { skill: Skill, year: year }, navigator)
        if (res.success && res.data.success === true) {
            setCourseData(res.data.data)
            var temp = []
            var tempLabel = []
            for (let i = 0; i < res.data.data.length; i++) {
                temp.push(res.data.data[i].cleared)
                tempLabel.push(res.data.data[i].course_name)
            }

            setSeries(temp)
            setOptions({
                chart: {
                    type: 'donut',
                    width: 40,
                    height: 40,
                    sparkline: {
                        enabled: true
                    }
                },
                labels: tempLabel,

                stroke: {
                    width: 1
                },
                tooltip: {
                    fixed: {
                        enabled: true,
                    },
                }
            })
        }
    }

    return <div className="dashboard-body">

        <h3 style={{ fontWeight: 500, fontSize: 23, marginLeft: 5 }}>
            Welcome {localStorage.getItem("name")},
        </h3>

        <div
            style={{
                display: "flex",
                alignItems: "center",
                gap: 20,
                marginTop: 20,
            }}
        >
            <CustomSelect
                label="Course Name"
                options={
                    verticals === null
                        ? []
                        : verticals.map((item) => ({
                            label: item.name,
                            value: item.id,
                        }))
                }
                onChange={handleSkillChange}
                margin={1}
                // widthFull={true}
                placeholder={"Select a Course"}
            />
            <CustomSelect
                label="Year"
                margin={1}
                widthFull={true}
                options={[
                    { label: "All Year", value: 0 },
                    { label: "I Year", value: 1 },
                    { label: "II Year", value: 2 },
                    { label: "III Year", value: 3 },
                    { label: "IV Year", value: 4 },
                ]}
                onChange={handleYearChange}
                placeholder={"Select a Year"}
            />


        </div>
        <BasicCard
            margin={20}
            color="white"
            maxWidth={"max-content"}
            padding={20}
            body={
                <>
                    <ReactApexChart options={options} series={series} type="donut" height={180} width={250} />
                    <center><h3 style={{ marginTop: 15 }}>Skill Wise Cleared</h3></center>
                </>
            }
        />

        <br />
        <CustomTable
            actionType={"none"}
            disableHeader={true}
            data={courseData}
            header={tableHeader}
            field={tableFields} />
    </div>
}

export default AnalysisDashboard