import "./style.css";
import AppLayout from "../../layout/index";
import CustomTable from "../../compounds/table";
import BasicCard from "../../compounds/cards/index";
import { EditRounded } from "@mui/icons-material";
import InputBox from "../../compounds/input";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import CustomSelect from "../../compounds/select";
import CustomButton from "../../compounds/button";
import ToastMsg from "../../compounds/toast";
import primaryColor from "../../theme/colors";
import axios from "axios";
import {api} from "../../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import { CircularProgress } from "@mui/material";

function CourseList() {
  return <AppLayout rId={2} body={<Body />} />;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: 2,
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  p: 3,
};

function Body() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const navigate = useNavigate();
  const [verticals, setVerticals] = useState([]);
  const [course, setCourse] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const tableHeader = ["Name", "Pre - Request", "My Status"];
  const tableFields = ["name", "pre_request", "status"];
  const [selectedVerticals, setSelectedVertical] = useState(0);
  const [selectedCourse, setSelectedCourse] = useState(0);
  const [courseName, setCourseName] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [courseDetails, setCourseDetails] = useState(0);
  const addCourseAccess = localStorage.getItem("addCourse") === "1";
  var { courseId } = useParams();
  courseId = CryptoJS.AES.decrypt(atob(courseId), "!@#$%^&*()").toString(
    CryptoJS.enc.Utf8
  );
  const openToast = (message, type) => {
    setToastMessage(message);
    setToastType(type);
    setToastShow(true);
    const timer = setTimeout(() => {
      closeToast();
    }, 5000);
  };

  const closeToast = () => {
    setToastShow(false);
  };

  const fetchCourse = () => {
    try {
      axios({
        url: api + "/course/allCourse",
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("auth"),
        },
        data: {
          user_id: localStorage.getItem("id"),
          id: parseInt(courseId),
        },
      })
        .then((res) => {
          if (res.data.success && res.data.data != null) {
            setCourseData(res.data.data);
          }
        })
        .catch((err) => {
          openToast(err.message, "error");
          if (err.response !== undefined && err.response.status === 401) {
            navigate("/auth/login");
          }
        });
    } catch (error) {
      openToast(error.message, "error");
    }
  };

  const fetchCourseList = (id) => {
    setCourse([]);
    try {
      axios({
        url: api + "/course/allCourse",
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("auth"),
        },
        data: {
          user_id: localStorage.getItem("id"),
          id: parseInt(id),
        },
      })
        .then((res) => {
          if (res.data.success && res.data.data != null) {
            setCourse(res.data.data);
          }
        })
        .catch((err) => {
          openToast(err.message, "error");
          if (err.response !== undefined && err.response.status === 401) {
            navigate("/auth/login");
          }
        });
    } catch (error) {
      openToast(error.message, "error");
    }
  };

  const addCourse = () => {
    try {
      axios({
        url: api + "/course/addCourse",
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("auth"),
        },
        data: {
          name: courseName,
          description: courseDescription,
          pre_request: selectedCourse.toString(),
          id: parseInt(courseId),
        },
      })
        .then((res) => {
          if (res.data.success) {
            openToast(res.data.message, "success");
          } else {
            openToast(res.data.error, "error");
          }
        })
        .catch((err) => {
          openToast(err.message, "error");
          if (err.response !== undefined && err.response.status === 401) {
            navigate("/auth/login");
          }
        });
    } catch (error) {
      openToast(error.message, "error");
    }
    fetchCourseDetails();
    fetchCourse();
    fetchVerticals();
    handleClose();
  };

  const fetchCourseDetails = () => {
    try {
      axios({
        url: api + "/course/vertical/details",
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("auth"),
        },
        data: {
          id: parseInt(courseId),
        },
      })
        .then((res) => {
          if (res.data.success && res.data.data !== null) {
            setCourseDetails(res.data.data);
          } else {
            openToast(res.data.error, "error");
          }
        })
        .catch((err) => {
          openToast(err.message, "error");
          if (err.response !== undefined && err.response.status === 401) {
            navigate("/auth/login");
          }
        });
    } catch (error) {
      openToast(error.message, "error");
    }
  };

  const fetchVerticals = () => {
    try {
      axios({
        url: api + "/course/allVertical",
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("auth"),
        },
      })
        .then((res) => {
          if (res.data.success && res.data.data !== null) {
            setVerticals(res.data.data);
          }
        })
        .catch((err) => {
          if (err.response !== undefined && err.response.status === 401) {
            navigate("/auth/login");
          }
        });
    } catch (error) {
      // console.log(error);
    }
  };

  const openRegistered = (item) => {
    navigate(
      "/course/registered/" +
        btoa(
          CryptoJS.AES.encrypt(
            item.id.toString(),
            "!@#$%^&*()"
          ).toString()
        )
    );
  };

  useEffect(() => {
    fetchCourseDetails();
    fetchCourse();
    fetchVerticals();
  }, []);

  const handleVertical = (value) => {
    setSelectedVertical(value);
    fetchCourseList(value);
  };

  return courseDetails !== 0 ? (
    <div className="course-list" style={{ padding: 20 }}>
      <CustomTable
        title={courseDetails.name}
        description={
          <p>
            Here you can find the Courses and Levels of{" "}
            <span style={{ color: primaryColor, fontWeight: 600 }}>
              {courseDetails.name}
            </span>
            .
          </p>
        }
        button={addCourseAccess ? handleOpen : undefined}
        buttonLabel={"+ Add Course"}
        data={courseData}
        header={tableHeader}
        field={tableFields}
        buttonAction={addCourseAccess ? openRegistered : () => {}}
      />
      <BasicCard
        color="white"
        body={
          <>
            <img
              width={"100%"}
              alt="course-img"
              src={
                "https://ps.bitsathy.ac.in/images/course/" + courseDetails.img
              }
            />
            <div className="course-card-content">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2 style={{ fontSize: 21, fontWeight: 500 }}>
                  {courseDetails.name}
                </h2>
                <EditRounded style={{ cursor: "pointer" }} fontSize="sm" />
              </div>
              <br />
              {/* <h5>1- Completed</h5>
              <h5 style={{ marginTop: 5 }}>1- OnGoing</h5> */}
            </div>
          </>
        }
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Add New Course
            </Typography>
            <div style={{ width: "94%" }}>
              <InputBox
                margin={10}
                label={"Course Name"}
                placeholder="Course Name"
                onChange={(e) => {
                  setCourseName(e.target.value);
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <label
                style={{
                  color: "grey",
                  fontSize: 13,
                }}
              >
                Decription
              </label>
              <br />
              <textarea
                onChange={(e) => {
                  setCourseDescription(e.target.value);
                }}
                style={{
                  width: "93%",
                  maxWidth: "93%",
                  minWidth: "93%",
                  minHeight: 50,
                  padding: 12,
                  fontSize: 15,
                  border: "0.5px solid grey",
                  borderRadius: 5,
                  marginTop: 5,
                  outline: "none",
                }}
              />
            </div>
            <CustomSelect
              onChange={handleVertical}
              label="Select Pre - Request Vertical"
              options={verticals.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              placeholder="Select any Vertical"
            />
            <CustomSelect
              onChange={setSelectedCourse}
              label="Select Pre - Request Course"
              options={course.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              placeholder="Select any Course"
            />
            <CustomButton func={addCourse} margin={20} label={"Add Course"} />
          </Box>
        </Fade>
      </Modal>

      {toastShow ? (
        <ToastMsg message={toastMessage} type={toastType} close={closeToast} />
      ) : null}
    </div>
  ) : (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress size={50} />
    </div>
  );
}

export default CourseList;
