import { useEffect, useState } from "react";
import CustomTable from "../../compounds/table";
import AppLayout from "../../layout";
import CustomSelect from "../../compounds/select";
import InputBox from "../../compounds/input";
import CustomButton from "../../compounds/button";
import postRequest from "../../function/axios";
import { useNavigate } from "react-router-dom";

function EvaluationAssign() {
  const navigate = useNavigate();
  const [courseData, setCourseData] = useState([]);
  const [evaluators, setEvaluators] = useState([]);
  const [selectedEvaluator, setSelectedEvaluator] = useState(0);
  const tableHeader = ["Course", "Evaluator", "My Status"];
  const tableFields = ["course", "evaluator", "status"];
  const [maxUser, setMaxUser] = useState("");

  const fetchAssignStatus = async () => {
    var res = await postRequest("/evaluation/assignStatus", {}, navigate);
    if (res.success === true && res.data.success === true) {
      setCourseData(res.data.data);
    }
  };

  const fetchEvaluators = async () => {
    var res = await postRequest("/evaluation/evaluators", {}, navigate);
    if (res.success === true && res.data.success === true) {
      setEvaluators(res.data.data);
    }
  };

  const handleAssign = async () => {
    var res = await postRequest(
      "/evaluation/assign",
      { user: selectedEvaluator, max_response: parseInt(maxUser) },
      navigate
    );
    console.log(res)
    if (res.success === true && res.data.success === true) {
      fetchAssignStatus();
    }
  };

  useEffect(() => {
    fetchAssignStatus();
    fetchEvaluators();
  }, []);

  return (
    <AppLayout
      rId={17}
      body={
        <div style={{ padding: 20 }}>
          <CustomTable
            title="Assign Evaluators"
            description={
              <div className="evaluation-assign-row">
                <InputBox
                  placeholder="Max Evaluation"
                  label="Max Evaluation"
                  type="number"
                  onChange={(e)=>{setMaxUser(e.target.value)}}
                />
                <CustomSelect
                  width={"100%"}
                  widthFull={true}
                  margin={"0px"}
                  label="Select any Evaluators"
                  onChange={setSelectedEvaluator}
                  options={evaluators.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                />
                <CustomButton func={handleAssign} label="Assign Evaluation" />
              </div>
            }
            data={courseData}
            header={tableHeader}
            field={tableFields}
          />
        </div>
      }
    />
  );
}

export default EvaluationAssign;
