import { useState } from "react";
import CustomButton from "../../compounds/button";
import CourseCard from "../../compounds/cards/course";
import Backdrop from "@mui/material/Backdrop";
import AppLayout from "../../layout";
import "./style.css";
import axios from "axios";
import {api} from "../../utils/api";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Fade, Modal, Typography } from "@mui/material";
import CustomSelect from "../../compounds/select";
import InputBox from "../../compounds/input";
import ToastMsg from "../../compounds/toast";
import ErrorText from "../../compounds/error";

function Course() {
  return <AppLayout body={<Body />} rId={2} />;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: 2,
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  p: 3,
};

function Body() {
  const [vertical, setVertical] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedVerticals, setSelectedVertical] = useState(0);
  const [verticalName, setVerticalName] = useState("");
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [errorText, setErrorText] = useState("");
  const addCourse = localStorage.getItem("addCourse")==="1"

  const closeToast = () => {
    setToastShow(false);
  };

  const fetchVerticals = () => {
    try {
      axios({
        url: api + "/course/allVertical",
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("auth"),
        },
      })
        .then((res) => {
          if (res.data.success && res.data.data !== null) {
            setVertical(res.data.data);
          }
        })
        .catch((err) => {
          if (err.response!==undefined  && err.response.status === 401) {
            navigate("/auth/login");
          }
        });
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchVerticals();
  }, []);

  const openToast = (message, type) => {
    setToastMessage(message);
    setToastType(type);
    setToastShow(true);
    const timer = setTimeout(() => {
      closeToast();
    }, 5000);
  };

  const [file, setFile] = useState(null); 

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = (event) => {
    event.preventDefault();

    if (event.target.elements.name.value.trim() === "") {
      setErrorText("Please fill the Course Name");
      return;
    }

    if (!file) {
      setErrorText("Please select a file to upload");
      return;
    }

    const formData = new FormData();
    formData.append("image", file);
    formData.append("name", event.target.elements.name.value);
    formData.append("pre_request", selectedVerticals);

    axios
      .post(api + "/course/vertical/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("auth"),
        },
      })
      .then((res) => {
        if (res.data.success) {
          openToast(res.data.message, "success");
          setOpen(false);
          fetchVerticals();
        } else {
          openToast(res.data.error, "error");
        }
      })
      .catch((error) => {
        console.error(error);
        openToast(error.message, "error");
      });
  };

  return (
    <div style={{ padding: 20, display: "flex", flexDirection: "column" }}>
      <h3 style={{ fontWeight: 500, fontSize: 23 }}>Course</h3>
      <div style={{ width: "max-content", marginTop: 10 }}>
        {
          addCourse 
          ? <CustomButton label="+ Add New Course" func={handleOpen} />
          : null
        }
      </div>

      <div className="course-card-row">
        {vertical.length === 0 ? (
          isLoading ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <h3 style={{ fontWeight: 500, marginTop: 20 }}>No Course Found</h3>
          )
        ) : (
          vertical.map((item, i) => (
            <CourseCard
              path={"/course/"}
              key={i}
              id={item.id}
              title={item.name}
              value={item.completed}
              img={item.img}
            />
          ))
        )}
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form onSubmit={handleUpload}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Add New Vertical
              </Typography>
              <div style={{ width: "94%" }}>
                <InputBox
                  margin={10}
                  onChange={setVerticalName}
                  label={"Vertical Name"}
                  name="name"
                  placeholder="Vertical Name"
                />
                <InputBox
                  type={"file"}
                  margin={20}
                  label={"Vertical Image"}
                  name="img"
                  onChange={handleFileChange}
                  placeholder="Vertical Image"
                />
              </div>
              <CustomSelect
                onChange={setSelectedVertical}
                label="Select Pre - Request Vertical"
                options={vertical.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                placeholder="Select any Vertical"
              />
              <ErrorText label={errorText} />
              <CustomButton margin={20} label={"Add Vertical"} />
            </form>
          </Box>
        </Fade>
      </Modal>

      {toastShow ? (
        <ToastMsg message={toastMessage} type={toastType} close={closeToast} />
      ) : null}
    </div>
  );
}

export default Course;
