import { useEffect, useState } from "react";
import AppLayout from "../../layout";
import CryptoJS from "crypto-js";
import { useNavigate, useParams } from "react-router-dom";
import CustomTable from "../../compounds/table";
import postRequest from "../../function/axios";
import CustomDialog from "../../compounds/dialog";
import TestCaseTable from "../assessment/compounds/testcase";
import MCQOptions from "../assessment/compounds/mcq_view";
import { DeleteRounded, EditRounded } from "@mui/icons-material";

function CourseQuestions() {
  return <AppLayout rId={6} body={<Body />} />;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "70%",
  borderRadius: 2,
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  overflow: "auto",
  p: 2,
};

function Body() {
  const navigate = useNavigate();
  const [questionData, setquestionData] = useState([]);
  const tableHeader = ["Type", "Title", "Assigned", "Cleared", "Max Score"];
  const tableFields = [
    "type",
    "title",
    "count_assigned",
    "max_cleared",
    "score",
  ];
  var { courseId } = useParams();
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  courseId = CryptoJS.AES.decrypt(atob(courseId), "!@#$%^&*()").toString(
    CryptoJS.enc.Utf8
  );

  const fetchQuestion = async () => {
    const res = await postRequest(
      "/questionBank/getQuestion",
      { skill_id: parseInt(courseId) },
      navigate
    );
    if (res.success && res.data.data !== null && res.data.data !== undefined) {
      setquestionData(res.data.data);
    }
  };

  const handleDeleteQuestion = async () => {
    if (
      window.confirm(localStorage.getItem("name") + ", Are you sure to Delete?")
    ) {
      const res = await postRequest(
        "/questionBank/deleteQuestion",
        { id: parseInt(selectedQuestion.id) },
        navigate
      );
      console.log(res);
      if (
        res.success &&
        res.data.data !== null &&
        res.data.data !== undefined
      ) {
        fetchQuestion();
        handleClose();
      }
    }
  };

  const handleEditQuestion = async () => {
    navigate(
      "/reports/questionBank/course/question/edit/" +
        btoa(
          CryptoJS.AES.encrypt(
            selectedQuestion.id.toString(),
            "!@#$%^&*()"
          ).toString()
        )
    );
  };

  const fetchQuestionDetails = async (id) => {
    var res = await postRequest(
      "/questionBank/viewQuestion",
      { id: parseInt(id) },
      navigator
    );
    if (res.success === true && res.data.success === true) {
      setSelectedQuestion(res.data.data);
    }
  };

  useEffect(() => {
    fetchQuestion();
  }, []);

  return (
    <div style={{ padding: 20 }}>
      <CustomTable
        button={() => {
          navigate(
            "/reports/questionBank/course/question/add/" +
              btoa(
                CryptoJS.AES.encrypt(
                  courseId.toString(),
                  "!@#$%^&*()"
                ).toString()
              )
          );
        }}
        buttonLabel="Add Question"
        title={"Assesment Question"}
        description={<>Here you can find the Assesment Question</>}
        data={questionData}
        header={tableHeader}
        field={tableFields}
        buttonAction={(item) => {
          setSelectedQuestion(item);
          fetchQuestionDetails(item.id);
          setOpen(true);
        }}
      />
      {open ? (
        <CustomDialog
          open={open}
          handleClose={handleClose}
          title={selectedQuestion.type + " Question"}
          action={
            <div style={{ display: "flex", gap: 10 }}>
              <div
                onClick={handleEditQuestion}
                style={{
                  backgroundColor: "#0270c9",
                  color: "white",
                  height: "max-content",
                  padding: 5,
                  borderRadius: 10,
                  cursor: "pointer",
                }}
              >
                <EditRounded />
              </div>

              <div
                onClick={handleDeleteQuestion}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  height: "max-content",
                  padding: 5,
                  borderRadius: 10,
                  cursor: "pointer",
                }}
              >
                <DeleteRounded />
              </div>
            </div>
          }
          style={style}
          body={
            <div
              style={{
                marginTop: 20,
              }}
            >
              <h4
                style={{
                  color: "grey",
                  fontSize: 13,
                  fontWeight: 500,
                }}
              >
                Question Title
              </h4>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 5,
                  gap: 10,
                }}
              >
                <h4
                  style={{
                    fontSize: 18,
                    fontWeight: 500,
                  }}
                >
                  {selectedQuestion.title}
                </h4>
                <h4
                  style={{
                    color: "red",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  {selectedQuestion.score + " Marks"}
                </h4>
              </div>
              <h4
                style={{
                  color: "grey",
                  fontSize: 13,
                  fontWeight: 500,
                  marginTop: 20,
                }}
              >
                Question Description
              </h4>
              <div
                style={{ marginTop: 5 }}
                dangerouslySetInnerHTML={{
                  __html: selectedQuestion.description,
                }}
              ></div>

              {selectedQuestion.type === "compiler"
                ? selectedQuestion.options.map((testCase, i) => (
                    <TestCaseTable
                      index={i}
                      input={testCase.input}
                      output={testCase.output}
                      score={testCase.score}
                    />
                    // console.log(testCase)
                  ))
                : null}

              {selectedQuestion.type === "mcq"
                ? selectedQuestion.options.map((testCase, i) => (
                    <MCQOptions
                      index={i}
                      label={testCase.label}
                      score={testCase.score}
                    />
                    // console.log(testCase)
                  ))
                : null}
            </div>
          }
        />
      ) : null}
    </div>
  );
}
export default CourseQuestions;
