import { EditRounded } from "@mui/icons-material";
import AppLayout from "../../layout";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Badge, CircularProgress, LinearProgress } from "@mui/material";
import CustomTable from "../../compounds/table";
import BasicCard from "../../compounds/cards";
import primaryColor from "../../theme/colors";
import postRequest from "../../function/axios";
import { useEffect } from "react";
import CryptoJS from "crypto-js";
import * as XLSX from "xlsx";

function CourseRegistered() {
  return <AppLayout rId={2} body={<Body />} />;
}

function Body() {
  const [courseData, setCourseData] = useState([]);
  var { courseId } = useParams();
  courseId = CryptoJS.AES.decrypt(atob(courseId), "!@#$%^&*()").toString(
    CryptoJS.enc.Utf8
  );
  const tableHeader = ["User Id", "Name", "Year", "Mark", "Attempts", "Status"];
  const tableFields = ["user_id", "name", "year", "mark", "attempts", "status"];
  const [courseDetails, setCourseDetails] = useState(null);

  const fetchRegisteredUser = async () => {
    var res = await postRequest(
      "/course/registered",
      { id: parseInt(courseId) },
      navigator
    );
    if (res.data.data !== null && res.data.data !== undefined) {
      setCourseData(res.data.data);
    }
  };

  const fetchCourseDetails = async () => {
    var res = await postRequest(
      "/course/detail",
      { id: parseInt(courseId) },
      navigator
    );
    if (res.data.data !== null && res.data.data !== undefined) {
      setCourseDetails(res.data.data);
    }
  };

  useEffect(() => {
    fetchCourseDetails();
    fetchRegisteredUser();
  }, []);

  const countStatus = (list, targetStatus) => {
    return list.reduce((count, i) => {
      if (i.status === targetStatus) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  const handleDownload = () => {

    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(courseData);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Students Registered");


    XLSX.writeFile(workbook, "Course Registered.xlsx", { compression: true });
  };
  return courseDetails ? (
    <div style={{ padding: 20 }}>
      <div className="course-list">
        <CustomTable
          button={() => {handleDownload()}}
          buttonAction={() => {}}
          buttonLabel="Download"
          title={courseDetails.name}
          description={
            <>
              Here you can find the Registered Users of{" "}
              <span style={{ color: primaryColor, fontWeight: 600 }}>
                {courseDetails.name}
              </span>
              .
            </>
          }
          data={courseData}
          header={tableHeader}
          field={tableFields}
        />
        <BasicCard
          overflow="none"
          color="white"
          body={
            <>
              <img
                width={"100%"}
                alt="course-img"
                src={
                  "https://ps.bitsathy.ac.in/images/course/" + courseDetails.img
                }
              />
              <div className="course-card-content">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 style={{ fontSize: 21, fontWeight: 500 }}>
                    {courseDetails.name}
                  </h2>
                  <EditRounded style={{ cursor: "pointer" }} fontSize="sm" />
                </div>
                <div
                  style={{
                    marginTop: 5,
                    display: "flex",
                    gap: 5,
                    flexWrap: "wrap",
                  }}
                >
                  <Badge type={"success"} label="Active" />
                  <Badge type={"danger"} label="Registration Closed" />
                </div>
                <br />
                <h5>{countStatus(courseData, "Completed")} - Completed</h5>
                <h5 style={{ marginTop: 5 }}>
                  {countStatus(courseData, "OnGoing")} - OnGoing
                </h5>
                <h5 style={{ marginTop: 5 }}>
                  {countStatus(courseData, "Dropped")} - Dropped
                </h5>
              </div>
            </>
          }
        />
      </div>
    </div>
  ) : (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <center>
        <CircularProgress style={{ height: 70, width: 70 }} />
      </center>
    </div>
  );
}

export default CourseRegistered;
