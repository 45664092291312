import {
  MenuRounded,
  NotificationsOutlined,
  SearchRounded,
  SettingsOutlined,
  SettingsRounded,
} from "@mui/icons-material";
import Logo from "../assets/img/logo.png";
import UserLogo from "../assets/img/user.png"
import "./style.css";

function TopBar(props) {
  return (
    <div
      className="app-topbar"
      style={{
        backgroundColor: "white",
        display: "flex",
        padding: "10px 25px",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 20,
      }}
    >
      <div
      style={{display:'flex',gap:20,width:'100%'}}
      >
        <div
              className="topbar-title-block"
        >
          <div
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
            }}
          >
            <img width={30} src={Logo} alt="logo" />
            <h3 className="topbar-title" >PS Portal</h3>
          </div>
          <div onClick={props.sidebar} className="menu-icon sidebar-menu">
            <MenuRounded />
          </div>
        </div>
        <div className="search-input">
          <div style={{ display: "flex", gap: 10 }}>
            <SearchRounded fontSize="sm" color="grey" />
            <input placeholder="Search" />
          </div>
          {/* <div className="menu-icon">
            <SettingsRounded />
          </div> */}
        </div>
      </div>
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 20,
        }}
      >
        <div className="menu-icon">
          <NotificationsOutlined />
        </div>
        <div className="topbar-account">
          <img width={30} src={UserLogo} alt="user" />
          <SettingsOutlined />
        </div>
      </div> */}
    </div>  
  );
}

export default TopBar;
