import "./style.css";
import AppLayout from "../../layout";
import CustomButton from "../../compounds/button";
import { useEffect, useState } from "react";
import postRequest from "../../function/axios";
import { useNavigate } from "react-router-dom";
import SlotCard from "../../compounds/cards/launch";
import CustomDialog from "../../compounds/dialog";
import { Backdrop, Fade, Modal, Typography } from "@mui/material";
import CustomSelect from "../../compounds/select";
import InputBox from "../../compounds/input";

function CourseSlots() {
  return <AppLayout rId={5} body={<Body />} />;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  borderRadius: 2,
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  p: 2,
};

function Body() {

  const navigator = useNavigate()
  const [launch, setLaunch] = useState([]);
  const [openSlotDialog, setOpenSlotDialog] = useState(false);
  const handleOpen = () => setOpenSlotDialog(true);
  const handleClose = () => setOpenSlotDialog(false);
  const [selectedVertical, setSelectedVertical] = useState(0)
  const [vertical, setVertical] = useState([])
  const [course, setCourse] = useState([
    { name: "Expirement 1", id: 1 },
    { name: "Expirement 2", id: 2 },
  ])

  const [selectedVenue, setSelectedVenue] = useState(0)
  const [venue, setVenue] = useState([
    { name: "Digital Lib", id: 1 },
    { name: "CSE LAB 1", id: 2 },
    { name: "CSE LAB 2", id: 3 },
  ])
  const [selectedCourse, setSelectedCourse] = useState(0)

  const fetchSlot = async () => {
    var res = await postRequest("/slots/get", {}, navigator)
    if (res.data.data !== null && res.data.data !== undefined) {
      setLaunch(res.data.data)
    }
  }

  useEffect(() => {
    fetchSlot()
    fetchVerticals()
  }, [])

  const fetchVerticals = async () => {
    var res = await postRequest("/course/allVertical", {}, navigator)
    if (res.data.data !== null && res.data.data !== undefined) {
      setVertical(res.data.data)
    }
  }

  return (
    <div style={{ padding: 20 }}>
      <h3 style={{ fontWeight: 500, fontSize: 23 }}>Course Slot</h3>
      <div style={{ width: "110px", marginTop: 15 }}>
        <CustomButton label={"+ New Slot"} func={handleOpen} />
      </div>

      <div className="launch-list">
        {
          launch.map((item, i) => (
            <SlotCard key={i} slot={item} onClick={"/slots/"} />
          ))
        }
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSlotDialog}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openSlotDialog}>
          <div className="new-slot-dialog">
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {"New Slot"}
            </Typography>
            <CustomSelect
              onChange={setSelectedVertical}
              label="Course Name" options={vertical.map((item) => ({ label: item.name, value: item.id }))} />
            {
              selectedVertical !== 0
                ? <CustomSelect
                  onChange={setSelectedCourse} label="Course Level" options={course.map((item) => ({ label: item.name, value: item.id }))} />
                : null}
            <div className="input-field-row">
              <InputBox label="Slot Date" type="date" />
              {/* <CustomSelect widthFull={true} label="Course Level"/> */}
            </div>
            <div className="input-field-row">
              <InputBox label="Start Time" type="time" />
              <InputBox label="End Time" type="time" />
              {/* <CustomSelect widthFull={true} label="Course Level"/> */}
            </div>
            <CustomSelect
              onChange={setSelectedVenue}
              label="Slot Venue" options={venue.map((item) => ({ label: item.name, value: item.id }))} />
            <CustomButton label="Create Slot" margin={20} />

          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default CourseSlots;
