import { PersonRounded } from "@mui/icons-material";
import BasicCard from "../../compounds/cards";
import AppLayout from "../../layout";
import "./style.css";
import { useState } from "react";
import Chart from "react-apexcharts";
import FullWidthCard from "../../compounds/cards/fullWidth";
import CourseCard from "../../compounds/cards/course";
import postRequest from "../../function/axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../compounds/select";

function Dashboard() {
  const dashboard = localStorage.getItem("dashboard");
  return (
    <AppLayout
      body={dashboard === "admin" ? <AdminDashboard /> : <UserDashboard />}
      rId={1}
    />
  );
}

function AdminDashboard() {
  const navigator = useNavigate();
  const [verticals, setVerticals] = useState(null);
  const [selectedYear,setSelectedYear] = useState(0)
  const [selectedSkill,setSelectedSkill]=useState(0)
  const [dashboardCount, setDashboardCount] = useState({
    total_user: 0,
    active_user: 0,
    in_active_user: 0,
  });
  const [yearData, setYearData] = useState({
    series: [
      {
        name: "Count",
        data: [],
      },
    ],
    options: {
      colors: ["#0095FF", "#0095FF"],
      grid: {
        show: false,
      },
      title: {
        text: "Year Wise",
        align: "left",
        floating: true,
        style: {
          fontSize: "16px",
          fontWeight: 500,
          fontFamily: "Roboto",
          letterSpacing: 0.3,
          color: "#263238",
        },
      },
      chart: {
        type: "bar",
        height: 30,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: [],
      },
    },
    // responsive: [
    //   {
    //     breakpoint: 1000,
    //     options: {
    //       plotOptions: {
    //         bar: {
    //           horizontal: false
    //         }
    //       },
    //       legend: {
    //         position: "bottom"
    //       }
    //     }
    //   }
    // ]
  });
  const updateData = (newData) => {
    setYearData((prevData) => ({
      ...prevData,
      series: [
        {
          ...prevData.series[0],
          data: newData,
        },
      ],
    }));
  };

  // Function to update x-axis categories
  const updateXAxisCategories = (newCategories) => {
    setYearData((prevData) => ({
      ...prevData,
      options: {
        ...prevData.options,
        xaxis: {
          ...prevData.options.xaxis,
          categories: newCategories,
        },
      },
    }));
  };

  const [skillChart, setSkillChart] = useState({
    series: [],
    options: {
      colors: ["#0095FF", "#00E096", "#FA5A7D"],
      chart: {
        type: "bar",
        height: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " Users";
          },
        },
      },
    },
  });

  const updateSeriesData = (newData) => {
    setSkillChart((prevChart) => ({
      ...prevChart,
      series: newData,
    }));
  };

  const updateSkillCategories = (newCategories) => {
    setSkillChart((prevChart) => ({
      ...prevChart,
      options: {
        ...prevChart.options,
        xaxis: {
          ...prevChart.options.xaxis,
          categories: newCategories,
        },
      },
    }));
  };

  const fetchDashboardCount = async () => {
    const res = await postRequest("/dashboard/count", {}, navigator);
    if (res.success && res.data.success && res.data.data) {
      setDashboardCount(res.data.data);
      updateData(res.data.data.year_wise.value);
      updateXAxisCategories(res.data.data.year_wise.label);
    }
  };

  const fetchVertical = async () => {
    const res = await postRequest("/course/allVertical", {}, navigator);
    setVerticals(res.data.data);
    fetchSkillData(res.data.data[0].id,0);
  };

  const fetchSkillData = async (id,year) => {
    const res = await postRequest(
      "/dashboard/skillWise",
      { skill: parseInt(id),year:parseInt(year) },
      navigator
    );
    if (res.success && res.data.data) {
      updateSeriesData(res.data.data.value);
      updateSkillCategories(res.data.data.label);
    }
  };

  useEffect(() => {
    fetchDashboardCount();
    fetchVertical();
  }, []);

  const handleSkillChange = (id) => {
    setSelectedSkill(id)
    fetchSkillData(id,selectedYear);
  };

  const handleYearChange = (year)=> {
    setSelectedYear(year)
    fetchSkillData(selectedSkill,year)
  }

  return (
    <div className="dashboard-body">
      <h3 style={{ fontWeight: 500, fontSize: 23, marginLeft: 5 }}>
        Welcome {localStorage.getItem("name")},
      </h3>

      <div className="dashboard-row">
        <BasicCard
          padding="20px"
          color="white"
          body={
            <>
              <h3>Users</h3>
              <h4
                style={{
                  marginTop: 7,
                  color: "#737791",
                  letterSpacing: 0.2,
                  fontWeight: 400,
                  fontSize: 14,
                }}
              >
                User Status
              </h4>
              <div className="user-card">
                <BasicCard
                  padding="20px"
                  color="success"
                  body={
                    <>
                      <div
                        style={{
                          backgroundColor: "#3CD856",
                          width: "max-content",
                          borderRadius: 50,
                          padding: "2px 4px",
                          color: "white",
                        }}
                      >
                        <PersonRounded />
                      </div>
                      <h1
                        style={{ fontSize: 20, fontWeight: 600, marginTop: 20 }}
                      >
                        {dashboardCount.total_user}
                      </h1>
                      <h4
                        style={{
                          fontSize: 13,
                          fontWeight: 400,
                          marginTop: 5,
                          color: "#425166",
                        }}
                      >
                        Total Users
                      </h4>
                    </>
                  }
                />
                <BasicCard
                  padding="20px"
                  color="success"
                  body={
                    <>
                      <div
                        style={{
                          backgroundColor: "#3CD856",
                          width: "max-content",
                          borderRadius: 50,
                          padding: "2px 4px",
                          color: "white",
                        }}
                      >
                        <PersonRounded />
                      </div>
                      <h1
                        style={{ fontSize: 20, fontWeight: 600, marginTop: 20 }}
                      >
                        {dashboardCount.active_user}
                      </h1>
                      <h4
                        style={{
                          fontSize: 13,
                          fontWeight: 400,
                          marginTop: 5,
                          color: "#425166",
                        }}
                      >
                        Active Users
                      </h4>
                    </>
                  }
                />
                <BasicCard
                  padding="20px"
                  color="danger"
                  body={
                    <>
                      <div
                        style={{
                          backgroundColor: "#FA5A7D",
                          width: "max-content",
                          borderRadius: 50,
                          padding: "2px 4px",
                          color: "white",
                        }}
                      >
                        <PersonRounded />
                      </div>
                      <h1
                        style={{ fontSize: 20, fontWeight: 600, marginTop: 20 }}
                      >
                        {dashboardCount.in_active_user}
                      </h1>
                      <h4
                        style={{
                          fontSize: 13,
                          fontWeight: 400,
                          marginTop: 5,
                          color: "#425166",
                        }}
                      >
                        No Performs Users
                      </h4>
                    </>
                  }
                />
              </div>
            </>
          }
        />
        <BasicCard
          padding="20px 20px 0px 20px"
          color="white"
          body={
            <Chart
              options={yearData.options}
              series={yearData.series}
              type="bar"
              height={230}
            />
          }
        />
      </div>
      <FullWidthCard
        margin={20}
        padding={"20px 20px 0px 20px"}
        color="white"
        body={
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 20,
                marginBottom: 15,
              }}
            >
              <h3>Course</h3>
              <CustomSelect
                options={
                  verticals === null
                    ? []
                    : verticals.map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))
                }
                onChange={handleSkillChange}
                margin={1}
                // widthFull={true}
                placeholder={"Select a Course"}
              />
              <h3>Year</h3>
              <CustomSelect
                margin={1}
                widthFull={true}
                options={[
                  { label: "All Year", value: 0 },
                  { label: "I Year", value: 1 },
                  { label: "II Year", value: 2 },
                  { label: "III Year", value: 3 },
                  { label: "IV Year", value: 4 },
                ]}
                onChange={handleYearChange}
                placeholder={"Select a Year"}
              />
            </div>
            <Chart
              options={skillChart.options}
              series={skillChart.series}
              type="bar"
              width={"100%"}
              height={450}
            />
          </>
        }
      />
    </div>
  );
}

function UserDashboard() {
  const [myLearning, setLearning] = useState([]);
  const [course, setCourse] = useState([]);

  const fetchMyLearning = async () => {
    const res = await postRequest("/myLearning/all", {}, navigator);
    if (res.success && res.data.data) {
      setLearning(res.data.data);
    }
  };

  const fetchCourse = async () => {
    const res = await postRequest("/course/allVertical", {}, navigator);
    if (res.success && res.data.data) {
      setCourse(res.data.data);
    }
  };

  useEffect(() => {
    fetchMyLearning();
    fetchCourse();
  }, []);
  return (
    <div className="dashboard-body">
      <h3 style={{ fontWeight: 500, fontSize: 23, marginLeft: 5 }}>
        Welcome {localStorage.getItem("name")},
      </h3>

      {myLearning.length > 0 ? (
        <h3 style={{ marginTop: 30, fontWeight: 500 }}>On Going Course</h3>
      ) : null}
      <div className="course-card-row">
        {myLearning.map((item, i) => (
          <CourseCard
            path={"/myLearning/details/"}
            key={i}
            id={item.id}
            title={item.name}
            value={null}
            img={item.img}
          />
        ))}
      </div>
      {myLearning.length > 0 && course.length > 0 ? (
        <h3 style={{ marginTop: 40, fontWeight: 500 }}>All Course</h3>
      ) : null}
      <div className="course-card-row">
        {course.map((item, i) => (
          <CourseCard
            path={"/course/"}
            key={i}
            id={item.id}
            title={item.name}
            value={item.completed}
            img={item.img}
          />
        ))}
      </div>
    </div>
  );
}
export default Dashboard;
