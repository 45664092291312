import '../style.css'

function SqlTables(props) {
    function decodeBase64(base64String) {
        try {
            return atob(base64String);
        } catch (error) {
            return base64String;
        }
    }

    return <table className="sql-table">
        <thead>
            {
                props.title
                    ? <tr><th colSpan={Object.keys(props.data[0]).length} >{props.title}</th></tr>
                    : null
            }
            <tr>
                {
                    props.data.length > 0
                        ?
                        Object.keys(props.data[0]).map(function (key) {
                            return <th key={key}>{key}</th>;
                        })
                        : null
                }
            </tr>
        </thead>
        <tbody>

            {
                props.data.map(function (item, index) {
                    var keys = Object.keys(item);
                    return (
                        <tr key={index}>
                            {keys.map(function (key) {
                                return <td key={key}>{
                                    props.decode ?
                                        decodeBase64(item[key])
                                        : item[key]
                                }</td>;
                            })}
                        </tr>
                    );
                })
            }
        </tbody>
    </table>
}

export default SqlTables