import { useEffect, useState } from "react";
import CustomTable from "../../compounds/table";
import AppLayout from "../../layout";
import "./style.css";
import primaryColor from "../../theme/colors";
import axios from "axios";
import {api} from "../../utils/api";
import { useNavigate } from "react-router-dom";
import ErrorText from "../../compounds/error";
import CustomButton from "../../compounds/button";
import {
  Backdrop,
  Box,
  CircularProgress,
  Fade,
  Modal,
  Typography,
} from "@mui/material";

function RegisterCourse() {
  return <AppLayout rId={3} body={<Body />} />;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: 2,
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  p: 2,
};

function Body() {
  const navigate = useNavigate();

  const [courseData, setCourseData] = useState([]);
  const tableHeader = ["Vertical", "Course Name"];
  const tableFields = ["vertical", "name"];
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedCourse, setSelectedCourse] = useState({});
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    fetchCourse();
  }, []);

  const selectCourse = (item) => {
    setSelectedCourse(item);
    handleOpen();
  };

  const fetchCourse = () => {
    try {
      setCourseData([]);
      axios({
        url: api + "/register/available",
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("auth"),
        },
        data: {
          user_id: localStorage.getItem("id"),
        },
      })
        .then((res) => {
          if (res.data.success && res.data.data !== null) {
            setCourseData(res.data.data);
          }
        })
        .catch((err) => {
          if (err.response !== undefined && err.response.status === 401) {
            navigate("/auth/login");
          }
        });
    } catch (error) {
      // console.log(error);
    }
  };

  const handleRegister = () => {
    try {
      axios({
        url: api + "/register",
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("auth"),
        },
        data: {
          user_id: localStorage.getItem("id"),
          skill_id: parseInt(selectedCourse.id),
        },
      })
        .then((res) => {
          if (res.data.success) {
            fetchCourse();
          }
        })
        .catch((err) => {
          if (err.response !== undefined && err.response.status === 401) {
            navigate("/auth/login");
          }
        });
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
    handleClose();
    fetchCourse();
  };

  return (
    <div style={{ padding: 20 }}>
      <CustomTable
        title={"Register Course"}
        description={
          <p>
            Here you can find the Available Course for{" "}
            <span style={{ color: primaryColor, fontWeight: 600 }}>
              {localStorage.getItem("id") +
                " - " +
                localStorage.getItem("name")}
            </span>
            .
          </p>
        }
        data={courseData}
        header={tableHeader}
        field={tableFields}
        actionType={"button"}
        buttonLabel={"View"}
        buttonAction={selectCourse}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h4">
              {selectedCourse.name}
            </Typography>
            <div style={{ marginTop: 10 }}>
              <p>{selectedCourse.description}</p>
            </div>
            {isLoading ? (
              <center style={{ marginTop: 20 }}>
                <CircularProgress />
              </center>
            ) : (
              <CustomButton
                margin={20}
                label={"Register"}
                func={() => {
                  setLoading(true);
                  handleRegister();
                }}
              />
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default RegisterCourse;
