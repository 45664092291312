import "./style.css";

function FullWidthCard(props) {
  const color = props.color;
  return (
    <div
      style={{
        marginTop:props.margin,
        padding:props.padding,
        boxShadow: color === "white" ? "2px 1px 20px #d6d6d680" : "",
        backgroundColor:
          color === "white"
            ? "white"
            : color === "success"
            ? "#DCFCE7"
            : color === "warning"
            ? "#FFF4DE"
            : color === "danger"
            ? "#FFE2E5"
            : "",
      }}
      className={"fullwidth-card"}
    >
      {props.body}
    </div>
  );
}

export default FullWidthCard;
