import { useNavigate } from "react-router-dom";
import AppLayout from "../../layout";
import CustomButton from "../../compounds/button";
import TrainerCard from "../../compounds/cards/trainer";
import { useEffect, useState } from "react";
import CustomDialog from "../../compounds/dialog";
import InputBox from "../../compounds/input";
import CustomSelect from "../../compounds/select";
import postRequest from "../../function/axios";

function Trainers() {
  return <AppLayout rId={10} body={<Body />} />;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "80%",
  borderRadius: 2,
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  p: 2,
};

function Body() {
  const navigator = useNavigate();
  const [openNewRequest, setOpenNewRequest] = useState(false);
  const [course, setCourse] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(0);
  const [selectedTrainerYear, setSelectedTrainerYear] = useState(0);
  const [selectedTraineeYear, setSelectedTraineeYear] = useState(0);
  const [maxUser, setMaxUser] = useState("");
  const [registrationCloseDate, setRegistrationCloseDate] = useState("");
  const [registrationCloseTime, setRegistrationCloseTime] = useState("");

  const [trainerRequest, setTrainerRequest] = useState([]);

  const fetchCourse = async () => {
    const res = await postRequest("/course/allCourse", { id: 0 }, navigator);
    if (res.success && res.data !== null) {
      setCourse(res.data.data);
    }
  };

  const fetchYears = async () => {
    const res = await postRequest("/master/years/get", {}, navigator);
    if (res.success && res.data !== null) {
      setYears(res.data.data);
    }
  };

  const fetchTrainers = async () => {
    const res = await postRequest("/trainers", {}, navigator);
    if (res.success && res.data !== null) {
      setTrainerRequest(res.data.data)
    }
  };

  const newRequest = async () => {
    const res = await postRequest(
      "/trainers/request",
      {
        course_id: selectedCourse,
        trainer_year: selectedTrainerYear,
        user_year: selectedTraineeYear,
        max_user: parseInt(maxUser),
        registration_close_date: registrationCloseDate,
        registration_close_time: registrationCloseTime,
      },
      navigator
    );
    if (res.success && res.data.success) {
      setOpenNewRequest(false);
    }
    fetchCourse()
  };

  useEffect(() => {
    fetchTrainers();
  }, []);

  return (
    <div style={{ padding: 20 }}>
      <h3 style={{ fontWeight: 500, fontSize: 23 }}>Course Trainers</h3>
      <div style={{ width: "160px", marginTop: 15 }}>
        <CustomButton
          label={"Request Trainers"}
          func={() => {
            setOpenNewRequest(true);
            fetchCourse();
            fetchYears();
          }}
        />
      </div>

      <div className="launch-list">
        {trainerRequest.map((item, i) => (
          <TrainerCard key={i} training={item} />
        ))}
      </div>

      <CustomDialog
        style={style}
        open={openNewRequest}
        handleClose={() => {
          setOpenNewRequest(false);
        }}
        title={"New Request"}
        body={
          <div style={{ width: "100%" }}>
            <CustomSelect
              onChange={setSelectedCourse}
              options={course.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              label={"Course Name"}
              placeholder={"Select a Course"}
            />
            <div style={{ width: "100%", display: "flex" }}>
              <InputBox
                placeholder={"Maximum Trainnes per Trainer"}
                label={"Maximum Trainees"}
                margin={20}
                flex={3}
                type={"number"}
                onChange={(e) => {
                  setMaxUser(e.target.value);
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                gap: 20,
              }}
            >
              <CustomSelect
                onChange={setSelectedTrainerYear}
                options={years.map((item) => ({
                  label: item.year,
                  value: item.id,
                }))}
                label={"Trainer Year"}
                placeholder={"Select a Year"}
              />
              <CustomSelect
                onChange={setSelectedTraineeYear}
                options={years.map((item) => ({
                  label: item.year,
                  value: item.id,
                }))}
                label={"Trainee Year"}
                placeholder={"Select a Year"}
              />
            </div>
            <div
              style={{
                display: "flex",
                gap: 20,
              }}
            >
              <InputBox
                label={"Registration Close Date"}
                margin={20}
                flex={3}
                type={"date"}
                onChange={(e) => {
                  setRegistrationCloseDate(e.target.value);
                }}
              />
              <InputBox
                label={"Registration Close Time"}
                margin={20}
                flex={3}
                type={"time"}
                onChange={(e) => {
                  setRegistrationCloseTime(e.target.value);
                }}
              />
            </div>
            <CustomButton
              margin={30}
              label={"Request Trainers"}
              func={newRequest}
            />
          </div>
        }
      />
    </div>
  );
}

export default Trainers;
