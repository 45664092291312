import {
  Avatar,
  Backdrop,
  CircularProgress,
  Fade,
  Modal,
  Typography,
} from "@mui/material";
import Badge from "../../compounds/badge";
import BasicCard from "../../compounds/cards";
import AppLayout from "../../layout";
import CustomButton from "../../compounds/button";
import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import { Editor } from "@monaco-editor/react";
import { useEffect, useState } from "react";
import postRequest, { postComplierRequest } from "../../function/axios";
import ErrorText from "../../compounds/error";
import CryptoJS from "crypto-js";
import { useParams } from "react-router-dom";
import { api } from "../../utils/api";

function AssessmentResultDetails() {
  return <AppLayout rId={5} body={<Body />} />;
}

function Body() {
  const [resultDetail, setResultDetail] = useState(0);
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [errorTxt, setErrorTxt] = useState("");
  var { registerId } = useParams();
  registerId = CryptoJS.AES.decrypt(atob(registerId), "!@#$%^&*()").toString(
    CryptoJS.enc.Utf8
  );
  const [openSubmitted, setOpenSubmitted] = useState(false);
  const handleOpenSubmitted = () => {
    setOpenSubmitted(true);
  };
  const handleCloseSubmitted = () => setOpenSubmitted(false);

  const fetchResultDetail = async () => {
    var res = await postRequest("/slots/result", { id: parseInt(registerId) });
    console.log(res.data)
    if (res.success === true && res.data.data !== undefined) {
      setResultDetail(res.data.data);
    } else {
      if (!res.success) {
        setErrorTxt(res.error);
      } else {
        setErrorTxt(res.data.error);
      }
    }
  };

  const runTestCases = async () => {
    try {
      for (
        let i = 0;
        i < resultDetail.assessment_question[selectedQuestion].options.length;
        i++
      ) {
        const testCase =
          resultDetail.assessment_question[selectedQuestion].options[i];

        console.log(testCase);
        const { input, output: expectedOutput } = testCase;
        const res = await postComplierRequest(
          "/compiler/compile",
          {
            compiler_on: "practice",
            input: input.replace(/<br\/>/g, "\n"),
            language:
              resultDetail.assessment_question[selectedQuestion].response,
            code: resultDetail.assessment_question[selectedQuestion]
              .response_content,
            user: localStorage.getItem("id"),
          },
          navigator
        );

        if (
          res.success &&
          res.data &&
          res.data.data !== undefined &&
          res.data.success
        ) {
          const { error, output: compilerOutput } = res.data.data;
          var formattedOutput = error + compilerOutput.replace(/\n/g, "<br/>");

          formattedOutput = formattedOutput.replace(/<br\/>$/, "");
          const status = formattedOutput === expectedOutput ? "1" : "0";

          console.log(compilerOutput);

          setResultDetail((prevState) => {
            // Create a copy of prevState
            const updatedDetail = { ...prevState };
            // Update the status of the selected question
            updatedDetail.assessment_question[selectedQuestion].options[
              i
            ].status = status;
            return updatedDetail;
          });
        }
      }
    } catch (error) {
      console.error("Error compiling:", error);
    }
  };

  useEffect(() => {
    fetchResultDetail();
  }, []);

  return resultDetail !== 0 ? (
    <div className="project-details" style={{ padding: 20 }}>
      <div style={{ flex: 3 }} className="projects">
        <BasicCard
          padding={20}
          color="white"
          body={
            <>
              <h2 style={{ fontSize: 20, fontWeight: 500 }}>
                Assessment Details
              </h2>
              <div
                style={{
                  display: "flex",
                  gap: 20,
                  marginTop: 30,
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3>{resultDetail.course_name}</h3>
                <h3>Slot: {resultDetail.slot_date_time}</h3>
                <h3>Submitted IP: {resultDetail.submitted_ip}</h3>
                <h3>Total Score: {resultDetail.score}</h3>
              </div>
            </>
          }
        />

        <BasicCard
          padding={20}
          color="white"
          margin={20}
          body={
            <>
              <h2 style={{ fontSize: 20, fontWeight: 500 }}>User Details</h2>
              <div
                style={{
                  display: "flex",
                  gap: 20,
                  marginTop: 30,
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <AvatarGroup user={resultDetail.name} />
                <h3>{resultDetail.year} Year</h3>
                <h3>{resultDetail.email}</h3>
                <h3>{resultDetail.dept_name}</h3>
              </div>
            </>
          }
        />

        {resultDetail.assessment_question !== null &&
          resultDetail.assessment_question.length > 0 ? (
          <BasicCard
            padding={20}
            margin={20}
            color="white"
            body={
              <>
                <h2 style={{ fontSize: 20, fontWeight: 500 }}>
                  Assessment Questions
                </h2>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                >
                  <div>
                    <h4
                      style={{
                        color: "grey",
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      Question Title
                    </h4>
                    <h3 style={{ marginTop: 10 }}>
                      {resultDetail.assessment_question[selectedQuestion].title}
                    </h3>
                  </div>

                  <div
                    style={{ display: "flex", alignItems: "center", gap: 10 }}
                  >
                    <ArrowBackIosNewRounded
                      fontSize="sm"
                      onClick={() => {
                        if (selectedQuestion > 0) {
                          setSelectedQuestion(selectedQuestion - 1);
                        }
                      }}
                    />
                    {selectedQuestion +
                      1 +
                      "/" +
                      resultDetail.assessment_question.length}
                    <ArrowForwardIosRounded
                      fontSize="sm"
                      onClick={() => {
                        if (
                          selectedQuestion <
                          resultDetail.assessment_question.length - 1
                        ) {
                          setSelectedQuestion(selectedQuestion + 1);
                        }
                      }}
                    />
                  </div>
                </div>

                <br />
                <h4 style={{ color: "grey", fontSize: 15, fontWeight: 500 }}>
                  Question Description
                </h4>
                <div
                  style={{ marginTop: 10 }}
                  dangerouslySetInnerHTML={{
                    __html:
                      resultDetail.assessment_question[selectedQuestion]
                        .description,
                  }}
                />
                <br />
                <h4 style={{ color: "grey", fontSize: 15, fontWeight: 500 }}>
                  Answer (
                  {resultDetail.assessment_question[selectedQuestion].response})
                </h4>

                {
                  resultDetail.assessment_question[selectedQuestion].type === "compiler" ?

                    <div>
                      <div
                        style={{
                          backgroundColor: "#1e1e1e",
                          padding: 10,
                          borderRadius: 10,
                          marginTop: 10,
                        }}
                      >
                        <Editor
                          options={{
                            fontSize: 17,
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            tabSize: 8,
                            letterSpacing: 1.3,
                            readOnly: true,
                          }}
                          value={
                            resultDetail.assessment_question[selectedQuestion]
                              .response_content
                          }
                          height={400}
                          width={"100%"}
                          theme={"vs-dark"}
                          language={
                            resultDetail.assessment_question[selectedQuestion]
                              .response
                          }
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: 20,
                        }}
                      >
                        <div style={{ width: 150 }}>
                          <CustomButton func={runTestCases} label="Run" />
                        </div>
                      </div>

                      <table style={{ marginTop: 20 }} className="testcase-table">
                        <thead>
                          <tr style={{ backgroundColor: "rgb(238, 242, 246)" }}>
                            <th style={{ padding: 8, textAlign: "start" }}>
                              TestCase
                            </th>
                            <th style={{ padding: 8, textAlign: "start" }}>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {resultDetail.assessment_question[
                            selectedQuestion
                          ].options.map((item, i) => (
                            <tr>
                              <td style={{ padding: 10 }}>TestCase #{i + 1}</td>
                              <td style={{ padding: 10 }}>
                                {item.status === "1" ? (
                                  <p
                                    style={{
                                      backgroundColor: "#01a001",
                                      color: "white",
                                      padding: "4px 15px",
                                      borderRadius: 50,
                                      width: "max-content",
                                      fontSize: 14,
                                    }}
                                  >
                                    Passed
                                  </p>
                                ) : item.status === "0" ? (
                                  <p
                                    style={{
                                      backgroundColor: "red",
                                      color: "white",
                                      padding: "4px 15px",
                                      borderRadius: 50,
                                      width: "max-content",
                                      fontSize: 14,
                                    }}
                                  >
                                    Not Passed
                                  </p>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    : resultDetail.assessment_question[selectedQuestion].type === "file"

                      ? <iframe
                        style={{
                          border: "none",
                          height: "100%",
                          width: "100%",
                          flex: 3,
                          borderRadius: 10,
                          minHeight: 500,
                        }}
                        src={
                          api +
                          "/evaluation/getFile/" +
                          resultDetail.assessment_question[selectedQuestion].task_id +
                          "/" +
                          resultDetail.assessment_question[selectedQuestion].response +
                          "#toolbar=0"
                        }
                      />

                      : null}
              </>
            }
          />
        ) : null}
      </div>

      <BasicCard
        minWidth={350}
        color="white"
        body={
          <div
            className="project-detail-card"
            style={{
              padding: 20,
              minWidth: 350,
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <h3 style={{ fontSize: 19 }}>Assessment Tags</h3>
            <div className="project-detail-tags">
              <div style={{ cursor: "pointer" }} onClick={handleOpenSubmitted}>
                <Badge type="success" label={"2 Question Submitted"} />
              </div>
              <Badge type="pending" label={"24 Mins Taken"} />
              <Badge
                type="pending"
                label={
                  (resultDetail.tab_switch === null
                    ? 0
                    : resultDetail.tab_switch) + " Tab Swtich"
                }
              />
              <Badge type="pending" label={"0 Second Login Request"} />
            </div>

            <h3 style={{ marginTop: 30 }}>Course Timeline</h3>
            <ul style={{ padding: "10px 20px" }}>
              <li className={"listItem"}>
                <div>
                  <h4>{resultDetail.created_at}</h4>
                  <h2>Course Registered</h2>
                </div>
              </li>

              <li className={"listItem"}>
                <div>
                  <h4>{resultDetail.updated_at}</h4>
                  <h2>Slot Booked</h2>
                </div>
              </li>

              <li
                className={
                  resultDetail.attendance !== "1"
                    ? "listItem danger"
                    : "listItem"
                }
              >
                <div>
                  <h4>{resultDetail.attendance_on}</h4>
                  <h2>
                    Assessment
                    {resultDetail.attendance === "1" ? " Present" : " Absent"}
                  </h2>
                </div>
              </li>

              {resultDetail.assessment_question !== null &&
                resultDetail.assessment_question.length > 0 ? (
                <li className={"listItem"}>
                  <div>
                    <h4>{resultDetail.assessment_question[0].assigned_on}</h4>
                    <h2>Assessment Started</h2>
                  </div>
                </li>
              ) : null}

              {resultDetail.submitted_on !== null &&
                resultDetail.submitted_on !== "" ? (
                <li className={"listItem"}>
                  <div>
                    <h4>{resultDetail.submitted_on}</h4>
                    <h2>Assessment Completed</h2>
                  </div>
                </li>
              ) : null}
            </ul>

            {resultDetail.attendance !== "1" &&
              resultDetail.submitted_on === null ? (
              <div>
                <h3 style={{ marginTop: 20 }}>Slot Action</h3>
                <CustomButton label="Remove Slot" margin={20} danger="danger" />
              </div>
            ) : null}
          </div>
        }
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubmitted}
        onClose={handleCloseSubmitted}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openSubmitted}>
          <div className="question-submitted-log">
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Question Log
            </Typography>

            <table style={{ marginTop: 20 }} className="testcase-table">
              <thead>
                <tr style={{ backgroundColor: "rgb(238, 242, 246)" }}>
                  <th style={{ padding: 8, textAlign: "start" }}>S.No</th>
                  <th style={{ padding: 8, textAlign: "start" }}>
                    Submitted On
                  </th>
                  <th style={{ padding: 8, textAlign: "start" }}>
                    Submitted IP
                  </th>
                </tr>
              </thead>
              <tbody>
                {resultDetail.assessment_question !== null &&
                  resultDetail.assessment_question.map((item, i) => (
                    <tr key={i}>
                      <td style={{ padding: 10 }}>{i + 1}</td>
                      <td style={{ padding: 10 }}>{item.submitted_on}</td>
                      <td style={{ padding: 10 }}>{item.submitted_ip}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Fade>
      </Modal>
    </div>
  ) : (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {errorTxt === "" ? (
        <CircularProgress style={{ height: 70, width: 70 }} />
      ) : (
        <ErrorText label={errorTxt} />
      )}
    </div>
  );
}

function AvatarGroup(props) {
  const user = props.user;
  const stringAvatar = (name) => {
    if (name.includes(" ")) {
      return {
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
      };
    } else {
      return {
        children: name[0],
      };
    }
  };
  return (
    <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
      <Avatar
        style={{
          color: "white",
          backgroundColor: "black",
          width: 45,
          height: 45,
        }}
        {...stringAvatar(user)}
      />
      <div>
        <h3 style={{ fontSize: 16 }}>{user}</h3>
      </div>
    </div>
  );
}

export default AssessmentResultDetails;
