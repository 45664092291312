import {
  FormatAlignJustifyRounded,
  LinkRounded,
  YouTube,
} from "@mui/icons-material";
import "./style.css";

function MaterialList(props) {
  const material = props.material;
  return (
    <div
      className="material-list"
      onClick={() => {
        props.func(props.index);
      }}
    >
      {material.type === "text" ? (
        <FormatAlignJustifyRounded fontSize="sm" />
      ) : material.type === "youtube" ? (
        <YouTube fontSize="sm" />
      ) : material.type === "link" ? (
        <LinkRounded fontSize="sm" />
      ) : null}
      <h5 style={{ fontSize: 14, fontWeight: 500, letterSpacing: 0.2 }}>
        {material.title}
      </h5>
    </div>
  );
}

export default MaterialList;
