import { useNavigate, useParams } from "react-router-dom";
import CustomTable from "../../../compounds/table";
import AppLayout from "../../../layout";
import { useState } from "react";
import CryptoJS from "crypto-js";
import primaryColor from "../../../theme/colors";
import postRequest from "../../../function/axios";
import { useEffect } from "react";
import CustomDialog from "../../../compounds/dialog";
import UserImg from "../../../assets/img/user.png";
import CustomButton from "../../../compounds/button";

function AttendancePage() {
  return <AppLayout rId={5} body={<Body />} />;
}

function Body() {
  const navigator = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [slotDetails, setSlotDetails] = useState(null);
  var { slot } = useParams();
  slot = CryptoJS.AES.decrypt(atob(slot), "!@#$%^&*()").toString(
    CryptoJS.enc.Utf8
  );

  const [courseData, setCourseData] = useState([]);

  const tableHeader = ["User Id", "Name", "Attendance"];

  const tableFields = ["user_id", "name", "status"];

  const fetchSlotDetails = async () => {
    const res = await postRequest(
      "/slots/details",
      { id: parseInt(slot) },
      navigator
    );
    if (res.data.data !== null && res.data.data !== undefined) {
      setSlotDetails(res.data.data);
    }
  };

  const fetchSlotUser = async () => {
    const res = await postRequest(
      "/attendance/all",
      { slot: parseInt(slot) },
      navigator
    );


    if (res.data.data !== null && res.data.data !== undefined) {
      setCourseData(res.data.data);
    }
  };

  useEffect(() => {
    fetchSlotDetails();
    fetchSlotUser();
  }, []);

  const handleAttendance = async () => {
    await postRequest(
      "/attendance/mark",
      {
        register_id: parseInt(selectedUser.register_id),
        status: selectedUser.status == "Present" ? "0" : "1",
      },
      navigator
    );
    fetchSlotUser()
  };

  return (
    <div style={{ padding: 30 }}>
      {slotDetails ? (
        <CustomTable
          // button={() => {}}
          // buttonLabel="Download"
          title={slotDetails.name !== null ? slotDetails.name:"" + " Attendance"}
          description={
            <>
              Here you can find the Attendance  
            </>
          }
          actionType={"button"}
          data={courseData}
          header={tableHeader}
          field={tableFields}
          buttonLabel={"View"}
          buttonAction={(item) => {
            setSelectedUser(item);
            setOpen(true);
          }}
        />
      ) : null}

      {selectedUser !== null ? (
        <CustomDialog
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
          title={"Attendance"}
          body={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 20,
              }}
            >
              <img
                style={{
                  borderRadius: 500,
                  backgroundColor: "rgb(238, 242, 246)",
                  width: "50%",
                }}
                src={UserImg}
              />
              <br />
              <br />
              <h4>User Id: {selectedUser.user_id}</h4>
              <h4 style={{ marginTop: 10 }}>Name: {selectedUser.name}</h4>
              <div>
                <CustomButton
                  label={
                    selectedUser.status == "Present" ? "Absent" : "Present"
                  }
                  margin={30}
                  func={() => {
                    handleAttendance()
                    setOpen(false);
                  }}
                />
              </div>
            </div>
          }
        />
      ) : null}
    </div>
  );
}

export default AttendancePage;
