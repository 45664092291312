import {  useNavigate } from "react-router-dom";
import Logo from "../../assets/img/logo.png";
import GoogleLogo from "../../assets/img/googe.png";
import primaryColor from "../../theme/colors";
import "../style.css";
import axios from "axios";
import {api} from "../../utils/api";
import { useState } from "react";
import ToastMsg from "../../compounds/toast";
import postRequest from "../../function/axios";
import { useGoogleLogin } from "@react-oauth/google";
import InputBox from "../../compounds/input";
import CustomButton from "../../compounds/button";

function LoginAuth() {
  const navigate = useNavigate();
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  const openToast = (message, type) => {
    setToastMessage(message);
    setToastType(type);
    setToastShow(true);
    setTimeout(() => {
      closeToast();
    }, 5000);
  };

  const closeToast = () => {
    setToastShow(false);
  };

  const handleLogin = (event) => {

    axios({
      url: api + "/auth/login",
      method: "POST",
      data: {
        username: event.target.elements.userEmail.value,
        password: event.target.elements.userPassword.value,
      },
    })
      .then(async (res) => {
        if (res.data.success) {
          localStorage.setItem("auth", res.data.auth_token);
          localStorage.setItem("name", res.data.user.name);
          localStorage.setItem("id", res.data.user.id);
          localStorage.setItem("isLogin", "true");
          handleRoles();
        } else {
          openToast(res.data.error, "error");
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    event.preventDefault();
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      fetchLoginData(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const handleRoles = async () => {
    const res = await postRequest("/roles/getRole", {}, navigate);
    if (res.success && res.data.data) {
      localStorage.setItem("dashboard", res.data.data.dashboard);
      localStorage.setItem("addCourse", res.data.data.add_course);
      navigate("/");
    }
  };

  const fetchLoginData = (userCode) => {
    axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${userCode.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${userCode.access_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        axios({
          url: api + "/auth/Glogin",
          method: "POST",
          data: {
            username: res.data.email,
          },
        })
          .then((response) => {
            if (response.data.success && response.data.data !== null) {
              localStorage.setItem("auth", response.data.auth_token);
              localStorage.setItem("name", response.data.user.name);
              localStorage.setItem("id", response.data.user.id);
              localStorage.setItem("isLogin", "true");
              handleRoles();
            } else {
              openToast(response.data.error, "error");
            }
          })
          .catch((err) => {
            // console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(238, 242, 246)",
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
      }}
    >
      <div
        className="login-form"
        style={{
          borderRadius: 10,
          boxShadow: "1px solid rgba(144, 202, 249, 0.145)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "max-content",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            alignItems: "center",
          }}
        >
          <img width={30} src={Logo} alt="logo" />
          <h3 style={{ fontSize: 20, fontWeight: 500 }}>PS Portal</h3>
        </div>

        <h2
          style={{
            color: primaryColor,
            fontWeight: 500,
            fontSize: 23,
            marginTop: 50,
          }}
        >
          Hi, Welcome Back
        </h2>

        
        {/* <div style={{ fontSize: 13, marginTop: 30 }}>OR</div>  */}
        {/* <div
          onClick={handleGoogleLogin}
          style={{
            display: "flex",
            gap: 20,
            backgroundColor: "rgb(248, 250, 252)",
            width: "100%",
            justifyContent: "center",
            border: "1px solid rgb(238, 242, 246)",
            padding: 7,
            alignItems: "center",
            marginTop: 60,
            borderRadius: 5,
            cursor: "pointer",
          }}
        >
          <img src={GoogleLogo} alt="google" width={20} />
          <h3 style={{ fontSize: 14, fontWeight: 400 }}>Sign In with Google</h3>
        </div>
        <h3
          style={{
            color: "rgb(105, 117, 134)",
            fontSize: 14,
            letterSpacing: 0.5,
            fontWeight: 400,
            marginTop: 20,
          }}
        >
          Sign In with your BITSathy Account
        </h3>
        */}

       <form
          onSubmit={(e) => handleLogin(e)}
          style={{
            width: "100%",
          }}
        >
            <InputBox
              name="userEmail"
              type="username"
              label={"Username"}
              placeholder="Your Username"
              margin={15}
            />
            <InputBox
              name="userPassword"
              type="password"
              label={"Password"}
              placeholder="Your Password"
              margin={15}
            />

          <div
            style={{
              width: "100%",
              marginTop: 15,
              display: "flex",
              justifyContent: "end",
            }}
          >
            {/* <Link
              style={{
                textDecoration: "none",
                fontSize: 14,
                color: primaryColor,
              }}
              to={"/auth/resetPassword"}
            >
              Forgot Password?
            </Link> */}
          </div>
          <CustomButton label={"Sign In"} margin={20} />
        </form>  
      </div>

      {toastShow ? (
        <ToastMsg message={toastMessage} type={toastType} close={closeToast} />
      ) : null}
    </div>
  );
}

export default LoginAuth;
