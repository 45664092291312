import { useEffect, useState } from "react";

function AssessmentTopBar(props) {
    const [hours, minutes, seconds] = props.endTime.split(':').map(Number);

    // Set the due time by creating a Date object
    const dueTime = new Date();
    dueTime.setHours(hours);
    dueTime.setMinutes(minutes);
    dueTime.setSeconds(seconds);
  
    const calculateTimeRemaining = (endTime) => {
      const now = new Date();
      const difference = endTime - now;
      const seconds = Math.max(0, Math.floor(difference / 1000));
  
      return seconds;
    };
  
    const [remainingTime, setRemainingTime] = useState(calculateTimeRemaining(dueTime));
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setRemainingTime((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            props.handleFinishTest();
            return 0;
          }
        });
      }, 1000);
    
      return () => clearTimeout(timer);
    }, [remainingTime]);
    
  
    const formatTimeUnit = (value) => (value < 10 ? `0${value}` : value);
  
    const formattedTime = {
      hours: Math.floor(remainingTime / 3600),
      minutes: Math.floor((remainingTime % 3600) / 60),
      seconds: remainingTime % 60,
    };
  
  return (
    <div
      style={{
        backgroundColor: "white",
        // width:"100%",
        display: "flex",
        padding: 15,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <h3>{props.title}</h3>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 20,
        }}
      >
        <h4 style={{ fontWeight: 500 }}>
          Ends On : {formatTimeUnit(formattedTime.hours)}:{formatTimeUnit(formattedTime.minutes)}:{formatTimeUnit(formattedTime.seconds)}
        </h4>
        <button
          onClick={props.handleOpen}
          style={{
            backgroundColor: "red",
            padding: "7px 15px",
            outline: "none",
            border: "none",
            color: "white",
            fontSize: 16,
            borderRadius: 8,
            cursor: "pointer",
          }}
        >
          Finish Now
        </button>
      </div>
    </div>
  );
}

export default AssessmentTopBar;
