import { Editor } from "@monaco-editor/react";
import AppLayout from "../../layout";
import "./style.css";
import primaryColor from "../../theme/colors";
import Select from "react-select";
import { useState } from "react";
import { postComplierRequest } from "../../function/axios";

function PraticeComplier() {
    return <AppLayout rId={15} body={<Body />} />;
}

function Body() {
    const [language, setLanguage] = useState("");
    const [code, setCode] = useState("");
    const [output, setOutput] = useState("");
    const [input, setInput] = useState("");
    const [sqlOutput, setSqlOutput] = useState([]);

    const compile = async () => {
        const res = await postComplierRequest(
            "/compiler/compile",
            {
                compiler_on: "practice",
                input: input,
                language: language,
                code: code,
                user: localStorage.getItem("id"),
            },
            navigator
        );
        console.log(res)
        setSqlOutput([]);
        if (res.success === true) {
            if (res.data.success === true) {
                setOutput(
                    (res.data.data.error + res.data.data.output).replace(/ /g, "&nbsp;")
                );

                if (language === "sql" && res.data.data.sql_output !== null) {
                    setSqlOutput(res.data.data.sql_output);
                }
            } else {
                setOutput(res.data.error);
            }
        } else {
            setOutput(res.error);
        }
    };
    return (
        <div className="complier-screeen">
            <div
                style={{
                    flex: 3,
                    borderRadius: 10,
                    padding: "10px 0px",
                    backgroundColor: "#1e1e1e",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0 15px",
                        marginBottom: 10,
                    }}
                >
                    <Select
                        placeholder="Selct a Language"
                        options={[
                            { label: "C", value: "c" },
                            { label: "C++", value: "cpp" },
                            { label: "Python", value: "py" },
                            { label: "Java", value: "java" },
                            { label: "SQL", value: "sql" },
                        ]}
                        onChange={(e) => {
                            // props.setLanguage(e.value);
                            setLanguage(e.value);
                        }}
                    />

                    <button
                        style={{
                            padding: "8px 20px",
                            backgroundColor: primaryColor,
                            color: "white",
                            outline: "none",
                            border: "none",
                            fontSize: 16,
                            borderRadius: 8,
                            height: "max-content",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            setSqlOutput([]);
                            setOutput("Compiling...");
                            compile();
                            // props.setCode("")
                        }}
                    >
                        Run
                    </button>
                </div>
                <Editor
                    options={{
                        fontSize: 17,
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        tabSize: 8,
                        letterSpacing: 1.3,
                    }}
                    height={language === "sql" ? 200 : 500}
                    width={"100%"}
                    theme={"vs-dark"}
                    language={language === "py" ? "python" : language}
                    onChange={(value) => {
                        setCode(value);
                    }}
                />
            </div>

            <div className="complier-input-output">
                <textarea
                    onChange={(e) => {
                        setInput(e.target.value);
                    }}
                    placeholder="Custom Input"
                />
                <div className="compiler-output">
                    <h5>Output</h5>
                    <h3
                        style={{ fontSize: 18, fontWeight: 400, marginTop: 10 }}
                        dangerouslySetInnerHTML={{
                            __html: output.replace(/\n/g, "<br/>"),
                        }}
                    />
                    <table className="sql-output">
                        {/* <tr>
                            <th>id</th>
                            <th>user</th>
                            <th>skill_id</th>
                            <th>slot_id</th>
                            <th>created_at</th>
                        </tr> */}
                        {sqlOutput.map((item, i) => (
                            <tr>
                                <td>{item.id}</td>
                                <td>{item.user}</td>
                                <td>{item.skill_id}</td>
                                <td>{item.slot_id}</td>
                                <td>{item.created_at}</td>
                            </tr>
                        ))}
                    </table>
                </div>
            </div>
        </div>
    );
}

export default PraticeComplier;
