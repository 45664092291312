import CustomButton from "../../../../compounds/button";
import CustomTable from "../../../../compounds/table";

function TestCaseTable(props) {
  const tableHeader = ["Input", "Output", "Score"];

  const tableFields = ["input", "output", "score"];

  return (
    <div style={{ marginTop: 40 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3>Test Cases</h3>
        {props.showAdd ? (
          <div style={{ width: "max-content" }}>
            <CustomButton
              label="Add"
              padding={"8px 15px"}
              func={props.handleOpen}
            />
          </div>
        ) : null}
      </div>
      <CustomTable
        returnType="index"
        data={props.data}
        header={tableHeader}
        field={tableFields}
        disableHeader={true}
        buttonAction={props.buttonAction}
      />
    </div>
  );
}

export default TestCaseTable;
