function Badge(props) {
  const type = props.type;
  return (
    <div
      style={{
        marginTop:props.margin,
        padding: "3px 12px",
        borderRadius: 5,
        width: "max-content",
        backgroundColor:
          type === "success"
            ? "rgb(181 248 163)"
            : type === "danger"
            ? "#ff00007d"
            : type === "pending"
            ? "rgb(229 216 252)"
            : "",
      }}
    >
      <h6
        style={{
          fontSize: 14,
          fontWeight: 400,
          color:
            type === "success"
              ? "green"
              : type === "danger"
              ? "#d51616"
              : type === "pending"
              ? "purple"
              : "",
        }}
      >
        {props.label}
      </h6>
    </div>
  );
}

export default Badge;
