import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../compounds/button";
import BasicCard from "../../compounds/cards";
import AppLayout from "../../layout";
import { api } from "../../utils/api";
import "./style.css";
import CryptoJS from "crypto-js";
import postRequest, { postComplierRequest } from "../../function/axios";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Editor } from "@monaco-editor/react";
import './style.css'

function EvaluationDetails() {
  var { evaluationId } = useParams();
  const [justification, setJustification] = useState("");
  const [rubixTotal, setRubixTotal] = useState(0);
  evaluationId = CryptoJS.AES.decrypt(
    atob(evaluationId),
    "!@#$%^&*()"
  ).toString(CryptoJS.enc.Utf8);
  const [responseDetails, setResponseDetail] = useState(0);
  const navigate = useNavigate()
  const [code, setCode] = useState("")

  const fetchResponse = async () => {
    var res = await postRequest(
      "/evaluation/getDetails",
      { id: parseInt(evaluationId) },
      navigator
    );
    if (
      res.success === true &&
      res.data.success === true &&
      res.data.data !== undefined
    ) {
      setResponseDetail(res.data.data);
      setCode(res.data.data.response_content)
    }
  };

  useEffect(() => {
    fetchResponse();
  }, []);

  const handleMarkChange = (index, value) => {
    if (
      parseInt(value === "" ? 0 : value) <=
      responseDetails.rubix[index].max_score
    ) {
      setResponseDetail((responseDetails) => {
        const updatedResponse = { ...responseDetails };
        updatedResponse.rubix[index].mark = parseInt(value);
        return updatedResponse;
      });
    }
  };

  useEffect(() => {
    if (responseDetails !== 0) {
      handleUpdateScore();
    }
  }, [responseDetails]);

  const handleUpdateScore = () => {
    var tempSum = 0;
    for (let i = 0; i < responseDetails.rubix.length; i++) {
      const element = responseDetails.rubix[i];
      if (parseInt(element.mark) > 0) {
        tempSum += parseInt(element.mark);
      }
    }
    setRubixTotal(tempSum);
  };


  const handleSubmit = async () => {
    var res = await postRequest("/evaluation/addLog", {
      id: parseInt(evaluationId),
      rubix: responseDetails.rubix,
      justification: justification,
      total_score: rubixTotal,
    })
    if (res.success === true && res.data.success === true) {
      navigate("/evaluation")
    }
  }

  const runTestCases = async () => {
    try {
      for (
        let i = 0;
        i < responseDetails.test_case.length;
        i++
      ) {
        const testCase =
          responseDetails.test_case[i];

        console.log(testCase)

        const { input, Output: expectedOutput } = testCase;
        const res = await postComplierRequest(
          "/compiler/compile",
          {
            compiler_on: "practice",
            input: input.replace(/<br\/>/g, "\n"),
            language:
              responseDetails.response,
            code: code,
            user: localStorage.getItem("id"),
          },
          navigator
        );


        if (
          res.success &&
          res.data &&
          res.data.data !== undefined &&
          res.data.success
        ) {
          const { error, output: compilerOutput } = res.data.data;
          var formattedOutput = error + compilerOutput.replace(/\n/g, "<br/>");

          console.log(input, expectedOutput)

          formattedOutput = formattedOutput.replace(/<br\/>$/, "");
          const status = formattedOutput === expectedOutput ? "1" : "0";


          setResponseDetail((prevState) => {
            const updatedDetail = { ...prevState };
            responseDetails.test_case[
              i
            ].status = status;
            return updatedDetail;
          });
        }
      }
    } catch (error) {
      console.error("Error compiling:", error);
    }
  };

  return (
    <AppLayout
      rId={16}
      body={
        responseDetails !== 0 ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              padding: 20,
              boxSizing: "border-box",
            }}
          >
            <BasicCard
              padding={20}
              color="white"
              body={
                <>
                  <h4 style={{ color: "grey", fontSize: 15, fontWeight: 500 }}>
                    Question Title
                  </h4>
                  <h3 style={{ marginTop: 10 }}>{responseDetails.title}</h3>

                  <h4
                    style={{
                      color: "grey",
                      fontSize: 15,
                      fontWeight: 500,
                      marginTop: 20,
                    }}
                  >
                    Question Description
                  </h4>
                  <div
                    style={{ marginTop: 10 }}
                    dangerouslySetInnerHTML={{
                      __html: responseDetails.description,
                    }}
                  />
                </>
              }
            />
            <div className="evaluation-details">
              {
                responseDetails.type === "compiler"
                  ? <div style={{ flex: 3 }}>
                    <div
                      style={{
                        backgroundColor: "#1e1e1e",
                        padding: 10,
                        borderRadius: 10,
                        marginTop: 10,
                      }}
                    >
                      <Editor
                        options={{
                          fontSize: 17,
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          tabSize: 8,
                          letterSpacing: 1.3,
                          // readOnly: true,
                        }}
                        value={
                          code
                        }
                        height={400}
                        width={"100%"}
                        theme={"vs-dark"}
                        language={
                          responseDetails.response
                        }
                        onChange={(value) => {
                          setCode(value)
                          console.log(value)
                        }}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: 20,
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <CustomButton
                          func={runTestCases}
                          label="Run" />
                      </div>
                    </div>

                    <table style={{ marginTop: 20 }} className="testcase-table">
                      <thead>
                        <tr style={{ backgroundColor: "rgb(238, 242, 246)" }}>
                          <th style={{ padding: 8, textAlign: "start" }}>
                            TestCase
                          </th>
                          <th style={{ padding: 8, textAlign: "start" }}>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {responseDetails.test_case.map((item, i) => (
                          <tr>
                            <td style={{ padding: 10 }}>TestCase #{i + 1}</td>
                            <td style={{ padding: 10 }}>
                              {item.status === "1" ? (
                                <p
                                  style={{
                                    backgroundColor: "#01a001",
                                    color: "white",
                                    padding: "4px 15px",
                                    borderRadius: 50,
                                    width: "max-content",
                                    fontSize: 14,
                                  }}
                                >
                                  Passed
                                </p>
                              ) : item.status === "0" ? (
                                <p
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    padding: "4px 15px",
                                    borderRadius: 50,
                                    width: "max-content",
                                    fontSize: 14,
                                  }}
                                >
                                  Not Passed
                                </p>
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>F
                  </div>
                  :

                  <iframe
                    style={{
                      border: "none",
                      height: "100%",
                      flex: 3,
                      borderRadius: 10,
                      minHeight: 500,
                    }}
                    src={
                      api +
                      "/evaluation/getFile/" +
                      responseDetails.task_id +
                      "/" +
                      responseDetails.response +
                      "#toolbar=0"
                    }
                  />

              }
              <div className="evaluation-rubix">
                <BasicCard
                  padding={20}
                  color="white"
                  body={
                    <>
                      <table className="evaluation-table">
                        <thead>
                          <tr>
                            <th>Rubix</th>
                            <th>Mark</th>
                          </tr>
                        </thead>
                        <tbody>
                          {responseDetails.rubix !== null
                            ? responseDetails.rubix.map((item, i) => (
                              <tr>
                                <td>{item.label}</td>
                                <td style={{ minWidth: 140 }}>
                                  <input
                                    max={item.max_score}
                                    min={0}
                                    type="number"
                                    value={
                                      responseDetails.rubix[i].mark ===
                                        undefined
                                        ? ""
                                        : responseDetails.rubix[i].mark
                                    }
                                    onChange={(e) => {
                                      handleMarkChange(i, e.target.value);
                                    }}
                                  />
                                  &nbsp;&nbsp;/ {item.max_score}
                                </td>
                              </tr>
                            ))
                            : null}
                        </tbody>
                      </table>

                      <br />
                      <label>Justification for this mark</label>
                      <br />
                      <textarea
                        onChange={(e) => {
                          setJustification(e.target.value);
                        }}
                      />
                      <h3 style={{ marginTop: 10 }}>Total : {rubixTotal}</h3>
                      <CustomButton margin={20} label="Submit" func={handleSubmit} />
                    </>
                  }
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={50} />
          </div>
        )
      }
    />
  );
}

export default EvaluationDetails;
