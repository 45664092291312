function TextMaterial(props) {
  const material = props.material;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 30,
      }}
    >
      <h3 style={{ fontWeight: 500 }}>{material.title}</h3>
      <div
        style={{ marginTop: 20 }}
        dangerouslySetInnerHTML={{ __html: material.content }}
      />
    </div>
  );
}

export default TextMaterial;
