import { Avatar, CircularProgress } from "@mui/material";
import Badge from "../../compounds/badge";
import BasicCard from "../../compounds/cards";
import AppLayout from "../../layout";
import "./style.css";
import CustomButton from "../../compounds/button";
import { useEffect, useState } from "react";
import postRequest, { githubRequest } from "../../function/axios";
import { useNavigate, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
import ErrorText from "../../compounds/error";

function ProjectDetails() {
  return <AppLayout rId={8} body={<Body />} />;
}

function Body() {
  const navigate = useNavigate();
  var { projectId } = useParams();
  projectId = CryptoJS.AES.decrypt(atob(projectId), "!@#$%^&*()").toString(
    CryptoJS.enc.Utf8
  );
  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const [projectDetails, setProjectDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errText, setErrText] = useState("");
  const [commitHistory, setCommitHistory] = useState([]);

  const fetchProjectDetails = async () => {
    var res = await postRequest(
      "/projects/details",
      { id: parseInt(projectId) },
      navigate
    );
    console.log(res)
    if (res.success && res.data.success === true) {
      setProjectDetails(res.data.data);
      if (res.data.data.status === "1") {
        fetchGithubCommits();
      }
      setIsLoading(false);
    }
  };

  const handleUpdateState = async (status) => {
    var res = await postRequest("/projects/updateState", {
      project_id: parseInt(projectId),
      status: status,
    });
    if (res.data.success !== true) {
      setErrText(res.data.error);
    }
    fetchProjectDetails();
  };

  const handleAddColo = async (id, email) => {

    var res = await postRequest("/projects/github/addCol", {
      email: email,
      repo: projectId,
      id: id
    });
    console.log(res)
    if (res.data.success !== true) {
      setErrText(res.data.error);
    }
    fetchProjectDetails();
  };

  useEffect(() => {
    fetchProjectDetails();
  }, []);

  const fetchGithubCommits = async () => {
    var res = await postRequest(
      "/projects/github/commits",
      { id: projectId },
      navigate
    );
    if (res.data.success === true && res.data.data !== null) {
      setCommitHistory(res.data.data);
    }
  };

  return !isLoading ? (
    <div className="project-details" style={{ padding: 20 }}>
      <div style={{ flex: 3 }} className="projects">
        <h2 style={{ fontWeight: 400 }}>
          {capitalize(projectDetails.title.toLowerCase())}
        </h2>
        {projectDetails.status === "2" ? (
          <Badge margin={10} label="Pending Approval" type="pending" />
        ) : null}

        {projectDetails.status === "1" ? (
          <Badge margin={10} label="Project Approved" type="success" />
        ) : null}

        <BasicCard
          margin={20}
          padding={20}
          color="white"
          body={
            <>
              <h2 style={{ fontSize: 18, fontWeight: 500 }}>Description</h2>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: 17,
                  lineHeight: 1.5,
                  color: "#515151",
                  marginTop: 10,
                }}
              >
                {projectDetails.description}
              </p>
            </>
          }
        />

        {projectDetails.status === "1" ? (
          <BasicCard
            margin={20}
            padding={20}
            color="white"
            body={
              <>
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <h2 style={{ fontSize: 18, fontWeight: 500 }}>
                    Github Repo Details
                  </h2>
                  <h3
                    style={{
                      color: "gray",
                      fontWeight: 400,
                      cursor: "pointer",
                    }}
                  >
                    {commitHistory.length} Commits
                  </h3>
                </div>
                <a
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  href={"https://github.bitsathy.ac.in/ps/PS_TAC_" + projectDetails.id}>
                  <p
                    style={{
                      textAlign: "justify",
                      fontSize: 17,
                      lineHeight: 1.5,
                      color: "#515151",
                      marginTop: 10,
                    }}
                  >
                    Rep Name : PS_TAC_{projectDetails.id} (
                    {projectDetails.members.length} Collaborates)
                  </p>
                </a>

                <br />
                <br />
                <h4>Collaborates Not Added</h4>
                <div
                  style={{
                    display: "flex",
                    gap: 20,
                    marginTop: 20,
                    flexWrap: "wrap",
                    justifyContent: "start",
                  }}
                >
                  {projectDetails.members.map((item, i) =>
                    item.status === "2" ? (
                      <div
                        onClick={() => {
                          handleAddColo(item.id, item.email.split("@")[0])
                        }}
                        style={{
                          cursor: "pointer",
                          padding: "10px 30px",
                          borderRadius: 10,
                          alignItems: "center",
                          backgroundColor: "rgb(248 163 163 / 36%)",
                        }}
                      >
                        <h3>{item.name}</h3>
                        <p
                          style={{
                            fontSize: 12,
                            color: "#3c3b3b",
                            marginTop: 3,
                          }}
                        >
                          Click to Add
                        </p>
                      </div>
                    ) : null
                  )}
                </div>
              </>
            }
          />
        ) : null}

        <BasicCard
          margin={20}
          padding={20}
          color="white"
          body={
            <>
              <h2 style={{ fontSize: 18, fontWeight: 500 }}>Team Details</h2>
              <div
                style={{
                  display: "flex",
                  gap: 40,
                  marginTop: 20,
                  flexWrap: "wrap",
                  justifyContent: "start",
                }}
              >
                {projectDetails.members.map((item, i) => (
                  <AvatarGroup user={item} key={i} />
                ))}
              </div>
            </>
          }
        />
      </div>
      <BasicCard
        minWidth={350}
        color="white"
        body={
          <div
            className="project-detail-card"
            style={{
              padding: 20,
              minWidth: 350,
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            {/* <img
              width={"100%"}
              alt="course-img"
              src={"https://ps.bitsathy.ac.in/images/course/1.png"}
            /> */}

            <h3 style={{ marginTop: 0 }}>Tags</h3>
            <div className="project-detail-tags">
              <Badge type="pending" label={"P" + projectDetails.id} />
              {projectDetails.status === "2" ? (
                <Badge type="pending" label="Pending Approval" />
              ) : null}
              <Badge type="pending" label={projectDetails.type} />
              <Badge type="pending" label={projectDetails.domain} />
              <Badge
                type="pending"
                label={projectDetails.members.length + " Members"}
              />
              {projectDetails.members.map((item, i) => (
                <Badge type="pending" label={item.level} key={i} />
              ))}
            </div>

            <h3 style={{ marginTop: 30 }}>Project Timeline</h3>
            <ul style={{ padding: "10px 20px" }}>
              <li className={"listItem"}>
                <div>
                  <h4>{projectDetails.created_at}</h4>
                  <h2>Project Created</h2>
                </div>
              </li>
            </ul>

            {projectDetails.approve_project === "1" && projectDetails.status === "2" ? (
              <div>
                <h3 style={{ marginTop: 20 }}>Project Action</h3>
                <div style={{ display: "flex", gap: 10, marginTop: 10 }}>
                  <CustomButton
                    label="Approve"
                    func={() => {
                      handleUpdateState("1");
                    }}
                  />
                  <CustomButton label="Decline" danger="danger" />
                </div>
              </div>
            ) : null}

            <ErrorText label={errText} />
          </div>
        }
      />
    </div>
  ) : (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress size={50} />
    </div>
  );
}

function AvatarGroup(props) {
  const user = props.user;
  const stringAvatar = (name) => {
    if (name.includes(" ")) {
      return {
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
      };
    } else {
      return {
        children: name[0],
      };
    }
  };

  return (
    <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
      <Avatar
        style={{
          color: "white",
          backgroundColor: "black",
          width: 45,
          height: 45,
        }}
        {...stringAvatar(user.name)}
      />
      <div>
        <h3 style={{ fontSize: 16 }}>{user.name}</h3>
        <h4
          style={{
            color: "grey",
            fontSize: 14.5,
            fontWeight: 500,
            marginTop: 2,
          }}
        >
          {user.level}
        </h4>
      </div>
    </div>
  );
}

export default ProjectDetails;
