import { useNavigate } from "react-router-dom";
import "./style.css";
import CryptoJS from "crypto-js";

function SlotCard(props) {
  const slot = props.slot;
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate(
          props.onClick +
            btoa(
              CryptoJS.AES.encrypt(slot.id.toString(), "!@#$%^&*()").toString()
            )
        );
      }}
      className="course-card"
    >
      <div className="course-card-content">
        <h4 style={{ fontWeight: 500, fontSize: 14, color: "#6a6a6a" }}>
          {slot.label}
        </h4>
        <h3 style={{ fontWeight: 500, marginTop: 5 }}>
          {slot.skill_id === 0 ? "All Course" : slot.course}
        </h3>
        <p style={{ marginTop: 15, color: "#6a6a6a", fontSize: 14 }}>
          {slot.venue}
        </p>
        <h4 style={{ fontWeight: 500, fontSize: 14, marginTop: 3 }}>
          {props.isEvaluation?"Assigned By":"Slot By"} {slot.slot_by}
        </h4>
      </div>
    </div>
  );
}

export default SlotCard;
