import { DashboardOutlined, FilePresentRounded, Logout, YouTube } from "@mui/icons-material";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { api } from "../utils/api";
import axios from "axios";

function AppSideBar(props) {
  const navigate = useNavigate();
  const menuIcons = [
    <DashboardOutlined />,
    <YouTube />,
    <Logout />,
    <FilePresentRounded />,
  ];
  const [menu, setMenu] = useState(
    localStorage.getItem("menu-resource") !== null &&
      localStorage.getItem("menu-resource") !== "undefined"
      ? JSON.parse(localStorage.getItem("menu-resource"))
      : null
  );

  useEffect(() => {
    fetchResource();
  }, []);

  const fetchResource = () => {
    try {
      axios({
        url: api + "/roles/getResource",
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("auth"),
        },
      })
        .then((res) => {
          if (res.data.success && res.data.data !== null) {
            setMenu(res.data.data);
            localStorage.setItem(
              "menu-resource",
              JSON.stringify(res.data.data)
            );
          }
        })
        .catch((err) => {
          if (err.response !== undefined && err.response.status === 401) {
            navigate("/auth/login");
          }
        });
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <div
      className={props.open ? "app-sidebar sidebar-open" : "app-sidebar"}
      style={{
        backgroundColor: "white"
      }}
    >
      {menu !== null &&
        menu.map((item, i) => (
          <div
            key={i}
            onClick={() => {
              navigate(item.path);
            }}
          >
            {item.group !== null && item.group !== "" ? (
              <h4 style={{ fontWeight: 500, fontSize: 13, marginTop: 20 }}>
                {item.group}
              </h4>
            ) : null}
            <div
              className={
                props.resource === item.id
                  ? "menu-item menu-active"
                  : "menu-item"
              }
            >
              {menuIcons[item.icon]}
              <h3 style={{ fontWeight: 400, fontSize: 14 }}>{item.name}</h3>
            </div>
            {item.separator ? (
              <hr style={{ marginTop: 15, border: "0.5px solid #dddddd" }} />
            ) : null}
          </div>
        ))}
    </div>
  );
}

export default AppSideBar;
