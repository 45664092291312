import axios from "axios";
import { api, complierApi } from "../utils/api";

const postRequest = async (url, data, navigate) => {
  try {
    const response = await axios.post(
      api + url,
      // url == "/compiler/compile" ? compilerApi : api + url,
      data,
      {
        headers: {
          Authorization: localStorage.getItem("auth"),
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    if (error.response && error.response.status === 401) {
      navigate("/auth/login");
    }

    return { success: false, error: error.message };
  }
};


export const postComplierRequest = async (url, data, navigate) => {
  try {
    const response = await axios.post(
      complierApi + url,
      data,
      {
        headers: {
          Authorization: localStorage.getItem("auth"),
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    if (error.response && error.response.status === 401) {
      navigate("/auth/login");
    }

    return { success: false, error: error.message };
  }
};


export default postRequest;
