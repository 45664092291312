import Badge from "../../compounds/badge";
import AppLayout from "../../layout";
import CustomButton from "../../compounds/button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import postRequest from "../../function/axios";
import {api} from "../../utils/api";
import CryptoJS from "crypto-js";

function ProjectsPage() {
  return <AppLayout rId={8} body={<Body />} />;
}

function Body() {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const handleNewProject = () => {
    navigate("new");
  };

  const fetchProjects = async () => {
    var res = await postRequest("/projects/getAll", {}, navigate);
    if (res.success && res.data.success === true) {
      setProjects(res.data.data);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <div style={{ padding: 20 }}>
      <h3 style={{ fontWeight: 500, fontSize: 23 }}>My Projects</h3>
      <div style={{ width: "max-content", marginTop: 20 }}>
        <CustomButton label="+ Add New Project" func={handleNewProject} />
      </div>
      <div style={{ marginTop: 20 }} className="course-card-row">
        {projects.map((item, i) => (
          <div
            onClick={() => {
              navigate(
                "details/" +
                  btoa(
                    CryptoJS.AES.encrypt(
                      item.id.toString(),
                      "!@#$%^&*()"
                    ).toString()
                  )
              );
            }}
            className="course-card"
            style={{
              backgroundColor: "white",
            }}
          >
            <div
              style={{ boxSizing: "border-box", height: "100%" }}
              className="course-card-content"
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <img
                    style={{
                      width: 50,
                      height: 50,
                      borderRadius: 10,
                    }}
                    src={api + "/viewImage/courseLogo/" + item.logo}
                  />
                  <h3 style={{ fontWeight: 500, marginTop: 10 }}>
                    {item.title.slice(0, 25) + "..."}
                  </h3>
                  <p
                    style={{
                      fontSize: 15,
                      color: "#707070",
                      marginTop: 5,
                      textAlign: "justify",
                      lineHeight: 1.5,
                    }}
                  >
                    {item.description.slice(0, 100) + "..."}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 15,
                  }}
                >
                  <div />
                  {
                  item.status === "1" ? (
                    <Badge type="success" label="Approved" />
                  ) :
                  item.status === "2" ? (
                    <Badge type="pending" label="Pending Approval" />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProjectsPage;
