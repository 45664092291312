import { useState } from "react";
import CustomButton from "../../../compounds/button";
import BasicCard from "../../../compounds/cards";
import postRequest from "../../../function/axios";
import primaryColor from "../../../theme/colors";
import { CircularProgress } from "@mui/material";

function Options(props) {

  return (
    <div
      onClick={() => {
        props.setLoading(true)
        props.onChange(props.option.id)
      }}
      style={{
        border: props.selected ? "1px solid rgb(14 120 220)" : "1px solid #cfcfcf",
        borderRadius: 10,
        marginTop: 10,
        padding: 15,
        display: "flex",
        alignItems: "center",
        gap: 20,
        cursor: "pointer"
      }}
    >
      {/* <input
          // defaultChecked={props.selected}
          type="radio"
          value={props.option.id}
          name={"question" + props.questionId}
          onChange={handleRadioChange}
          style={{
            marginRight: 15,
            width: 14,
            height: 14,
            backgroundColor: "black",
            border: "1px solid black",
          }}
        /> */}
      <div style={{
        width: 15,
        height: 15,
        borderRadius: 50,
        backgroundColor: props.selected ? primaryColor : "",
        border: !props.selected ? "1px solid rgb(161 161 161)" : "",
      }}>

      </div>
      <label style={{ cursor: "pointer" }}>
        {props.option.label}
      </label>
    </div>
  );
}

function MCQ(props) {
  const [loading, setLoading] = useState(false)
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log(event.target.elements.question + props.questionId.value)
  //   props.onSubmit(event.target.elements.question + props.questionId.value);
  // };


  const handleSubmitOption = async (option) => {
    const res = await postRequest("/assessment/answer", {
      task_id: props.questionId,
      type: "mcq",
      response: option.toString(),
    });
    if (res.data.success) {
      props.handleQuestionSubmit(props.selectedIndex, option, "mcq");
    }
    setLoading(false)
  }

  return (
    <BasicCard
      color="white"
      padding={20}
      body={
        <div>
          <h3>Choose any Options</h3>
          {/* <form style={{ marginTop: 30 }} onSubmit={handleSubmit}> */}
          {props.Options &&
            props.Options.map((option, i) => (
              <Options
                option={option}
                key={i}
                selected={option.id === parseInt(props.selected)}
                questionId={props.questionId}
                onChange={handleSubmitOption}
                setLoading={setLoading}
              />
            ))}
          {!loading && props.Options.length !== 0 ? (
            <CustomButton func={() => {
              props.handleNext();
            }} margin={30} label={"Next"} />
          ) : null}

          {
            loading ?
              <center style={{ marginTop: 20 }}><CircularProgress /></center>
              : null
          }
          {/* </form> */}
        </div>
      }
    />
  );
}

export default MCQ;
