import { CloseRounded } from "@mui/icons-material";
import "./style.css";
import { useState } from "react";

function CustomSnackbar(props) {
  const type = props.type;
  const [isOpen, setIsOpen] = useState(true);
  return isOpen ? (
    <div
      style={{
        padding: 10,
        color: "white",
        borderRadius: 5,
        display: "flex",
        justifyContent: "space-between",
        backgroundColor:
          type === "success" ? "green" : type === "danger" ? "red" : "",
      }}
    >
      <h5 style={{ fontWeight: 400 }}>{props.label}</h5>
      <CloseRounded style={{cursor:"pointer"}} onClick={()=>{setIsOpen(false)}} fontSize="sm" />
    </div>
  ) : null;
}

export default CustomSnackbar;
