import primaryColor from "../../../theme/colors";
import Select from "react-select";
import postRequest, { postComplierRequest } from "../../../function/axios";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Editor } from "@monaco-editor/react";
import { CircularProgress } from "@mui/material";

function Compiler(props) {
  const navigator = useNavigate();
  // const [isCompiling, setIsCompiling] = useState(false);
  const [language, setLanguage] = useState("");
  const [output, setOutput] = useState("");
  const [isCustomInput, setIsCustomInput] = useState(false);
  const [customInput, setCustomInput] = useState("");

  const editorRef = useRef(null);

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;

    editor.onKeyDown((e) => {
      if (
        (e.ctrlKey || e.metaKey) &&
        (e.code === "KeyC" || e.code === "KeyV")
      ) {
        e.preventDefault();
        e.stopPropagation();
      }
    });

    editor.updateOptions({ contextmenu: false });

    // props.setCode(props.question[props.selected].response_content);
  };

  const runTestCases = async () => {
    try {
      if (!language) {
        setOutput("Select a Language");
        props.setIsCompiling(false);
        return;
      }

      let scoreUpdates = [];

      for (let i = 0; i < props.testCase.length; i++) {
        const testCase = props.testCase[i];
        const { input, output: expectedOutput, id } = testCase;
        const res = await postComplierRequest(
          "/compiler/compile",
          {
            compiler_on: "assessment",
            task_id: props.taskId.toString(),
            input: input.replace(/<br\/>/g, "\n"),
            language,
            code: props.question[props.selected].response_content,
            user: localStorage.getItem("id"),
          },
          navigator
        );

        if (
          res.success &&
          res.data &&
          res.data.data !== undefined &&
          res.data.success
        ) {
          const { error, output: compilerOutput } = res.data.data;
          var formattedOutput = error + compilerOutput.replace(/\n/g, "<br/>");

          formattedOutput = formattedOutput.replace(/<br\/>$/, "");
          const status = formattedOutput === expectedOutput ? "1" : "0";

          props.setQuestion((prevQuestions) => {
            const updatedQuestions = [...prevQuestions];
            props.question[props.selected].options[i].status = status;
            return updatedQuestions;
          });
          const scoreUpdate = { id, status };
          scoreUpdates.push(scoreUpdate);
        }
      }

      const bulkScoreUpdates = scoreUpdates.map(({ id, status }) => ({
        id,
        status,
        score:
          status === "1" ? props.testCase.find((tc) => tc.id === id).score : 0,
      }));
      const sumOfScores = bulkScoreUpdates.reduce(
        (totalScore, { score }) => totalScore + score,
        0
      );

      await submitTest(sumOfScores);
    } catch (error) {
      console.error("Error compiling:", error);
    }
    props.setIsCompiling(false);
  };

  const runCode = async () => {
    try {
      if (!language) {
        setOutput("Select a Language");
        props.setIsCompiling(false);
        return;
      }

      for (let i = 0; i < props.testCase.length; i++) {
        const testCase = props.testCase[i];

        if (testCase.score === 0) {
          const { input } = testCase;
          const res = await postComplierRequest(
            "/compiler/compile",
            {
              compiler_on: "assessment",
              task_id: props.taskId.toString(),
              input: input.replace(/<br\/>/g, "\n"),
              language,
              code: props.question[props.selected].response_content,
              user: localStorage.getItem("id"),
            },
            navigator
          );

          if (
            res.success &&
            res.data &&
            res.data.data !== undefined &&
            res.data.success
          ) {
            setOutput(
              (res.data.data.error + res.data.data.output).replace(
                / /g,
                "&nbsp;"
              )
            );
          }
          break;
        }
      }
    } catch (error) {
      console.error("Error compiling:", error);
    }
    props.setIsCompiling(false);
  };

  const runCustomTestCase = async () => {
    try {
      if (!language) {
        setOutput("Select a Language");
        props.setIsCompiling(false);
        return;
      }

      const res = await postComplierRequest(
        "/compiler/compile",
        {
          compiler_on: "assessment",
          task_id: props.taskId.toString(),
          input: customInput,
          language,
          code: props.question[props.selected].response_content,
          user: localStorage.getItem("id"),
        },
        navigator
      );

      if (
        res.success &&
        res.data &&
        res.data.data !== undefined &&
        res.data.success
      ) {
        setOutput(
          (res.data.data.error + res.data.data.output).replace(/ /g, "&nbsp;")
        );

      }
    } catch (error) {
      console.error("Error compiling:", error);
    }
    props.setIsCompiling(false);
  };

  const submitTest = async (totalScore) => {
    await postRequest("/assessment/answer", {
      task_id: props.taskId,
      type: "compiler",
      response: language,
      response_content: props.question[props.selected].response_content,
      total_score: totalScore,
    });
    
    props.handleQuestionSubmit(props.selected, language, "mcq");
  };

  // const testCaseLog = async (testCase, status) => {
  //   const res = await postRequest(
  //     "/compiler/addTestCaseLog",
  //     {
  //       test_Case: testCase,
  //       status: status,
  //     },
  //     navigator
  //   );
  //   return res;
  // };

  // useEffect(() => {
  //   console.log(props.question[props.selected].response);
  //   console.log(props.question[props.selected].response)
  //   props.onChange(props.selected, props.question[props.selected].response);
  // }, []);

  return (
    <>
      <div
        style={{
          borderRadius: 10,
          padding: "10px 0px",
          backgroundColor: "#1e1e1e",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 15px",
            marginBottom: 10,
          }}
        >
          <Select
            placeholder="Selct a Language"
            // options={[
            //   { label: "C", value: "c" },
            //   { label: "C++", value: "cpp" },
            //   { label: "Python", value: "py" },
            //   { label: "Java", value: "java" },
            // ]}
            options={props.question[props.selected].complier
              .toString()
              .split("\\\\")
              .map((item) => ({ label: item, value: item }))}
            onChange={(e) => {
              props.setLanguage(e.value);
              setLanguage(e.value);
            }}
          />

          {props.isCompiling ? (
            <CircularProgress size={35} />
          ) : (
            <div style={{ display: "flex", gap: 10 }}>
              <button
                style={{
                  padding: "8px 20px",
                  backgroundColor: primaryColor,
                  color: "white",
                  outline: "none",
                  border: "none",
                  fontSize: 16,
                  borderRadius: 8,
                  height: "max-content",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.setIsCompiling(true);
                  setOutput("Compiling...");
                  isCustomInput ? runCustomTestCase() : runCode();
                  // props.setCode("")
                }}
              >
                Run
              </button>
              <button
                style={{
                  padding: "8px 20px",
                  backgroundColor: primaryColor,
                  color: "white",
                  outline: "none",
                  border: "none",
                  fontSize: 16,
                  borderRadius: 8,
                  height: "max-content",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.setIsCompiling(true);
                  runTestCases();
                  // props.setCode("")
                }}
              >
                Submit
              </button>
            </div>
          )}
        </div>
        <Editor
          options={{
            fontSize: 17,
            fontFamily: "Roboto",
            fontWeight: 500,
            tabSize: 8,
            letterSpacing: 1.3,
          }}
          height={500}
          width={"100%"}
          theme={"vs-dark"}
          language={
            props.selectedLanguage === "py" ? "python" : props.selectedLanguage
          }
          value={props.question[props.selected].response_content}
          onChange={(value) => {
            // props.setCode(value);
            props.onChange(props.selected, value, "compiler");
          }}
          onMount={handleEditorDidMount}
        />
      </div>

      <div style={{ marginTop: 20, padding: 10 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            onChange={(e) => {
              setIsCustomInput(e.target.checked);
            }}
            type="checkbox"
            style={{
              outline: "none",
              width: 18,
              height: 18,
            }}
          />
          <label style={{ marginLeft: 10 }}>Custom Input</label>
        </div>
        <textarea
          onChange={(e) => {
            setCustomInput(e.target.value);
          }}
          style={{
            width: "100%",
            minWidth: "100%",
            maxWidth: "100%",
            padding: 12,
            paddingLeft: 5,
            fontSize: 15,
            border: "0.5px solid grey",
            borderRadius: 5,
            marginTop: 10,
            minHeight: 100,
            maxHeight: 100,
            boxSizing: "border-box",
            resize: "none",
            outline: "none",
          }}
        />
      </div>
      <div
        style={{
          padding: 10,
          marginTop: 10,
          maxWidth: "800px",
          overflow: "auto",
        }}
      >
        <h3>Output</h3>
        <br />
        {output !== "" ? (
          <div
            dangerouslySetInnerHTML={{
              __html: output
                .replace(/\n/g, "<br/>")
                .replace(
                  "/Users/adcmacbook/Desktop/ps_skill/server/codes/8/",
                  ""
                ),
            }}
          ></div>
        ) : null}
      </div>
    </>
  );
}

export default Compiler;
