function YoutubeMaterial(props) {
  return (
    <iframe
      style={{ marginTop: 20, borderRadius: 10 }}
      width="100%"
      height="500"
      src={props.link}
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  );
}

export default YoutubeMaterial;
