import { useState } from "react";
import CustomTable from "../../compounds/table";
import AppLayout from "../../layout"
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";


function LoginLogs() {
    return <AppLayout rId={13} body={<Body />} />
}

function Body() {
    const navigate = useNavigate()
    const [courseData, setCourseData] = useState([]);
    const tableHeader = ["User Id", "Name", "Email", "Count", "Status"];
    const tableFields = ["user_id", "name", "email", "count", "status"];

    return <div
        style={{ padding: 20 }}>
        <CustomTable
            title={"Assessment Login Logs"}
            description={
                "Here you can find the Login Logs on Assessment Timing"
            }
            data={courseData}
            header={tableHeader}
            field={tableFields}
            buttonAction={(item) => {
                navigate("question/" + btoa(
                    CryptoJS.AES.encrypt(
                        item.skill_id.toString(),
                        "!@#$%^&*()"
                    ).toString()
                ))
            }} />
    </div>
}


export default LoginLogs
