import "./style.css";
import AppLayout from "../../layout";
import CustomButton from "../../compounds/button";
import { useEffect, useState } from "react";
import postRequest from "../../function/axios";
import { useNavigate } from "react-router-dom";
import SlotCard from "../../compounds/cards/launch";

function AttendanceSlots() {
  return <AppLayout rId={11} body={<Body />} />;
}

function Body() {

  const navigator = useNavigate()
  const [launch, setLaunch] = useState([]);

  const fetchSlot = async()=>{
    var res = await postRequest("/slots/get",{},navigator)
    if(res.data.data!==null && res.data.data !== undefined){
      setLaunch(res.data.data)
    }
  }

  useEffect(()=>{
    fetchSlot()
  },[])

  return (
    <div style={{ padding: 20 }}>
      <h3 style={{ fontWeight: 500, fontSize: 23 }}>Course Slot</h3>
      <div style={{ width: "110px", marginTop: 15 }}>
        <CustomButton label={"+ New Slot"} />
      </div>

      <div className="launch-list">
        {
            launch.map((item,i)=>(
                <SlotCard key={i} slot={item} onClick={"/slot/attendance/"}/>
            ))
        }
      </div>
    </div>
  );
}

export default AttendanceSlots;
