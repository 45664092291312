import { useState } from "react";
import CourseCard from "../../compounds/cards/course";
import AppLayout from "../../layout";
import "./style.css";
import axios from "axios";
import {api} from "../../utils/api";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import ToastMsg from "../../compounds/toast";

function MyLearning() {
  return <AppLayout body={<Body />} rId={4} />;
}

function Body() {
  const [vertical, setVertical] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [isLoading, setLoading] = useState(true);

  const closeToast = () => {
    setToastShow(false);
  };

  const fetchCourse = () => {
    try {
      axios({
        url: api + "/myLearning/all",
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("auth"),
        },
      })
        .then((res) => {
          if (res.data.success && res.data.data !== null) {
            setVertical(res.data.data);
          }
        })
        .catch((err) => {
          if (err.response!==undefined  && err.response.status === 401) {
            navigate("/auth/login");
          }
        });
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCourse();
  }, []);

  const openToast = (message, type) => {
    setToastMessage(message);
    setToastType(type);
    setToastShow(true);
    const timer = setTimeout(() => {
      closeToast();
    }, 5000);
  };

  return (
    <div style={{ padding: 20, display: "flex", flexDirection: "column" }}>
      <h3 style={{ fontWeight: 500, fontSize: 23 }}>My Learning</h3>

      <div className="course-card-row">
        {vertical.length === 0 ? (
          isLoading ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <h3 style={{ fontWeight: 500, marginTop: 20 }}>No Course Found</h3>
          )
        ) : (
          vertical.map((item, i) => (
            <CourseCard
              path={"details/"}
              key={i}
              id={item.id}
              title={item.name}
              value={null}
              img={item.img}
            />
          ))
        )}
      </div>

      {toastShow ? (
        <ToastMsg message={toastMessage} type={toastType} close={closeToast} />
      ) : null}
    </div>
  );
}

export default MyLearning;
