import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import BasicCard from "../../compounds/cards";
import { useEffect, useState } from "react";
import AssessmentTopBar from "./compounds/topbar";
import CustomButton from "../../compounds/button";
import TestCaseTable from "./compounds/testcase";
import { CircularProgress, Typography } from "@mui/material";
import postRequest, { postComplierRequest } from "../../function/axios";
import CryptoJS from "crypto-js";
import { useNavigate, useParams } from "react-router-dom";
import ErrorText from "../../compounds/error";
import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import Compiler from "./compounds/complier";
import MCQ from "./compounds/mcq";
import CustomDialog from "../../compounds/dialog";
import FileCompound from "./compounds/file";
import "./style.css";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

function Assessment() {
  const navigator = useNavigate();
  const [seconds, setSeconds] = useState(5);
  const [percentage, setPercentage] = useState(0);
  const [assessmentStatus, setAssessmentStatus] = useState("loading");
  const [courseDetails, setCourseDetail] = useState(null);
  const [question, setQuestion] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState(0);
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [errorTxt, setErrorTxt] = useState("");
  const [openFinish, setOpenFinish] = useState(false);
  const [openIsCompiling, setOpenIsCompiling] = useState(false);
  const handleOpen = () => setOpenFinish(true);
  const handleClose = () => setOpenFinish(false);
  const [feedback, setFeedback] = useState("");
  const [feedbackError, setFeedbackError] = useState("");
  const [isCompiling, setIsCompiling] = useState(false);

  const handleCloseCompiling = () => setOpenIsCompiling(false);
  const handleOpenCompiling = () => setOpenIsCompiling(true);

  const [allConditions, setAllConditions] = useState(false);

  var { registerId } = useParams();
  registerId = CryptoJS.AES.decrypt(atob(registerId), "!@#$%^&*()").toString(
    CryptoJS.enc.Utf8
  );

  const [checkboxes, setCheckboxes] = useState({
    instr1: false,
    instr2: false,
    // instr3: false,
    instr4: false,
    instr5: false,
    instr6: false,
    instr7: false,
    instr8: false,
  });

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setCheckboxes((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  const areAllCheckboxesChecked = () => {
    return Object.values(checkboxes).every((checked) => checked);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (courseDetails !== null && seconds > 0 && percentage < 100) {
        setSeconds((prevSeconds) => prevSeconds - 1);
        setPercentage((prevPercentage) => prevPercentage + (1 / 5) * 100);
      } else {
        clearInterval(interval);
        if (assessmentStatus !== "error" && assessmentStatus !== "feedback")
          setAssessmentStatus("ready");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [courseDetails, seconds, percentage]);

  useEffect(() => {
    console.log("dvs");
    setAllConditions(areAllCheckboxesChecked());
  }, [checkboxes]);

  const toggleFullScreen = () => {
    const element = document.documentElement;
    if (!document.fullscreenElement) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  const fetchCourseDetails = async () => {
    try {
      const res = await postRequest(
        "/register/details",
        { id: parseInt(registerId) },
        navigator
      );
      if (res.data.data !== null && res.data.data !== undefined) {
        setCourseDetail(res.data.data);
        if (res.data.data.attendance === "1") {
          if (res.data.data.feedback === null && res.data.data.submitted_on) {
            setAssessmentStatus("feedback");
          } else if (res.data.data.submitted_on) {
            setErrorTxt("Test and Feedback Already Submitted");
            setAssessmentStatus("error");
          } else {
            setAssessmentStatus("no");
            fetchQuestion(res.data.data.slot_id, res.data.data.skill_id);
          }
        } else {
          setErrorTxt("Attendance Not Marked");
          setAssessmentStatus("error");
        }
      } else {
        navigator("/404");
      }
    } catch (error) {
      console.error("Error fetching course details:", error);
    }
  };

  const fetchQuestion = async (id, skill) => {
    const res = await postRequest(
      "/assessment/question",
      {
        slot_id: parseInt(id),
        skill_id: skill,
        register_id: parseInt(registerId),
      },
      navigator
    );
    if (res.data.success) {
      setQuestion(res.data.data[selectedSection].question);
      setSections(res.data.data);
      // viewQuestion(res.data.data);
    } else {
      setErrorTxt(res.data.error);
      setAssessmentStatus("error");
    }
  };

  const viewQuestion = async (data) => {
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      var res = await postComplierRequest("/assessment/getLastCode", {
        file_path: element.id + "/" + localStorage.getItem("id") + ".c",
        navigator,
      });

      if (res.success && res.data.success === true) {
        updateQuestionText(i, res.data.data, "compile");
      }
    }
  };

  useEffect(() => {
    fetchCourseDetails();
  }, []);

  const handlePre = () => {
    if (isCompiling) {
      handleOpenCompiling();
      return;
    }

    if (selectedQuestion > 0) {
      setSelectedQuestion(selectedQuestion - 1);
    }
  };

  const handleNext = () => {
    if (isCompiling) {
      handleOpenCompiling();
      return;
    }

    if (selectedQuestion < question.length - 1) {
      setSelectedQuestion(selectedQuestion + 1);
    }
  };

  const updateQuestionText = (index, response, type) => {
    if (type === "mcq") {
      setQuestion((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[index].response = response;
        return updatedQuestions;
      });
    } else {
      setQuestion((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[index].response_content = response;
        return updatedQuestions;
      });
    }
  };

  const handleFinishTest = async () => {
    const res = await postRequest("/assessment/finish", {
      id: parseInt(registerId),
      tab_switch: parseInt(localStorage.getItem("tab-count")),
    });
    if (res.success && res.data.success) {
      // exitFullScreen();
      setOpenFinish(false);
      fetchCourseDetails();
    }
  };

  const handleStartAssessment = async () => {
    await postRequest("/assessment/start", {}, navigator);
  };

  const handleSubmitFeedback = async () => {
    if (feedback.toString().trim() !== "") {
      var res = await postRequest("/mylearning/submitFeedback", {
        id: parseInt(registerId),
        feedback: feedback,
      });
      if (res.success && res.data.success) {
        navigator("/myLearning");
      } else {
        setFeedbackError(res.data.error);
      }
    } else {
      setFeedbackError("Feedback Field can not empty");
    }
  };

  const handleSectionChange = async (index) => {
    setSelectedSection(index);
    setQuestion(sections[index].question);
    setSelectedQuestion(0);
  };

  return (
    <>
      {assessmentStatus === "no" ||
      assessmentStatus === "ready" ||
      assessmentStatus === "error" ? (
        <div
          style={{
            backgroundColor: "rgb(238, 242, 246)",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BasicCard
            maxWidth={600}
            color="white"
            padding={20}
            body={
              <div className="course-start-card">
                <h2 style={{ fontSize: 20 }}>
                  {courseDetails.name} Assessment
                </h2>
                <br />
                <p>
                  Please read and agree to the following terms and conditions
                  before proceeding with your assessment:
                </p>
                <div className="assessment-instruction">
                  <input
                    id="instr1"
                    type="checkbox"
                    onChange={handleCheckboxChange}
                  />{" "}
                  <label htmlFor="instr1">
                    <b>Assessment Timing: </b>I understand the assessment will
                    ends on {courseDetails.end_time}.
                  </label>
                </div>

                <div className="assessment-instruction">
                  <input
                    id="instr2"
                    type="checkbox"
                    onChange={handleCheckboxChange}
                  />{" "}
                  <label htmlFor="instr2">
                    <b>No Unauthorized Materials: </b>I understand that bringing
                    pre-written papers, Mobile or any other unauthorized
                    materials into the assessment area is strictly prohibited.
                  </label>
                </div>

                {/* <div className="assessment-instruction">
                  <input id="instr3" type="checkbox" onChange={handleCheckboxChange} />{" "}
                  <label htmlFor="instr3" >
                    <b> Mobile Phone Usage:</b> I agree that mobile phones are
                    not permitted during the assessment, except for two-factor
                    authentication during login. After use, I will immediately
                    place my phone in the designated rack.
                  </label>
                </div> */}

                <div className="assessment-instruction">
                  <input
                    id="instr4"
                    type="checkbox"
                    onChange={handleCheckboxChange}
                  />{" "}
                  <label htmlFor="instr4">
                    <b> Prohibition on Communication:</b> I agree not to
                    communicate, pass information or items, turn to my
                    neighbor's screen, or engage in any form of interaction with
                    peers during the assessment. Any such behavior will result
                    in penalties.
                  </label>
                </div>

                <div className="assessment-instruction">
                  <input
                    id="instr5"
                    type="checkbox"
                    onChange={handleCheckboxChange}
                  />{" "}
                  <label htmlFor="instr5">
                    <b>Reporting Unwanted Interactions:</b> If any peer
                    initiates interaction without my consent, I will immediately
                    report this to the supervisor to avoid being penalized.
                  </label>
                </div>

                <div className="assessment-instruction">
                  <input
                    id="instr6"
                    type="checkbox"
                    onChange={handleCheckboxChange}
                  />{" "}
                  <label htmlFor="instr6">
                    <b>Video Surveillance Acknowledgement:</b> I acknowledge
                    that the assessment venue is under video surveillance and
                    that any malpractice identified during post-assessment video
                    audits will result in penalties.
                  </label>
                </div>

                <div className="assessment-instruction">
                  <input
                    id="instr7"
                    type="checkbox"
                    onChange={handleCheckboxChange}
                  />{" "}
                  <label htmlFor="instr7">
                    <b>Seeking Assistance:</b> I agree to raise my hand and wait
                    for assistance from the authorities if I encounter any
                    problems related to the facility, resources, or portal
                    during the assessment.
                  </label>
                </div>

                <div className="assessment-instruction">
                  <input
                    id="instr8"
                    type="checkbox"
                    onChange={handleCheckboxChange}
                  />{" "}
                  <label htmlFor="instr8">
                    <b>Penalty for Malpractice:</b> I understand that
                    involvement in any prohibited activities will result in a
                    deduction of 500 reward points and a ten-day ban from
                    booking or appearing in any assessment on the PS portal.
                  </label>
                </div>
                <br />
                <p>
                  By ticking each box, I agree to adhere to these terms and
                  conditions to uphold the fairness and integrity of the
                  assessment process.
                </p>

                {/* <button onClick={toggleFullScreen}>Toggle Fullscreen</button> */}
                {allConditions && assessmentStatus === "ready" ? (
                  <CustomButton
                    margin={30}
                    label="Start Now"
                    func={() => {
                      if (courseDetails.enable_fullscreen === "1")
                        toggleFullScreen();
                      setAssessmentStatus("start");
                      handleStartAssessment();
                    }}
                  />
                ) : assessmentStatus === "error" ? (
                  <div>
                    <br />
                    <ErrorText label={errorTxt} />
                  </div>
                ) : !allConditions ? (
                  <div>
                    <br />
                    <ErrorText label={"Check All Terms and Conditions"} />
                  </div>
                ) : (
                  <div>
                    <h5 style={{ marginTop: 20 }}>{seconds} sec to Start</h5>
                    <br />
                    <BorderLinearProgress
                      variant="determinate"
                      value={parseInt(percentage)}
                    />
                  </div>
                )}
              </div>
            }
          />
        </div>
      ) : assessmentStatus === "feedback" ? (
        <div
          style={{
            backgroundColor: "rgb(238, 242, 246)",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BasicCard
            maxWidth={500}
            color="white"
            padding={20}
            body={
              <>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Submit a Feedback
                </Typography>
                <br />
                <h4
                  style={{
                    color: "grey",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                >
                  Any Feedback or Suggestions
                </h4>
                <textarea
                  onChange={(e) => {
                    setFeedback(e.target.value);
                  }}
                  style={{
                    width: "100%",
                    minWidth: "100%",
                    maxWidth: "100%",
                    padding: 12,
                    paddingLeft: 5,
                    fontSize: 15,
                    border: "0.5px solid grey",
                    borderRadius: 5,
                    marginTop: 5,
                    minHeight: 80,
                    boxSizing: "border-box",
                    maxHeight: 120,
                  }}
                />
                <ErrorText label={feedbackError} />
                <CustomButton
                  margin={20}
                  label={"Submit"}
                  func={handleSubmitFeedback}
                />
              </>
            }
          />
        </div>
      ) : assessmentStatus !== "start" ? (
        <div
          style={{
            display: "flex",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={70} />
        </div>
      ) : null}

      {assessmentStatus === "start" ? (
        <AssessmentBody
          question={question}
          selected={selectedQuestion}
          handleNext={handleNext}
          handlePre={handlePre}
          title={courseDetails.name}
          handleOpen={handleOpen}
          handleQuestionSubmit={updateQuestionText}
          setQuestion={setQuestion}
          endTime={courseDetails.end_time}
          handleFinishTest={handleFinishTest}
          handleFullScreen={toggleFullScreen}
          sections={sections}
          selectedSection={selectedSection}
          setSelectedQuestion={setSelectedQuestion}
          handleSectionChange={handleSectionChange}
          isCompiling={isCompiling}
          setIsCompiling={setIsCompiling}
          handleOpenCompiling={handleOpenCompiling}
          blockTabSwtich={
            courseDetails.enable_fullscreen === "1" ? true : false
          }
        />
      ) : null}

      <CustomDialog
        open={openFinish}
        handleClose={handleClose}
        title={"Finish Test"}
        body={
          <>
            {/* <FinishTestTable data={question} /> */}
            <div style={{ marginTop: 20, display: "flex", gap: 20 }}>
              <CustomButton
                label={"Finish"}
                danger={true}
                func={handleFinishTest}
              />
              <CustomButton label={"Cancel"} func={handleClose} />
            </div>
          </>
        }
      />

      <CustomDialog
        open={openIsCompiling}
        handleClose={handleCloseCompiling}
        title={"Code Submitting is Process"}
        body={
          <>
            <div style={{ marginTop: 20, display: "flex", gap: 20 }}>
              <CustomButton label={"Close"} func={handleCloseCompiling} />
            </div>
          </>
        }
      />
    </>
  );
}

function AssessmentBody(props) {
  const question = props.question;
  const selected = props.selected;
  const setQuestion = props.setQuestion;
  const [selectedLanguage, setLanguage] = useState("");

  const [openTabSwtich, setOpenSwtich] = useState(false);
  const handleOpen = () => setOpenSwtich(true);
  const handleClose = () => {
    setOpenSwtich(false);
    props.handleFullScreen();
  };
  const [code, setCode] = useState("");

  useEffect(() => {
    if (props.blockTabSwtich) {
      handleBlockTabSwtich();
    }
  }, []);

  const handleBlockTabSwtich = () => {
    const handleTabSwitch = () => {
      if (!document.hasFocus()) {
        const count = parseInt(localStorage.getItem("tab-count"));
        localStorage.setItem("tab-count", count + 1);
        handleOpen();
      }
    };

    const handleContextMenu = (e) => {
      e.preventDefault(); // Prevent the default behavior of the context menu event
    };

    window.addEventListener("blur", handleTabSwitch);
    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      window.removeEventListener("blur", handleTabSwitch);
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  };

  const onSubmitQuestion = async (value) => {
    const res = await postRequest("/assessment/answer", {
      task_id: question[selected].id,
      type: question[selected].type,
      response: value.toString(),
    });
    if (res.data.success) {
      props.handleQuestionSubmit(selected, value);
      props.handleNext();
    }
  };
  return (
    <div
      style={{
        backgroundColor: "rgb(238, 242, 246)",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <AssessmentTopBar
        title={props.title}
        handleOpen={props.handleOpen}
        endTime={props.endTime}
        handleFinishTest={props.handleFinishTest}
      />
      <div
        style={{
          padding: 15,
          paddingTop: 5,
          paddingBottom: 0,
          width: "100%",
          backgroundColor: "white",
          boxSizing: "border-box",
          display: "flex",
          gap: 10,
        }}
      >
        {props.sections.map((item, i) => (
          <div
            onClick={() => {
              props.handleSectionChange(i);
            }}
            style={{
              padding: "15px 20px",
              width: "max-content",
              cursor: "pointer",
              backgroundColor:
                props.selectedSection === i ? "rgb(238, 242, 246)" : "white",
              borderTopLeftRadius: props.selectedSection === i ? 8 : 0,
              borderTopRightRadius: props.selectedSection === i ? 8 : 0,
              boxShadow:
                props.selectedSection === i ? "1px 1px 6px 0px #8080807d" : "",
            }}
          >
            <h4 style={{ fontWeight: 500 }}>
              {i + 1 + ". " + item.section_name}
            </h4>
          </div>
        ))}
      </div>
      <div
        style={{
          flex: 3,
          display: "flex",
          backgroundColor: "rgb(238, 242, 246)",
          padding: 10,
        }}
      >
        <BasicCard
          maxWidth={115}
          padding={10}
          color="white"
          body={
            <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
              {question.map((item, i) => (
                <div
                  onClick={() => {
                    if (props.isCompiling) {
                      props.handleOpenCompiling();
                      return;
                    }
                    props.setSelectedQuestion(i);
                  }}
                  style={{
                    backgroundColor:
                      item.response !== null ? "green" : "#dfdfdf",
                    padding: 10,
                    borderRadius: 10,
                    width: "max-content",
                    minWidth: 20,
                    flex: 3,
                    cursor: "pointer",
                    border: item.response === null ? "0.5px solid #d9d7d7" : "",
                  }}
                >
                  <h3
                    style={{
                      color: item.response !== null ? "white" : "black",
                      width: "100%",
                      textAlign: "center",
                      fontWeight: 400,
                    }}
                  >
                    {i + 1}
                  </h3>
                </div>
              ))}
            </div>
          }
        />
        <div style={{ flex: 3 }}>
          <BasicCard
            color="white"
            padding={15}
            body={
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                  }}
                >
                  <div>
                    <h4
                      style={{ color: "grey", fontSize: 15, fontWeight: 500 }}
                    >
                      Question Title
                    </h4>
                    <h3 style={{ marginTop: 10 }}>
                      {selected + 1 + ". " + question[selected].title}
                    </h3>
                  </div>
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 10 }}
                  >
                    <ArrowBackIosNewRounded
                      fontSize="sm"
                      onClick={() => {
                        props.handlePre();
                        if (selected > 0) {
                          setCode(question[selected - 1].response_content);
                        }
                      }}
                    />
                    {selected + 1 + "/" + question.length}
                    <ArrowForwardIosRounded
                      fontSize="sm"
                      onClick={() => {
                        props.handleNext();
                        if (selected < question.length - 1) {
                          setCode(question[selected + 1].response_content);
                        }
                      }}
                    />
                  </div>
                </div>
                <br />
                <h4 style={{ color: "grey", fontSize: 15, fontWeight: 500 }}>
                  Question Description
                </h4>
                <div
                  style={{ marginTop: 10 }}
                  dangerouslySetInnerHTML={{
                    __html: question[selected].description,
                  }}
                />
                <br />
                {question[selected].type === "compiler"
                  ? question[selected].options.map((testCase, i) =>
                      testCase.score === 0 ? (
                        <TestCaseTable
                          key={i}
                          index={i}
                          input={testCase.input}
                          output={testCase.output}
                        />
                      ) : null
                    )
                  : null}

                <br />
                <br />
                <br />
                {question[selected].type === "compiler" ? (
                  <table className="testcase-table">
                    <tr style={{ backgroundColor: "rgb(238, 242, 246)" }}>
                      <th style={{ padding: 8, textAlign: "start" }}>
                        My TestCase
                      </th>
                      <th style={{ padding: 8, textAlign: "start" }}>Status</th>
                    </tr>
                    {question[selected].options.map((item, i) => (
                      <tr>
                        <td style={{ padding: 10 }}>TestCase #{i + 1}</td>
                        <td style={{ padding: 10 }}>
                          {item.status === "1" ? (
                            <p
                              style={{
                                backgroundColor: "#01a001",
                                color: "white",
                                padding: "4px 15px",
                                borderRadius: 50,
                                width: "max-content",
                                fontSize: 14,
                              }}
                            >
                              Passed
                            </p>
                          ) : item.status === "0" ? (
                            <p
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                padding: "4px 15px",
                                borderRadius: 50,
                                width: "max-content",
                                fontSize: 14,
                              }}
                            >
                              Not Passed
                            </p>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    ))}
                  </table>
                ) : null}
              </>
            }
          />
        </div>
        {question[selected].type !== "no-answer" ? (
          <div style={{ flex: 3 }}>
            <BasicCard
              // padding={15}
              body={
                question[selected].type === "compiler" ? (
                  <Compiler
                    selectedLanguage={selectedLanguage}
                    setLanguage={setLanguage}
                    taskId={question[selected].id}
                    testCase={question[selected].options}
                    setQuestion={setQuestion}
                    question={question}
                    selected={selected}
                    onChange={props.handleQuestionSubmit}
                    // code={code}
                    // setCode={setCode}
                    isCompiling={props.isCompiling}
                    setIsCompiling={props.setIsCompiling}
                    handleQuestionSubmit={props.handleQuestionSubmit}
                  />
                ) : question[selected].type === "mcq" ? (
                  <MCQ
                    Options={question[selected].options}
                    onSubmit={onSubmitQuestion}
                    selectedIndex={selected}
                    selected={question[selected].response}
                    questionId={question[selected].id}
                    handleQuestionSubmit={props.handleQuestionSubmit}
                    handleNext={props.handleNext}
                  />
                ) : question[selected].type === "file" ? (
                  <FileCompound
                    question={question}
                    selected={selected}
                    onChange={props.handleQuestionSubmit}
                  />
                ) : null
                // console.log(question[selected].type)
                // <Compiler/>
              }
            />
          </div>
        ) : null}
      </div>

      <CustomDialog
        open={openTabSwtich}
        handleClose={handleClose}
        title={"Warning!!"}
        body={
          <>
            <h3
              style={{
                marginTop: 10,
                fontWeight: 400,
              }}
            >
              You are Attempt to {localStorage.getItem("tab-count")} Tab
              Swtiches
            </h3>
            <CustomButton func={handleClose} label={"Okay"} margin={40} />
          </>
        }
      />
    </div>
  );
}

export default Assessment;
