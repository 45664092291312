import { useNavigate } from "react-router-dom";
import LoginAuth from "../pages/auth/login";
import Course from "../pages/course";
import Dashboard from "../pages/dashboard";
import { useEffect, useState } from "react";
import CourseList from "../pages/course/list";
import RegisterCourse from "../pages/register";
import SlotDetails from "../pages/slot/details";
import AssesmentQuestion from "../pages/slot/assessment/question";
import AddAssessmentQuestion from "../pages/slot/assessment/add";
import MyLearning from "../pages/my_learning";
import Assessment from "../pages/assessment";
import LearningDetial from "../pages/my_learning/details";
import CourseRegistered from "../pages/course/registered";
import CourseSlots from "../pages/slot";
import AuthLogout from "../pages/auth/logout";
import AttendancePage from "../pages/slot/attendance";
import ProjectsPage from "../pages/projects";
import NewProject from "../pages/projects/new_project";
import MyTrainee from "../pages/my_trainee";
import Trainers from "../pages/trainers";
import AttendanceSlots from "../pages/attendance";
import ProjectApproval from "../pages/projects/approval";
import ProjectDetails from "../pages/projects/details";
import QuestionBank from "../pages/questionBank";
import CourseQuestions from "../pages/questionBank/details";
import AddQuestionBank from "../pages/questionBank/add";
import LoginLogs from "../pages/loginLog";
import AssessmentResultDetails from "../pages/slot/result";
import AnalysisDashboard from "../pages/dashboard/analysis";
import PraticeComplier from "../pages/complier";
import CourseQuestionBank from "../pages/questionBank/course";
import EvaluationDetails from "../pages/evaluation/detail";
import EvaluationUsers from "../pages/evaluation/users";
import EditQuestionBank from "../pages/questionBank/edit";
import EvaluationAssign from "../pages/evaluation/assign";
import SQLPlayGround from "../pages/placeground/sql";
import BulkQuestionBank from "../pages/questionBank/bulk";
import CodeReview from "../pages/code";

function Protected({ children }) {
  const navigate = useNavigate();
  const [isAuth, setAuth] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("isLogin") !== "true") {
      setAuth(false);
      navigate("/auth/login");
    } else {
      setAuth(true);
    }
  }, [navigate]);

  if (isAuth) {
    return children;
  }
}

const routes = [
  {
    path: "/",
    element: (
      <Protected>
        <Dashboard />
      </Protected>
    ),
  },
  { path: "/auth/login", element: <LoginAuth /> },
  { path: "/auth/logout", element: <AuthLogout /> },
  {
    path: "/dashboard",
    element: (
      <Protected>
        <Dashboard />
      </Protected>
    ),
  },
  {
    path: "/dashboard/analysis",
    element: <Protected><AnalysisDashboard /></Protected>
  },
  {
    path: "/course",
    element: (
      <Protected>
        <Course />
      </Protected>
    ),
  },
  {
    path: "/course/registered/:courseId",
    element: (
      <Protected>
        <CourseRegistered />
      </Protected>
    ),
  },
  {
    path: "/course/:courseId",
    element: (
      <Protected>
        <CourseList />
      </Protected>
    ),
  },
  {
    path: "/registerCourse",
    element: (
      <Protected>
        <RegisterCourse />
      </Protected>
    ),
  },
  {
    path: "/slots",
    element: (
      <Protected>
        <CourseSlots />
      </Protected>
    ),
  },
  {
    path: "/slots/:slot",
    element: (
      <Protected>
        <SlotDetails />
      </Protected>
    ),
  },

  {
    path: "/myLearning",
    element: (
      <Protected>
        <MyLearning />
      </Protected>
    ),
  },
  {
    path: "/myLearning/details/:registerId",
    element: (
      <Protected>
        <LearningDetial />
      </Protected>
    ),
  },

  {
    path: "/slot/assessment/Question/:slot",
    element: (
      <Protected>
        <AssesmentQuestion />
      </Protected>
    ),
  },
  {
    path: "/slot/assessment/Question/add/:slot",
    element: (
      <Protected>
        <AddAssessmentQuestion />
      </Protected>
    ),
  },
  {
    path: "/slot/result/:registerId",
    element: (
      <Protected>
        <AssessmentResultDetails />
      </Protected>
    ),
  },
  {
    path: "/attendance",
    element: (
      <Protected>
        <AttendanceSlots />
      </Protected>
    ),
  },
  {
    path: "/slot/attendance/:slot",
    element: (
      <Protected>
        <AttendancePage />
      </Protected>
    ),
  },
  {
    path: "/myLearning/assessment/:registerId",
    element: <Assessment />,
  },
  {
    path: "/project",
    element: <ProjectsPage />,
  },
  {
    path: "/project/approve",
    element: <ProjectApproval />,
  },
  {
    path: "/project/details/:projectId",
    element: <ProjectDetails />,
  },
  {
    path: "/project/new",
    element: <NewProject />,
  },

  {
    path: "/myTrainee",
    element: <MyTrainee />,
  },
  {
    path: "/courseTrainer",
    element: <Trainers />,
  },
  // {
  //   path: "/evaluation",
  //   element: <Evaluation />,
  // },
  {
    path: "/evaluation",
    element: <EvaluationUsers />,
  },
  {
    path: "/evaluation/assign",
    element: <EvaluationAssign />,
  },
  {
    path: "/evaluation/details/:evaluationId",
    element: <EvaluationDetails />,
  },
  {
    path: "/reports/questionBank",
    element: <QuestionBank />
  },
  {
    path: "/reports/questionBank/course/:courseId",
    element: <CourseQuestionBank />
  },
  {
    path: "/reports/questionBank/course/question/:courseId",
    element: <CourseQuestions />
  },
  {
    path: "/reports/questionBank/course/question/add/:courseId",
    element: <AddQuestionBank />
  },
  {
    path: "/reports/questionBank/course/question/edit/:questionId",
    element: <EditQuestionBank />
  },
  {
    path: "/reports/questionBank/bulk",
    element: <BulkQuestionBank />
  },
  {
    path: "/reports/loginLogs",
    element: <LoginLogs />
  },
  {
    path: "/complier",
    element: <Protected><PraticeComplier /></Protected>
  },
  {
    path: "/codereview",
    element: <Protected><CodeReview /></Protected>
  },
  {
    path: "/playground/sql",
    element: <SQLPlayGround />
  },
  { path: "*", element: <h1>404</h1> },
];

export default routes;
