import { useState } from "react";
import * as XLSX from "xlsx";
import postRequest from "../../function/axios";
import { useNavigate } from "react-router-dom";

function BulkQuestionBank() {
  const [jsonData, setJsonData] = useState(null);
  const navigate = useNavigate()

  const readExcel = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      // Parse the Excel file
      const workbook = XLSX.read(data, { type: "binary" });
      // Process the data as needed
      const sheetName = workbook.SheetNames[1];
      const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      console.log(excelData);
      insertData(excelData);
    };

    reader.readAsBinaryString(file);
  };

  const insertData = async (data) => {
    const res = await postRequest("/questionBank/bulk",{data:data},navigate)
    console.log(res)
  };

  return (
    <div>
      <input
        type="file"
        onChange={(e) => {
          readExcel(e.target.files[0]);
        }}
      />
      {jsonData && <pre>{JSON.stringify(jsonData, null, 2)}</pre>}
    </div>
  );
}

export default BulkQuestionBank;
