import React, { useState } from "react";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  RemoveRedEyeRounded,
} from "@mui/icons-material";
import "./style.css";
import CustomButton from "./button";
import InputBox from "./input";

function CustomTable(props) {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [filterValue, setFilterValue] = useState("");

  const tableData = props.data;

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(currentPage * pageSize, tableData.length);

  const filteredData = tableData.filter((row) => {
    const searchMatch = Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase())
    );
    const filterMatch =
      filterValue === "" ||
      row[props.filterField].toLowerCase() === filterValue.toLowerCase();

    return searchMatch && filterMatch;
  });

  const visibleData = filteredData.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredData.length / pageSize);

  return (
    <div
      className="custom-table"
      style={{
        border: !props.disableHeader ? "1.2px solid rgb(203, 208, 221)" : null,
      }}
    >
      {!props.disableHeader ? (
        <div className="table-header">
          <div style={{ flex: 3 }}>
            <h2 style={{ fontSize: 22, fontWeight: 500 }}>{props.title}</h2>
            <p style={{ color: "grey", fontSize: 14, marginTop: 8 }}>
              {props.description}
            </p>
          </div>
          <div >
            {props.button !== undefined ? (
              <CustomButton label={props.buttonLabel} func={props.button} />
            ) : null}
          </div>
        </div>
      ) : null}

      <div
        className="custom-table-body"
        style={{
          backgroundColor: "white",
          padding: !props.disableHeader ? 20 : 0,
          overflow: "auto",
        }}
      >
        {" "}
        {!props.disableHeader ? (
          <div
            style={{
              width: 300,
              marginTop: -30,
            }}
            className="search-filter"
          >
            <InputBox
              type="text"
              placeholder="Search..."
              onChange={(e) => setSearchText(e.target.value)}
            />
            <br />
          </div>
        ) : null}
        <div
          style={{
            border: "0.2px solid rgb(216, 216, 216)",
            borderRadius: 8,
            minWidth: "max-content",
          }}
        >
          <table style={{ border: "none" }}>
            <thead style={{ border: "none" }}>
              <tr style={{ border: "none" }}>
                <td>S.No</td>
                {props.header.map((item, i) => (
                  <td key={i}>{item}</td>
                ))}
                {props.actionType !== "none" ? (
                  <td style={{ width: 100 }}>Action</td>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {visibleData.map((row, visibleIndex) => (
                <tr style={{ border: "none" }} key={startIndex + visibleIndex}>
                  <td>{startIndex + visibleIndex + 1}</td>
                  {props.field.map((item, i) => (
                    <td
                      key={i}
                      dangerouslySetInnerHTML={{ __html: row[item] }}
                    ></td>
                  ))}
                  {props.actionType !== "none" ? (
                    <td>
                      <div style={{ cursor: "pointer", width: "max-content" }}>
                        {props.actionType === "button" ? (
                          <CustomButton
                            padding={"8px 15px"}
                            label={props.buttonLabel}
                            func={() => {
                              if (props.buttonAction !== undefined)
                                props.buttonAction(
                                  props.returnType === "index"
                                    ? visibleIndex
                                    : row
                                );
                            }}
                          />
                        ) : (
                          <RemoveRedEyeRounded
                            onClick={() => {
                              if (props.buttonAction !== undefined)
                                props.buttonAction(
                                  props.returnType === "index"
                                    ? visibleIndex
                                    : row
                                );
                            }}
                          />
                        )}
                      </div>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>

          {props.returnType !== "index" ? (
            <div className="pagination">
              <h4 style={{ fontSize: 14, fontWeight: 500 }}>
                Page {currentPage} of {totalPages}
              </h4>
              <div className="pagination-right">
                <h4 style={{ fontSize: 14, fontWeight: 500 }}>
                  Rows per page:
                </h4>
                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(parseInt(e.target.value, 10));
                    setCurrentPage(1);
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
                <button
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <ArrowBackIosRounded fontSize="sm" />
                </button>
                <button
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={endIndex >= filteredData.length}
                >
                  <ArrowForwardIosRounded fontSize="sm" />
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default CustomTable;
