import CryptoJS from "crypto-js";
import AppLayout from "../../layout";
import BasicCard from "../../compounds/cards";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import postRequest from "../../function/axios";
import InputBox from "../../compounds/input";
import ToastMsg from "../../compounds/toast";
import CustomButton from "../../compounds/button";
import CustomDialog from "../../compounds/dialog";
import MCQTable from "../slot/assessment/compounds/mcq_table";
import CustomSelect from "../../compounds/select";
import JoditEditor from "jodit-react";
import TestCaseTable from "../slot/assessment/compounds/testcase_table";

function EditQuestionBank() {
  return (
    <AppLayout
      rId={6}
      body={
        <div style={{ margin: 20 }}>
          <BasicCard
            overflow="none"
            padding={20}
            color="white"
            body={<Body />}
          />
        </div>
      }
    />
  );
}

function Body() {
  const navigator = useNavigate();
  const [selectType, setSelectedType] = useState("");
  const [selectDifficulity, setDifficulity] = useState("");
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [optionLabel, setOptionLabel] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [score, setScore] = useState(0);
  const [testCaseInput, setTestCaseInput] = useState("");
  const [testCaseOutput, setTestCaseOutput] = useState("");
  const [optionScore, setOptionScore] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [selectedOption, setSelectedOption] = useState(-1);
  var { questionId } = useParams();
  questionId = CryptoJS.AES.decrypt(atob(questionId), "!@#$%^&*()").toString(
    CryptoJS.enc.Utf8
  );

  const [mcqData, setMcqData] = useState([]);
  const closeToast = () => {
    setToastShow(false);
  };

  const [testCase, setTestCase] = useState([]);
  const openToast = (message, type) => {
    setToastMessage(message);
    setToastType(type);
    setToastShow(true);
    setTimeout(() => {
      closeToast();
    }, 5000);
  };
  const updateQuestion = async () => {
    const data = {
      id: parseInt(questionId),
      title: title,
      description: description,
      score: parseInt(score),
      type: selectType,
      difficulty_level: selectDifficulity,
      options:
        selectType === "mcq"
          ? mcqData
          : selectType === "compiler"
          ? testCase
          : null,
    };
    var res = await postRequest("/questionBank/updateQuestion", data, navigator);
    res = res.data;
    if (res.success && res.data !== null && res.data !== undefined) {
      navigator(
        "/reports/questionBank"
      );
    } else {
      openToast(res.error, "error");
    }
  };

  const fetchQuestion = async () => {
    var res = await postRequest(
      "/questionBank/viewQuestion",
      { id: parseInt(questionId) },
      navigator
    );
    if (res.success === true && res.data.success === true) {
      var data = res.data.data;
      setTitle(data.title);
      setDescription(data.description);
      setSelectedType(data.type);
      setScore(data.score);
      setDifficulity(data.difficulty_level)
      if (data.type === "compiler") {
        setTestCase(data.options);
      }

      if (data.type === "mcq") {
        setMcqData(data.options);
      }
    }
  };

  useEffect(() => {
    fetchQuestion();
  }, []);

  return (
    <div>
      <h2 style={{ fontSize: 19, fontWeight: 500 }}>Edit Question</h2>
      <br />
      <InputBox
        value={title}
        label="Question Title"
        placeholder="Enter Question Title"
        onChange={(e) => {
          setTitle(e.target.value);
        }}
      />

      <div style={{ marginTop: 20 }}>
        <label
          style={{
            color: "grey",
            fontSize: 13,
          }}
        >
          Question Description
        </label>
      </div>
      <div style={{ marginTop: 8 }}>
        <JoditEditor
          // ref={editor}
          value={description}
          // ref={editor}
          // config={editorConfig}
          onChange={(value) => {
            setDescription(value);
          }}
        />
      </div>
      <div style={{ display: "flex", gap: 20, alignItems: "end" }}>
        <CustomSelect
          label="Task Type"
          placeholder="Select a Type"
          onChange={setSelectedType}
          value={selectType}
          options={[
            { label: "Compiler", value: "compiler" },
            { label: "File", value: "file" },
            { label: "MCQ", value: "mcq" },
            { label: "Manual Grading", value: "manual-grading" },
          ]}
        />
        <CustomSelect
          label="Task Difficulity"
          placeholder="Select a Difficulity"
          onChange={setDifficulity}
          options={[
            { label: "Easy", value: "easy" },
            { label: "Medium", value: "medium" },
            { label: "Hard", value: "hard" },
          ]}
        />
        <div style={{ flex: 3 }}>
          <label
            htmlFor={"score"}
            style={{
              color: "grey",
              fontSize: 13,
            }}
          >
            Task Score
          </label>
          <br />
          <input
            placeholder="Task Score"
            onChange={(e) => {
              setScore(e.target.value);
            }}
            value={score}
            type="number"
            style={{
              width: "92%",
              padding: "12px 15px",
              paddingLeft: 5,
              fontSize: 15,
              border: "0.5px solid grey",
              borderRadius: 5,
              marginTop: 5,
            }}
          />
        </div>
      </div>

      {selectType === "mcq" ? (
        <MCQTable data={mcqData} handleOpen={handleOpen} />
      ) : selectType === "compiler" ? (
        <TestCaseTable
          data={testCase}
          handleOpen={handleOpen}
          buttonAction={(index) => {
            setSelectedOption(index);
            setTestCaseInput(testCase[index].input);
            setTestCaseOutput(testCase[index].output);
            setOptionScore(testCase[index].score);
            handleOpenUpdate();
          }}
        />
      ) : null}
      <CustomButton label="Update Question" margin={30} func={updateQuestion} />

      <CustomDialog
        open={selectType === "mcq" && open}
        handleClose={handleClose}
        title={"Add Options"}
        body={
          <>
            <div style={{ width: "95%" }}>
              <InputBox
                label="Option Label"
                margin={20}
                placeholder="Label"
                onChange={(e) => {
                  setOptionLabel(e.target.value);
                }}
              />
              <InputBox
                label="Option Score"
                type="number"
                margin={20}
                placeholder="Score"
                onChange={(e) => {
                  setOptionScore(e.target.value);
                }}
              />
            </div>
            <CustomButton
              margin={20}
              label="Add Option"
              func={() => {
                setMcqData([
                  ...mcqData,
                  { label: optionLabel, score: parseInt(optionScore) },
                ]);
                handleClose();
              }}
            />
          </>
        }
      />

      <CustomDialog
        open={selectType === "compiler" && open}
        handleClose={handleClose}
        title={"Add Test Case"}
        body={
          <>
            <div style={{ width: "95%" }}>
              <h4
                style={{
                  color: "grey",
                  fontSize: 13,
                  fontWeight: 500,
                  marginTop: 20,
                }}
              >
                Input
              </h4>
              <textarea
                onChange={(e) => {
                  setTestCaseInput(e.target.value.replace(/\n/g, "<br/>"));
                }}
                style={{
                  width: "100%",
                  minWidth: "100%",
                  maxWidth: "100%",
                  padding: 12,
                  paddingLeft: 5,
                  fontSize: 15,
                  border: "0.5px solid grey",
                  borderRadius: 5,
                  marginTop: 5,
                  minHeight: 60,
                  maxHeight: 100,
                }}
              />

              
<h4
                style={{
                  color: "grey",
                  fontSize: 13,
                  fontWeight: 500,
                  marginTop: 20,
                }}
              >
                Output
              </h4>
              <textarea
                onChange={(e) => {
                  setTestCaseOutput(e.target.value.replace(/\n/g, "<br/>"));
                }}
                style={{
                  width: "100%",
                  minWidth: "100%",
                  maxWidth: "100%",
                  padding: 12,
                  paddingLeft: 5,
                  fontSize: 15,
                  border: "0.5px solid grey",
                  borderRadius: 5,
                  marginTop: 5,
                  minHeight: 60,
                  maxHeight: 100,
                }}
              />
              <InputBox
                label="Test Case Score"
                type="number"
                margin={20}
                placeholder="Score"
                onChange={(e) => {
                  setOptionScore(e.target.value);
                }}
              />
            </div>
            <CustomButton
              margin={20}
              label="Add Test Case"
              func={() => {
                setTestCase([
                  ...testCase,
                  {
                    id:0,
                    input: testCaseInput,
                    output: testCaseOutput,
                    score: parseInt(optionScore),
                  },
                ]);
                handleClose();
              }}
            />
          </>
        }
      />

      <CustomDialog
        open={selectType === "compiler" && openUpdate}
        handleClose={handleCloseUpdate}
        title={"Update Test Case"}
        body={
          <>
            <div style={{ width: "95%" }}>
              <h4
                style={{
                  color: "grey",
                  fontSize: 13,
                  fontWeight: 500,
                  marginTop: 20,
                }}
              >
                Input
              </h4>
              <textarea
                defaultValue={
                  selectedOption !== -1
                    ? testCase[selectedOption].input.replace(/<br\/>/g, "\n")
                    : null
                }
                onChange={(e) => {
                  setTestCaseInput(e.target.value.replace(/\n/g, "<br/>"));
                }}
                style={{
                  width: "100%",
                  minWidth: "100%",
                  maxWidth: "100%",
                  padding: 12,
                  paddingLeft: 5,
                  fontSize: 15,
                  border: "0.5px solid grey",
                  borderRadius: 5,
                  marginTop: 5,
                  minHeight: 60,
                  maxHeight: 100,
                }}
              />

              <h4
                style={{
                  color: "grey",
                  fontSize: 13,
                  fontWeight: 500,
                  marginTop: 20,
                }}
              >
                Output
              </h4>
              <textarea
                defaultValue={
                  selectedOption !== -1
                    ? testCase[selectedOption].output.replace(/<br\/>/g, "\n")
                    : null
                }
                onChange={(e) => {
                  setTestCaseOutput(e.target.value.replace(/\n/g, "<br/>"));
                }}
                style={{
                  width: "100%",
                  minWidth: "100%",
                  maxWidth: "100%",
                  padding: 12,
                  paddingLeft: 5,
                  fontSize: 15,
                  border: "0.5px solid grey",
                  borderRadius: 5,
                  marginTop: 5,
                  minHeight: 60,
                  maxHeight: 100,
                }}
              />

              <InputBox
                defaultValue={
                  selectedOption !== -1 ? testCase[selectedOption].score : null
                }
                label="Test Case Score"
                type="number"
                margin={20}
                placeholder="Score"
                onChange={(e) => {
                  setOptionScore(e.target.value);
                }}
              />
            </div>
            <CustomButton
              margin={20}
              label="Update Test Case"
              func={() => {
                const updatedTestCase = [...testCase];
                updatedTestCase[selectedOption] = {
                  id:testCase[selectedOption].id,
                  input: testCaseInput,
                  output: testCaseOutput,
                  score: parseInt(optionScore),
                };
                setTestCase(updatedTestCase);
                handleCloseUpdate();
              }}
            />
          </>
        }
      />

      {toastShow ? (
        <ToastMsg message={toastMessage} type={toastType} close={closeToast} />
      ) : null}
    </div>
  );
}

export default EditQuestionBank;
