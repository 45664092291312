import CustomButton from "../../../../compounds/button";
import CustomTable from "../../../../compounds/table";

function MCQTable(props){

    const tableHeader = ["Option", "Score"];

    const tableFields = ["label", "score"];

    return (
        <div style={{marginTop:40}}>
        <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
          <h3>MCQ Options</h3>
         <div style={{width:'max-content'}}>
         <CustomButton label="Add" padding={'8px 15px'} func={props.handleOpen}/>
         </div>
        </div>
        <CustomTable data={props.data} header={tableHeader} field={tableFields} disableHeader={true}/>
      </div>
    )
}

export default MCQTable