import { useEffect, useState } from "react";
import Badge from "../../compounds/badge";
import BasicCard from "../../compounds/cards";
import AppLayout from "../../layout";
import "./style.css";
import CustomTable from "../../compounds/table";
import primaryColor from "../../theme/colors";
import { EditRounded, PlayArrowOutlined } from "@mui/icons-material";
import CustomSelect from "../../compounds/select";
import { useNavigate, useParams } from "react-router-dom";
import postRequest from "../../function/axios";
import { CircularProgress } from "@mui/material";
import CryptoJS from "crypto-js";
import * as XLSX from "xlsx";
import CustomDialog from "../../compounds/dialog";
import { Editor } from "@monaco-editor/react";

function SlotDetails() {
  return <AppLayout rId={5} body={<Body />} />;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxHeight: "80%",
  borderRadius: 2,
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  overflow: "auto",
  p: 2,
};

function Body() {
  const [selectedAction, setSelectedAction] = useState();
  const navigator = useNavigate();
  const [slotDetails, setSlotDetails] = useState(null);
  const [openSlot, setOpenSlot] = useState(false);
  var { slot } = useParams();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  slot = CryptoJS.AES.decrypt(atob(slot), "!@#$%^&*()").toString(
    CryptoJS.enc.Utf8
  );
  const courseAction = [
    {
      label: "Assigment Question",
      value: 0,
    },
    {
      label: "Slot Attendance",
      value: 1,
    },
  ];

  const [courseData, setCourseData] = useState([]);

  const tableHeader = [
    "User Id",
    "Name",
    openSlot ? "Course" : null,
    "Attendance",
    "Mark",
    "Attempts",
  ];

  const tableFields = [
    "user_id",
    "name",
    openSlot ? "course" : null,
    "attendance",
    "score",
    "attempt",
  ];

  const handleCourseAction = (value) => {
    setSelectedAction(value);
  };

  const fetchSlotUser = async () => {
    const res = await postRequest(
      "/slots/users",
      { id: parseInt(slot) },
      navigator
    );
    if (res.data.data !== null && res.data.data !== undefined) {
      setCourseData(res.data.data);
    }
  };

  const handleCourseRun = () => {
    if (selectedAction === 0) {
      navigator(
        "/slot/assessment/Question/" +
          btoa(CryptoJS.AES.encrypt(slot.toString(), "!@#$%^&*()").toString())
      );
    }

    if (selectedAction === 1) {
      navigator(
        "/slot/attendance/" +
          btoa(CryptoJS.AES.encrypt(slot.toString(), "!@#$%^&*()").toString())
      );
    }
  };

  const fetchSlotDetails = async () => {
    const res = await postRequest(
      "/slots/details",
      { id: parseInt(slot) },
      navigator
    );
    if (res.data.data !== null && res.data.data !== undefined) {
      setSlotDetails(res.data.data);
      if (res.data.data.skill_id === 0) {
        setOpenSlot(true);
      }
    }
  };

  const countStatus = (list, targetStatus) => {
    return list.reduce((count, i) => {
      if (i.attendance === targetStatus) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  useEffect(() => {
    fetchSlotDetails();
    fetchSlotUser();
  }, []);

  const handleDownload = () => {
    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(courseData);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Students Registered");

    XLSX.writeFile(workbook, "Course Registered.xlsx", { compression: true });
  };

  return slotDetails ? (
    <div style={{ padding: 20 }}>
      <div className="course-list">
        <CustomTable
          button={() => {
            handleDownload();
          }}
          buttonAction={(item) => {
            navigator(
              "/slot/result/" +
                btoa(
                  CryptoJS.AES.encrypt(
                    item.id.toString(),
                    "!@#$%^&*()"
                  ).toString()
                )
            );
          }}
          buttonLabel="Download"
          title={openSlot ? "Slot Booked Users" : slotDetails.name}
          description={
            openSlot ? (
              <h4 style={{ marginTop: 10 }}>
                {courseData.length} - Registered,{" "}
                {countStatus(courseData, "Present")} - Present ,{" "}
                {countStatus(courseData, "Absent")} - Absent
              </h4>
            ) : (
              "You can find the Slot Booked Users"
            )
          }
          data={courseData}
          header={tableHeader}
          field={tableFields}
        />
        {!openSlot ? (
          <BasicCard
            overflow="none"
            color="white"
            body={
              <>
                <img
                  width={"100%"}
                  alt="course-img"
                  src={
                    "https://ps.bitsathy.ac.in/images/course/" + slotDetails.img
                  }
                />
                <div className="course-card-content">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h2 style={{ fontSize: 21, fontWeight: 500 }}>
                      {slotDetails.name}
                    </h2>
                    <EditRounded style={{ cursor: "pointer" }} fontSize="sm" />
                  </div>
                  <div
                    style={{
                      marginTop: 5,
                      display: "flex",
                      gap: 5,
                      flexWrap: "wrap",
                    }}
                  >
                    <Badge
                      type={slotDetails.status === "1" ? "success" : "danger"}
                      label={
                        slotDetails.status === "1" ? "Active" : "Non Active"
                      }
                    />
                  </div>
                  <br />
                  <h5>{courseData.length} - Registered</h5>
                  <h5 style={{ marginTop: 5 }}>
                    {countStatus(courseData, "Present")} - Present
                  </h5>
                  <h5 style={{ marginTop: 5 }}>
                    {countStatus(courseData, "Absent")} - Absent
                  </h5>
                  <div style={{ display: "flex", alignItems: "end", gap: 10 }}>
                    <div style={{ flex: 3 }}>
                      <CustomSelect
                        label="Run Action"
                        placeholder="Select an Action"
                        options={courseAction}
                        onChange={handleCourseAction}
                      />
                    </div>
                    <div
                      onClick={handleCourseRun}
                      style={{
                        backgroundColor: primaryColor,
                        color: "white",
                        padding: 5,
                        borderRadius: 5,
                        height: "max-content",
                        cursor: "pointer",
                      }}
                    >
                      <PlayArrowOutlined />
                    </div>
                  </div>
                </div>
              </>
            }
          />
        ) : null}
      </div>

      {open ? (
        <CustomDialog
          open={open}
          handleClose={handleClose}
          title={"Response"}
          style={style}
          body={
            <div style={{ marginTop: 10, padding: 20 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h4 style={{ fontWeight: 400 }}>User Id : 7376211CS261</h4>
                  <h4 style={{ fontWeight: 400, marginTop: 5 }}>
                    Name : Rishvanth
                  </h4>
                  <h4 style={{ fontWeight: 400, marginTop: 5 }}>
                    Email : rishvanthrajaa.cs21@bitsathy.ac.in
                  </h4>
                </div>
                <div>
                  <h4 style={{ fontWeight: 400 }}>Test Status : Submitted</h4>
                  <h4 style={{ fontWeight: 400, marginTop: 5 }}>
                    Tab Swtich : 12
                  </h4>
                </div>
              </div>

              <h3 style={{ marginTop: 20, fontWeight: 500 }}>Question Title</h3>
              <div
                style={{
                  padding: 10,
                  borderRadius: 10,
                  marginTop: 10,
                  backgroundColor: "#1e1e1e",
                }}
              >
                <Editor
                  options={{
                    fontSize: 17,
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    tabSize: 8,
                    letterSpacing: 1.3,
                    readOnly: true,
                    // lineNumbers: null
                  }}
                  height={400}
                  width={"100%"}
                  theme={"vs-dark"}
                  language={"python"}
                  value="evwuib bfewi  iu fuebw"
                />
              </div>
              <h4 style={{ marginTop: 10, marginLeft: 10, fontWeight: 500 }}>
                4 Test Case Passed, 40 Marks
              </h4>

              <h3 style={{ marginTop: 20, fontWeight: 500 }}>Question Title</h3>
              <div
                style={{
                  padding: 10,
                  borderRadius: 10,
                  marginTop: 10,
                  backgroundColor: "#1e1e1e",
                }}
              >
                <Editor
                  options={{
                    fontSize: 17,
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    tabSize: 8,
                    letterSpacing: 1.3,
                    readOnly: true,
                    // lineNumbers: null
                  }}
                  height={400}
                  width={"100%"}
                  theme={"vs-dark"}
                  language={"python"}
                  value="evwuib bfewi  iu fuebw
                  evwuib bfewi  iu fuebwevwuib bfewi  iu fuebwevwuib bfewi  iu fuebw
                  
                  evwuib bfewi  iu fuebwevwuib bfewi  iu fuebw
                  evwuib bfewi  iu fuebw
                  evwuib bfewi  iu fuebw
                  evwuib bfewi  iu fuebw
                  evwuib bfewi  iu fuebw
                  evwuib bfewi  iu fuebw\n\n\n
                  "
                />
              </div>
              <h4 style={{ marginTop: 10, marginLeft: 10, fontWeight: 500 }}>
                4 Test Case Passed, 40 Marks
              </h4>
            </div>
          }
        />
      ) : null}
    </div>
  ) : (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress style={{ height: 70, width: 70 }} />
    </div>
  );
}

export default SlotDetails;
