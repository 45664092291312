import { useEffect, useState } from "react";
import CustomButton from "../../compounds/button";
import BasicCard from "../../compounds/cards";
import InputBox from "../../compounds/input";
import CustomSelect from "../../compounds/select";
import AppLayout from "../../layout";
import postRequest from "../../function/axios";
import { useNavigate } from "react-router-dom";
import CustomSelect2 from "../../compounds/select2";
import { DeleteForeverRounded } from "@mui/icons-material";
import ToastMsg from "../../compounds/toast";
import { CircularProgress, LinearProgress } from "@mui/material";

function NewProject() {
  return <AppLayout rId={8} body={<Body />} />;
}

function Body() {
  const navigate = useNavigate();
  const [projectTitle, setProjectTitle] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [selectedProjectType, setSelectedProjectType] = useState("");
  const [projectDomain, setProjectDomain] = useState([]);
  const [selectedProjectDomain, setSelectedProjectDomain] = useState(0);
  const [projectMembers, setProjectMembers] = useState([]);
  const [toastType, setToastType] = useState("");
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const closeToast = () => {
    setToastShow(false);
  };
  const fetchDomain = async () => {
    var res = await postRequest("/course/allVertical", {}, navigate);

    if (res.success === true && res.data.success === true) {
      setProjectDomain(res.data.data);
    }
  };

  const updateProjectMemberId = (index, newValue) => {
    const updatedMembers = [...projectMembers];
    updatedMembers[index].user = newValue;
    updatedMembers[index].loadingName = false;
    setProjectMembers(updatedMembers);

    if (selectedProjectDomain.value !== 0 && newValue !== null) {
      fetchLevels(index);
    }
  };

  const initializeMembers = () => {
    setProjectMembers([
      {
        user: parseInt(localStorage.getItem("id")),
        contribution: 0,
        level: 0,
        eligible: [],
        loadingName: false,
        loadingLevel: false,
      },
    ]);
  };

  const removeProjectMember = (index) => {
    const updatedMembers = [...projectMembers];
    updatedMembers.splice(index, 1);
    setProjectMembers(updatedMembers);
  };

  const fetchLevels = async (index) => {
    const res = await postRequest(
      "/course/allCourse",
      {
        id: selectedProjectDomain.value,
        user_id: projectMembers[index].user.value,
      },
      navigate
    );

    if (res.success === true && res.data.success === true) {
      var temp = [];
      res.data.data.forEach((element) => {
        if (element.status === "Completed") {
          temp.push(element);
        }
      });

      const updatedMembers = [...projectMembers];
      updatedMembers[index].eligible = temp;
      setProjectMembers(updatedMembers);
    }
  };

  const handleSelectLevel = async (index, level) => {
    const updatedMembers = [...projectMembers];
    updatedMembers[index].level = level;
    setProjectMembers(updatedMembers);
  };

  const handleMemberContribution = async (index, newValue) => {
    const updatedMembers = [...projectMembers];
    updatedMembers[index].contribution = newValue;
    setProjectMembers(updatedMembers);
  };

  const handleAddUser = () => {
    // Adding a new value at the end of the array
    setProjectMembers([
      ...projectMembers,
      {
        user: "",
        contribution: 0,
        level: 0,
        eligible: [],
        loadingName: false,
        loadingLevel: false,
      },
    ]);
  };

  const validateForm = () => {
    if (projectTitle === "") {
      openToast("Project Title Required", "error");
      return;
    }

    if (projectDescription === "") {
      openToast("Project Description Required", "error");
      return;
    }

    if (selectedProjectType === "") {
      openToast("Project Type Required", "error");
      return;
    }

    if (selectedProjectDomain === 0) {
      openToast("Project Domain Required", "error");
      return;
    }

    for (let index = 0; index < projectMembers.length; index++) {
      const element = projectMembers[index];

      if (element.user === "") {
        openToast("Project Member " + (index + 1) + " Name Required", "error");
        return;
      }

      if (element.level === 0) {
        openToast("Project Member " + (index + 1) + " Level Required", "error");
        return;
      }

      if (element.contribution === 0) {
        openToast(
          "Project Member " + (index + 1) + " Contribution Required",
          "error"
        );
        return;
      }

      if (element.contribution <= 0 || element.contribution > 100) {
        openToast(
          "Project Member " +
          (index + 1) +
          " Contribution should between 1 to 100",
          "error"
        );
        return;
      }
    }

    createProject();
  };

  const createProject = async () => {
    var res = await postRequest("/projects/create", {
      title: projectTitle,
      description: projectDescription,
      project_type: selectedProjectType,
      project_domain: parseInt(selectedProjectDomain.value),
      project_members: projectMembers.map((item) => ({
        user: item.user.value,
        level: parseInt(item.level.value),
        contribution: parseInt(item.contribution),
      })),
    });

    if (res.success && res.data.success == true) {
      navigate("/project");
    } else {
      if (!res.success) {
        openToast(res.error, "error");
      } else {
        openToast(res.data.error, "error");
      }
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    initializeMembers();
    fetchDomain();
  }, []);

  const openToast = (message, type) => {
    setToastMessage(message);
    setToastType(type);
    setToastShow(true);
    const timer = setTimeout(() => {
      closeToast();
    }, 5000);
    setIsSubmitting(false);
  };

  return (
    <div style={{ padding: 20 }}>
      <BasicCard
        color="white"
        padding={15}
        // margin={20}
        overflow="none"
        body={
          <>
            <h3 style={{ fontWeight: 500, fontSize: 20 }}>Projects Details</h3>
            <div style={{ marginTop: 20 }}>
              <InputBox
                label={"Project Title"}
                onChange={(e) => setProjectTitle(e.target.value)}
              />
              <div style={{ marginTop: 20 }}>
                <label
                  style={{
                    color: "grey",
                    fontSize: 13,
                  }}
                >
                  Project Description
                </label>
                <textarea
                  onChange={(e) => setProjectDescription(e.target.value)}
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    minWidth: "100%",
                    padding: 12,
                    boxSizing: "border-box",
                    fontSize: 15,
                    border: "0.5px solid grey",
                    borderRadius: 5,
                    minHeight: 100,
                    maxHeight: 300,
                    marginTop: 5,
                  }}
                />
              </div>
            </div>
            <div
              className="project-field-row"
              style={{ display: "flex", gap: 20 }}>
              <CustomSelect
                label="Select Project Type"
                placeholder={"Select a Type"}
                onChange={setSelectedProjectType}
                options={[
                  { label: "Static", value: "static" },
                  { label: "Dynamic", value: "dynamic" },
                ]}
              />
              <CustomSelect2
                label="Select Project Domain"
                placeholder={"Select a Domain"}
                value={selectedProjectDomain}
                onChange={(e) => {
                  setSelectedProjectDomain(e);
                  initializeMembers();
                }}
                options={projectDomain.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </div>

            <div
              style={{
                display: "flex",
                gap: 20,
                alignItems: "center",
                marginTop: 30,
              }}
            >
              <h3 style={{ fontWeight: 500, fontSize: 20 }}>Team Details</h3>

              {/* <div style={{ width: 120 }}>
                <CustomButton
                  label="Add Member"
                  padding={8}
                  func={handleAddUser}
                />
              </div> */}
            </div>
            {projectMembers.map((item, i) => (
              <div
                className="project-field-row"
                key={i}
                style={{ display: "flex", alignItems: "flex-end", gap: 20 }}
              >
                <CustomSelect2
                  isLoading={item.loadingName}
                  label={"Team Member " + (i + 1)}
                  placeholder={"Select a Level"}
                  onChange={(e) => {
                    updateProjectMemberId(i, e);
                  }}
                  value={item.user}
                  options={[
                    {
                      label:
                        localStorage.getItem("name") +
                        " - " +
                        localStorage.getItem("id"),
                      value: localStorage.getItem("id"),
                    },
                  ]}
                />
                <CustomSelect2
                  isLoading={item.loadingLevel}
                  label="Select Project Level"
                  placeholder={"Select a Level"}
                  onChange={(e) => {
                    handleSelectLevel(i, e);
                  }}
                  value={item.level}
                  options={item.eligible.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
                <InputBox
                  flex={true}
                  label="Member 1 Contribution (0 - 100)"
                  margin={15}
                  type="number"
                  placeholder="Between 0 - 100"
                  onChange={(e) => {
                    handleMemberContribution(i, e.target.value);
                  }}
                />

                {i !== 0 ? (
                  <div
                    onClick={() => {
                      removeProjectMember(i);
                    }}
                    style={{
                      backgroundColor: "red",
                      height: "max-content",
                      padding: 10,
                      color: "white",
                      borderRadius: 10,
                      cursor: "pointer",
                    }}
                  >
                    <DeleteForeverRounded />
                  </div>
                ) : null}
              </div>
            ))}

            {isSubmitting ? (
              <center style={{ marginTop: 50 }}>
                <CircularProgress />
              </center>
            ) : (
              <CustomButton
                margin={"30px"}
                label={"Create New Project"}
                func={() => {
                  setIsSubmitting(true);
                  validateForm();
                }}
              />
            )}
          </>
        }
      />
      {toastShow ? (
        <ToastMsg message={toastMessage} type={toastType} close={closeToast} />
      ) : null}
    </div>
  );
}

export default NewProject;
