import "./style.css";
import { useEffect, useState } from "react";
import BasicCard from "../../compounds/cards";
import CustomSnackbar from "../../compounds/snackbar";
import AppLayout from "../../layout";
import TextMaterial from "./compounds/text_material";
import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import MaterialList from "./compounds/material_list";
import YoutubeMaterial from "./compounds/youtube_material";
import CustomButton from "../../compounds/button";
import ErrorText from "../../compounds/error";
import {
  Backdrop,
  CircularProgress,
  Fade,
  Modal,
  Typography,
} from "@mui/material";
import CustomSelect from "../../compounds/select";
import postRequest from "../../function/axios";
import CryptoJS from "crypto-js";
import { useNavigate, useParams } from "react-router-dom";
import LinkMaterial from "./compounds/link_material";

function LearningDetial() {
  return <AppLayout body={<Body />} rId={4} />;
}


function TextFields(props) {
  return (
    <div style={{ marginTop: 15 }}>
      <h5 style={{ color: "gray", fontWeight: 500, fontSize: 14 }}>
        {props.label}
      </h5>
      <h3 style={{ marginTop: 5 }}>{props.value}</h3>
    </div>
  );
}

function Body() {
  const navigator = useNavigate();
  const [open, setOpen] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const handleOpen = () => {
    setSlotBookingError("");
    setOpen(true);
  };
  const handleOpenFeedback = () => {
    setSlotBookingError("");
    setOpenFeedback(true);
  };
  const handleClose = () => setOpen(false);
  const handleCloseFeedback = () => setOpenFeedback(false);
  const [selectSlot, setSelectedSlot] = useState(0);
  const [openAssessment, setOpenAssessment] = useState(false);
  var { registerId } = useParams();
  registerId = CryptoJS.AES.decrypt(atob(registerId), "!@#$%^&*()").toString(
    CryptoJS.enc.Utf8
  );
  const [materialData, setMaterialData] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [courseDetails, setCourseDetail] = useState(null);
  const [slotAvailable, setSlotAvailable] = useState([]);
  const [feedback, setFeedback] = useState("");
  const [slotBookingError, setSlotBookingError] = useState("");
  const handleMaterial = (action) => {
    if (action === "next" && selectedMaterial + 1 < materialData.length) {
      setSelectedMaterial(parseInt(selectedMaterial + 1));
    } else if (action === "back" && selectedMaterial > 0) {
      setSelectedMaterial(parseInt(selectedMaterial - 1));
    }
  };

  const handleMaterialList = (material) => {
    setSelectedMaterial(material);
  };

  const fetchCourseDetails = async () => {
    var res = await postRequest(
      "/register/details",
      { id: parseInt(registerId) },
      navigator
    );
    if (res.data.data !== null && res.data.data !== undefined) {
      setCourseDetail(res.data.data);
      fetchMaterials(res.data.data.skill_id);
      if (res.data.data.slot_id === 0) {
        fetchSlotAvailable(res.data.data.skill_id);
      } else {
        fetchCurrentTime(
          res.data.data.date,
          res.data.data.start_time,
          res.data.data.end_time
        );
      }
    }
  };

  const fetchMaterials = async (id) => {
    const res = await postRequest("/materials/get", { id: id }, navigator);
    if (res.data.success && res.data.data) {
      setSelectedMaterial(0);
      setMaterialData(res.data.data);
    }
  };

  const handleBookSlot = async (event) => {
    var res = await postRequest(
      "/slots/book",
      {
        id: parseInt(registerId),
        skill_id: courseDetails.skill_id,
        slot_id: parseInt(selectSlot),
      },
      navigator
    );
    if (res.data.success) {
      handleClose();
      fetchCourseDetails();
    } else {
      setSlotBookingError(res.data.error);
    }
  };

  const fetchCurrentTime = async (date, startTime, endTime) => {
    var res = await postRequest("/currentTime", {}, navigator);

    const slotDate = new Date(date);
    const serverDateObject = new Date(res.data.serverDate);
    const currentTimeObject = new Date(
      `2000-01-01T${res.data.currentDateTime}`
    );
    const startTimeObject = new Date(`2000-01-01T${startTime}`);
    const endTimeObject = new Date(`2000-01-01T${endTime}`);

    if (
      slotDate.toDateString() === serverDateObject.toDateString() &&
      currentTimeObject >= startTimeObject &&
      currentTimeObject <= endTimeObject
    ) {
      setOpenAssessment(true);
    } else {
      setOpenAssessment(false);
    }
  };

  const fetchSlotAvailable = async (id) => {
    var res = await postRequest(
      "/slots/available",
      { id: parseInt(id) },
      navigator
    );
    if (res.data.data !== null && res.data.data !== undefined) {
      setSlotAvailable(res.data.data);
    }
  };

  const handleSubmitFeedback = async () => {
    if (feedback.toString().trim() !== "") {
      var res = await postRequest("/mylearning/submitFeedback", {
        id: parseInt(registerId),
        feedback: feedback,
      });
      console.log(res);
      if (res.success && res.data.success) {
        setOpenFeedback(false);
        fetchCourseDetails();
      } else {
        setSlotBookingError(res.data.error);
      }
    } else {
      setSlotBookingError("Feedback Field can not empty");
    }
  };

  useEffect(() => {
    fetchCourseDetails();
  }, []);

  return courseDetails ? (
    openAssessment && !courseDetails.submitted_on ? (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <BasicCard
          maxWidth="50%"
          padding={20}
          color="white"
          body={
            <center>
              <h3 style={{ fontSize: 19 }}>It's Time for Assesment</h3>
              <h1 style={{ fontSize: 100, marginTop: 40 }}>
                {courseDetails.seat_no}
              </h1>
              <h4>Seat Number</h4>
              <p style={{ fontSize: 14, marginTop: 10 }}>
                Kindly Verify your Seat Number
              </p>
              <div style={{ width: "70%", marginTop: 30 }}>
                {courseDetails.attendance === "1" ? (
                  <CustomButton
                    label="Launch Test Portal"
                    func={() => {
                      localStorage.setItem("tab-count", "0");
                      navigator(
                        "/myLearning/assessment/" +
                          btoa(
                            CryptoJS.AES.encrypt(
                              registerId,
                              "!@#$%^&*()"
                            ).toString()
                          )
                      );
                    }}
                  />
                ) : (
                  <ErrorText label={"Attendance Not Marked"} />
                )}
              </div>
            </center>
          }
        />
      </div>
    ) : (
      <div style={{ padding: 20 }}>
        {courseDetails.slot_id === 0 ? (
          <CustomSnackbar
            label="Assesment Not Booked still now"
            type="danger"
          />
        ) : null}
        <div style={{ marginTop: 15 }} className="course-list">
          <div style={{ flex: 3 }}>
            <div
              style={{
                flex: 3,
                backgroundColor: "white",
                borderRadius: 10,
                padding: 20,
              }}
            >
              <h3 style={{ fontWeight: 500 }}>Course Material</h3>
              {selectedMaterial !== null ? (
                materialData[selectedMaterial].type === "text" ? (
                  <TextMaterial material={materialData[selectedMaterial]} />
                ) : materialData[selectedMaterial].type === "youtube" ? (
                  <YoutubeMaterial
                    link={materialData[selectedMaterial].content}
                  />
                ) : materialData[selectedMaterial].type === "link" ? (
                  <LinkMaterial material={materialData[selectedMaterial]} />
                ) : null
              ) : null}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 30,
                  gap: 40,
                }}
              >
                {selectedMaterial > 0 ? (
                  <div
                    onClick={() => {
                      handleMaterial("back");
                    }}
                    style={{
                      backgroundColor: "#e1e1e1",
                      padding: "10px 12px 8px 10px",
                      borderRadius: 50,
                      cursor: "pointer",
                    }}
                  >
                    <ArrowBackIosNewRounded fontSize="sm" />
                  </div>
                ) : null}
                {selectedMaterial + 1 < materialData.length ? (
                  <div
                    onClick={() => {
                      handleMaterial("next");
                    }}
                    style={{
                      backgroundColor: "#e1e1e1",
                      padding: "10px 12px 8px 10px",
                      cursor: "pointer",
                      borderRadius: 50,
                    }}
                  >
                    <ArrowForwardIosRounded fontSize="sm" />
                  </div>
                ) : null}
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 10,
                  padding: 20,
                }}
              >
                <h4>Course Playlist</h4>
                {materialData.map((item, i) => (
                  <MaterialList
                    key={i}
                    index={i}
                    material={item}
                    func={handleMaterialList}
                  />
                ))}
              </div>
            </div>
          </div>
          <BasicCard
            body={
              <>
                <BasicCard
                  overflow="none"
                  color="white"
                  body={
                    <>
                      <img
                        width={"100%"}
                        alt="course-img"
                        src={
                          "https://ps.bitsathy.ac.in/images/course/" +
                          courseDetails.img
                        }
                      />
                      <div className="course-card-content">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <h2 style={{ fontSize: 21, fontWeight: 500 }}>
                            {courseDetails.name}
                          </h2>
                        </div>
                      </div>
                    </>
                  }
                />
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: 10,
                    padding: 20,
                    marginTop: 10,
                  }}
                >
                  <h4 style={{ fontWeight: 500 }}>Assessment Details</h4>
                  {courseDetails.slot_id === 0 ? (
                    <CustomButton
                      margin={10}
                      label="Book a Slot"
                      func={handleOpen}
                    />
                  ) : (
                    <div style={{ marginTop: 20 }}>
                      <TextFields label="Date" value={courseDetails.date} />
                      <TextFields
                        label="Starting Time"
                        value={courseDetails.start_time}
                      />
                      <TextFields
                        label="Ending Time"
                        value={courseDetails.end_time}
                      />
                      <TextFields label="Venue" value={courseDetails.venue} />
                      <TextFields
                        label="Seat No"
                        value={courseDetails.seat_no}
                      />

                      <TextFields
                        label="Test Submitted on"
                        value={
                          courseDetails.submitted_on
                            ? "Submitted"
                            : "Not Submitted"
                        }
                      />
                    </div>
                  )}
                </div>

                {courseDetails.submitted_on &&
                courseDetails.feedback === null ? (
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: 10,
                      padding: 20,
                      marginTop: 10,
                    }}
                  >
                    <h4 style={{ fontWeight: 500 }}>Feedback</h4>

                    <CustomButton
                      margin={10}
                      label="Submit a Feedback"
                      func={handleOpenFeedback}
                    />
                  </div>
                ) : null}

                {courseDetails.feedback !== null ? (
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: 10,
                      padding: 20,
                      marginTop: 10,
                    }}
                  >
                    <h4 style={{ fontWeight: 500 }}>Feedback : Submitted</h4>
                  </div>
                ) : null}
              </>
            }
          />
        </div>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <div className="slot-booking">
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Book a Slot
              </Typography>
              <CustomSelect
                onChange={setSelectedSlot}
                label="Select a Slot"
                options={slotAvailable.map((item) => ({
                  label: item.slot,
                  value: item.id,
                }))}
                placeholder="Select any Slot"
              />
              <ErrorText label={slotBookingError} />
              <CustomButton
                margin={20}
                label={"Book Now"}
                func={handleBookSlot}
              />
            </div>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openFeedback}
          onClose={handleCloseFeedback}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openFeedback}>
            <div className="slot-booking">
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Submit a Feedback
              </Typography>
              <br />
              <h4
                style={{
                  color: "grey",
                  fontSize: 13,
                  fontWeight: 500,
                }}
              >
                Any Feedback or Suggestions
              </h4>
              <textarea
                onChange={(e) => {
                  setFeedback(e.target.value);
                }}
                style={{
                  width: "100%",
                  minWidth: "100%",
                  maxWidth: "100%",
                  padding: 12,
                  paddingLeft: 5,
                  fontSize: 15,
                  border: "0.5px solid grey",
                  borderRadius: 5,
                  marginTop: 5,
                  minHeight: 80,
                  boxSizing: "border-box",
                  maxHeight: 120,
                }}
              />
              <ErrorText label={slotBookingError} />
              <CustomButton
                margin={20}
                label={"Submit"}
                func={handleSubmitFeedback}
              />
            </div>
          </Fade>
        </Modal>
      </div>
    )
  ) : (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress style={{ height: 70, width: 70 }} />
    </div>
  );
}

export default LearningDetial;
