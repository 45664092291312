import { useNavigate } from "react-router-dom";
import "./style.css";
import CryptoJS from "crypto-js";

function TrainerCard(props) {
  const training = props.training;
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        // navigate(
        //   "/slots/"+
        //     btoa(
        //       CryptoJS.AES.encrypt(training.id.toString(), "!@#$%^&*()").toString()
        //     )
        // );
      }}
      className="course-card"
    >
      <div className="course-card-content">
        <h4 style={{ fontWeight: 500, fontSize: 14, color: "#6a6a6a" }}>
          {training.trainer_year} Year To {training.user_year} Year
        </h4>
        <h3 style={{ fontWeight: 500, marginTop: 5 }}>{training.course}</h3>
        <p style={{ marginTop: 15, color: "#6a6a6a", fontSize: 14 }}>
          {training.user_active} Users Requested
        </p>
        <h4 style={{ fontWeight: 500, fontSize: 14, marginTop: 3 }}>
          Requested By {training.request_by}
        </h4>
      </div>
    </div>
  );
}

export default TrainerCard;
